import {AuthConsumerRenderProps, withAuthContext} from "auth/AuthContext";
import DatePicker from "component/final-form/DatePicker";
import Input from "component/final-form/Input";
import Radio, {generateRadioButtonOptions} from "component/final-form/Radio";
import RemoteSelect from "component/final-form/RemoteSelect";
import Select, {
  DropdownOption,
  mapToDropdownOptionArray,
  stringArrayToDropdownOptionArray
} from "component/final-form/Select";
import LoaderComponent from "component/LoaderComponent";
import MpGrid from "component/MpGrid";
import {FormApi} from "final-form";
import createDecorator from 'final-form-calculate';
import React, {Component} from "react";
import {Field, Form as FinalForm, FormRenderProps} from 'react-final-form';
import {withTranslation, WithTranslation} from "react-i18next";
import {RouteComponentProps} from "react-router";
import {CompanyOnboardViews} from "route/company-onboard/CompanyOnboardView";
import {Button, Container, Grid, RadioProps} from "semantic-ui-react";
import {getAllCountries, searchZipDataForInput} from "service/countryAndZipCityServices";
import axios from "service/http";
import styled from "styled-components";
import {CompanySubType, CompanyType} from "ts-types/api.enums";
import {UpsertCompanyOnboardingDto, ZipDataDto} from "ts-types/api.types";
import {IndexSignature} from "util/commonTypes";
import {composeValidators, emailValidator, required} from "util/validatorUtils";
import {withRouterWorkaround} from "util/workaroundUtils";

const UpsertContainer = styled(Container)`
  .success-message {
    color: darkgreen;
    display: inline-block;
    margin-right: 1rem;
  }
`;

const StyledMpGrid = styled(MpGrid)`

  &.ui.grid > .row > .column:first-child {
    padding-left: 0;
  }
`;

const StyledRow = styled(Grid.Row)`
  font-size: 14px;
`;

const ColumnWithRightBorder = styled(Grid.Column)`
  border-right: 1px solid #0f1a52;
`;

const cancelTokenSource = axios.CancelToken.source();

enum ZipDataOrderBy {
  ZIP = "ZIP",
  CITY = "CITY",
};

export interface IndexedZipData extends IndexSignature, ZipDataDto {
}

interface Props extends RouteComponentProps<any>,
    AuthConsumerRenderProps,
    WithTranslation {

  companyOnboarding: Partial<UpsertCompanyOnboardingDto>,
  setActiveView: (activeView: CompanyOnboardViews) => void,
  setCompanyOnboardingData: (data: Partial<UpsertCompanyOnboardingDto>) => void
}

interface State {
  genders: Array<RadioProps>,
  countries?: Array<DropdownOption>,
  cities: Array<DropdownOption>,
  zip: Array<DropdownOption>,
  languages: Array<DropdownOption>,
  companyTypes: Array<DropdownOption>,
  companySubTypes: Array<DropdownOption>,
  formDataLoaded?: boolean,
  successMessage?: string
}

class AddCompanyEmployee extends Component<Props, State> {

  constructor(props: Props) {
    super(props);

    const initialCities: Array<DropdownOption> = [];
    const initialZip: Array<DropdownOption> = [];

    const company = props.companyOnboarding.company;

    if (company) {
      if (company.city) {
        initialCities.push({
          key: company.city,
          text: company.city,
          value: company.city,
          predefinedOption: true
        });
      }

      if (company.zip) {
        initialZip.push({
          key: company.zip,
          text: company.zip,
          value: company.zip,
          predefinedOption: true
        });
      }
    }

    getAllCountries(cancelTokenSource)
    .then(response => {

      this.setState({
        countries: mapToDropdownOptionArray(response, "name", "shortName")
      });

      // const countryId = props.companyOnboarding.company?.countryId;
      // const companyZip = props.companyOnboarding.company?.zip;
      //
      // if (countryId && companyZip) {
      //   this.searchZipData(companyZip, countryId);
      // }
    })
    .finally(() => {
      this.setState({
        formDataLoaded: true
      });
    });

    const languageLabels: Array<string> = ["german", "french", "italian", "english"];
    const languageKeys: Array<string> = ["de", "fr", "it", "en"];
    const genderLabels: Array<string> = ["male", "female", "notAvailable"];

    this.state = {
      genders: generateRadioButtonOptions(genderLabels, "gender", props.t),
      cities: initialCities,
      zip: initialZip,
      languages: stringArrayToDropdownOptionArray(languageLabels, props.t, "language", languageKeys),
      companyTypes: stringArrayToDropdownOptionArray(Object.values(CompanyType), props.t, "companyType"),
      companySubTypes: stringArrayToDropdownOptionArray(Object.values(CompanySubType), props.t, "companySubType")
    };
  }

  setInitialCountry = (form: FormApi) => {
    const {countries} = this.state;
    const countryId = form.getState().values.company.countryId;
    if (countries && countries.length > 0 && countryId === undefined) {
      const switz = countries.find(c => c.key === "CH");
      if (switz) {
        form.change("company.countryId", switz.value);
      }
    }
  };

  sortZipData = (zipData: Array<IndexedZipData>, orderBy: ZipDataOrderBy) => {
    if (zipData) {
      if (orderBy === ZipDataOrderBy.ZIP) {
        return zipData.sort((a, b) => (a.zip > b.zip) ? 1 : ((b.zip > a.zip) ? -1 : 0));
      } else {
        return zipData.sort((a, b) => (a.city > b.city) ? 1 : ((b.city > a.city) ? -1 : 0));
      }
    }

    return [];
  };

  mapZipData = (field: string, zipData: Array<IndexedZipData>, orderBy: ZipDataOrderBy): Array<DropdownOption> => {

    const sortedZipData = this.sortZipData(zipData, orderBy);

    return sortedZipData.map(data => ({
      key: data.id,
      text: data[field],
      value: `${data.zip}, ${data.city}, ${data.countryId}`,
      content: `${data.zip}, ${data.city}, ${data.canton}`,
      predefinedOption: true
    }));
  };

  searchZipData = async (zip: string, countryId: number) => {

    const {cities, zip: zips, countries} = this.state;

    if (countries && countries.length > 0) {

      const country = countries.find(c => c.value === countryId);

      if (country) {

        const countryCode = country.key.toString();

        await searchZipDataForInput(zip, countryCode, cancelTokenSource, 75)
        .then(response => {

          const allInitialAndNewCityAdditions = cities.filter(city => city.value === city.key);
          const allInitialAndNewZipAdditions = zips.filter(zip => zip.value === zip.key);

          this.setState({
            cities: [...allInitialAndNewCityAdditions, ...this.mapZipData("city", response, ZipDataOrderBy.CITY)],
            zip: [...allInitialAndNewZipAdditions, ...this.mapZipData("zip", response, ZipDataOrderBy.ZIP)]
          });
        })
        .catch(() => {
          this.setState({
            cities: [],
            zip: []
          });
        });
      }
    }
  };

  onNewAddition = (options: "zip" | "cities") => (newAddition: DropdownOption) => {

    const {cities, zip: zips} = this.state;

    if (options === "cities") {

      const foundCity = cities.find(city => city.key === newAddition.key);

      if (!foundCity) {
        newAddition.predefinedOption = false;
        const newCities = cities.filter(city => city.predefinedOption === true);
        this.setState({
          cities: [newAddition, ...newCities]
        });
      }
    } else if (options === "zip") {

      const foundZip = zips.find(zip => zip.key === newAddition.key);

      if (!foundZip) {
        newAddition.predefinedOption = false;
        const newZips = zips.filter(city => city.predefinedOption === true);
        this.setState({
          zip: [newAddition, ...newZips]
        });
      }
    }
  };

  updateZipDataFieldDecorator = (field: string) =>
      (zipData?: string, allValues?: Partial<UpsertCompanyOnboardingDto & IndexSignature>) => {

        if (zipData && allValues && allValues["company"]) {
          const splitZipData = zipData.split(", ");

          //@ts-ignore
          const checkValue = allValues["company"][field];

          if (splitZipData.length === 3 && checkValue !== zipData) {
            return zipData;
          }
        }

        //@ts-ignore
        return allValues ? allValues["company"][field] : undefined;
      };

  zipDataDecorator = createDecorator(
      {
        field: "company.city",
        updates: {
          "company.zip": this.updateZipDataFieldDecorator("zip")
        }
      },
      {
        field: "company.zip",
        updates: {
          "company.city": this.updateZipDataFieldDecorator("city")
        }
      }
  );

  resetCompanySubType = createDecorator({
    field: 'company.companyType',
    updates: {
      "company.companySubType": (fieldValue, allValues?: Partial<UpsertCompanyOnboardingDto>) => {

        return fieldValue && fieldValue === CompanyType.SCHOOL
            ? allValues?.company?.companySubType !== undefined ? allValues.company.companySubType
            : CompanySubType.OTHER : undefined;
      }
    }
  });

  handleSubmit = (values: Partial<UpsertCompanyOnboardingDto>, form: FormApi) => {

    let zip = "";
    let city = "";

    if (values.company!.zip) {
      if (values.company!.zip.includes(', ')) {
        zip = values.company!.zip.split(", ")[0];
      } else {
        zip = values.company!.zip;
      }
    }

    if (values.company!.city) {
      if (values.company!.city.includes(', ')) {
        city = values.company!.city.split(", ")[1];
      } else {
        city = values.company!.city;
      }
    }

    const upsertCompanyOnboarding: Partial<UpsertCompanyOnboardingDto> = {
      //@ts-ignore
      company: {
        ...values.company,
        zip: zip,
        city: city
      },
      //@ts-ignore
      employee: {
        ...values.employee,
        countryId: values.company!.countryId
      }
    };

    this.props.setCompanyOnboardingData(upsertCompanyOnboarding);
    this.props.setActiveView(CompanyOnboardViews.COOPERATION_DETAILS);

  };

  render() {

    const {formDataLoaded} = this.state;
    const {t} = this.props;

    return (
        <UpsertContainer fluid>

          {formDataLoaded
              ? <React.Fragment>
                {this.renderFinalForm()}
              </React.Fragment>
              : <LoaderComponent message={t("employee.loadFormData")} />
          }
        </UpsertContainer>
    );
  }

  renderFinalForm(): React.ReactNode {

    return (
        <FinalForm
            onSubmit={(values, form) => this.handleSubmit(values, form)}
            validate={(values: Partial<UpsertCompanyOnboardingDto & { companyLogo: File }>) => {
              let errors: any = {};

              if(values.company){
                if (values.company.companyType === CompanyType.SCHOOL && !values.company.companySubType) {
                  errors = {
                    company: {
                      companySubType: "Required"
                    }
                  }
                }
              }
              return errors;
            }}
            decorators={[
              this.zipDataDecorator,
              this.resetCompanySubType,
            ]}
            initialValues={this.props.companyOnboarding}
            subscription={{pristine: true, submitting: true, values: true}}
            render={this.renderCompanyEmployeeFormContent}
        />
    );
  }

  renderCompanyEmployeeFormContent = ({form, handleSubmit, values}: FormRenderProps): React.ReactNode => {

    const {t} = this.props;
    const {
      zip,
      cities,
      countries,
      languages,
      companyTypes,
      companySubTypes,
      genders
    } = this.state;

    const countryId = form.getState().values.company.countryId;
    this.setInitialCountry(form);

    let disabledSubCompanyType = true;
    if (values.company.companyType && values.company.companyType === CompanyType.SCHOOL) {
      disabledSubCompanyType = false;
    }

    return (
        <form onSubmit={handleSubmit}>
          <StyledMpGrid>
            <Grid.Row>
              <Grid.Column width={8}>
                {t("company.headerText")}
              </Grid.Column>
              <Grid.Column width={8} style={{paddingLeft: "2rem"}}>
                {t("employee.headerText")}
              </Grid.Column>
            </Grid.Row>
            <StyledRow>
              <ColumnWithRightBorder width={8}>
                <StyledMpGrid>
                  <Grid.Row>
                    <Grid.Column width={3} verticalAlign="middle">
                      {t("company.description")}:
                    </Grid.Column>
                    <Grid.Column width={13} verticalAlign="middle">
                      <Field
                          fluid
                          name="company.description"
                          component={Input}
                          validate={required}
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column width={3} verticalAlign="middle">
                      {t("company.street")}:
                    </Grid.Column>
                    <Grid.Column width={13} verticalAlign="middle">
                      <Field
                          fluid
                          name="company.street"
                          component={Input}
                          validate={required}
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column width={3} verticalAlign="middle">
                      {t("company.zip")}:
                    </Grid.Column>
                    <Grid.Column width={4} verticalAlign="middle">
                      <Field
                          fluid
                          name="company.zip"
                          component={RemoteSelect}
                          allowAdditions
                          remoteSearch
                          customRemoteSearchMethod={(searchQuery: string) => this.searchZipData(searchQuery, countryId)}
                          onNewAddition={this.onNewAddition("zip")}
                          options={zip}
                          validate={required}
                      />
                    </Grid.Column>
                    <Grid.Column width={1} verticalAlign="middle">
                      {t("company.city")}:
                    </Grid.Column>
                    <Grid.Column width={8} verticalAlign="middle">
                      <Field
                          fluid
                          name="company.city"
                          component={RemoteSelect}
                          allowAdditions
                          remoteSearch
                          customRemoteSearchMethod={(searchQuery: string) => this.searchZipData(searchQuery, countryId)}
                          onNewAddition={this.onNewAddition("cities")}
                          options={cities}
                          validate={required}
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column width={3} verticalAlign="middle">
                      {t("company.countryId")}:
                    </Grid.Column>
                    <Grid.Column width={13} verticalAlign="middle">
                      <Field
                          name="company.countryId"
                          component={RemoteSelect}
                          options={countries}
                          validate={required}
                          fluid
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column width={3} verticalAlign="middle">
                      {t("company.tel")}: <br /> ({t("company.connection.tel")})
                    </Grid.Column>
                    <Grid.Column width={13} verticalAlign="middle">
                      <Field
                          fluid
                          name="company.tel"
                          component={Input}
                          validate={required}
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column width={3} verticalAlign="middle">
                      {t("company.email")}:
                    </Grid.Column>
                    <Grid.Column width={13} verticalAlign="middle">
                      <Field
                          fluid
                          name="company.email"
                          component={Input}
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column width={3} verticalAlign="middle">
                      {t("company.homepage")}:
                    </Grid.Column>
                    <Grid.Column width={13} verticalAlign="middle">
                      <Field
                          fluid
                          name="company.homepage"
                          component={Input}
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column width={3} verticalAlign="middle">
                      {t("company.language")}:
                    </Grid.Column>
                    <Grid.Column width={13} verticalAlign="middle">
                      <Field
                          name="company.language"
                          component={Select}
                          options={languages}
                          fluid
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column width={3} verticalAlign="middle">
                      {t("company.type")}:
                    </Grid.Column>
                    <Grid.Column width={13} verticalAlign="middle">
                      <Field
                          name="company.companyType"
                          component={Select}
                          options={companyTypes}
                          clearable
                          fluid
                          validate={required}
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column width={3} verticalAlign="middle">
                      {t("company.subType")}:
                    </Grid.Column>
                    <Grid.Column width={13} verticalAlign="middle">
                      <Field
                          name="company.companySubType"
                          component={Select}
                          options={companySubTypes}
                          clearable
                          fluid
                          disabled={disabledSubCompanyType}
                      />
                    </Grid.Column>
                  </Grid.Row>
                </StyledMpGrid>
              </ColumnWithRightBorder>
              <Grid.Column width={8}>
                <MpGrid>
                  <Grid.Row>
                    <Grid.Column width={3} verticalAlign="middle">
                      {t("employee.name")}:
                    </Grid.Column>
                    <Grid.Column width={13} verticalAlign="middle">
                      <Field
                          fluid
                          name="employee.name"
                          component={Input}
                          validate={required}
                          autoFocus
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column width={3} verticalAlign="middle">
                      {t("employee.firstName")}:
                    </Grid.Column>
                    <Grid.Column width={13} verticalAlign="middle">
                      <Field
                          fluid
                          name="employee.firstName"
                          component={Input}
                          validate={required}
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column width={3} verticalAlign="middle">
                      {t("employee.birthDate")}:
                    </Grid.Column>
                    <Grid.Column width={8} verticalAlign="middle">
                      <Field
                          fluid
                          name="employee.birthDate"
                          component={DatePicker}
                          validate={required}
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column width={3} verticalAlign="middle">
                      {t("employee.gender")}:
                    </Grid.Column>
                    <Grid.Column width={3} verticalAlign="middle">
                      <Field
                          name="employee.sex"
                          component={Radio}
                          radioDefinition={genders[0]}
                      />
                    </Grid.Column>
                    <Grid.Column width={3} verticalAlign="middle">
                      <Field
                          name="employee.sex"
                          component={Radio}
                          radioDefinition={genders[1]}
                      />
                    </Grid.Column>
                    <Grid.Column width={3} verticalAlign="middle">
                      <Field
                          name="employee.sex"
                          component={Radio}
                          radioDefinition={genders[2]}
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column width={3} verticalAlign="middle">
                      {t("employee.telephone")}: <br /> ({t("employee.connection.telephone")})
                    </Grid.Column>
                    <Grid.Column width={13} verticalAlign="middle">
                      <Field
                          fluid
                          name="employee.telephone"
                          component={Input}
                          validate={required}
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column width={3} verticalAlign="middle">
                      {t("employee.companyEmail")}:
                    </Grid.Column>
                    <Grid.Column width={13} verticalAlign="middle">
                      <Field
                          fluid
                          name="employee.companyEmail"
                          component={Input}
                          validate={composeValidators(required, emailValidator)}
                          disabled={true}
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column width={3} verticalAlign="middle">
                      {t("employee.language")}:
                    </Grid.Column>
                    <Grid.Column width={13} verticalAlign="middle">
                      <Field
                          name="employee.language"
                          component={Select}
                          options={languages}
                          validate={required}
                          fluid
                      />
                    </Grid.Column>
                  </Grid.Row>
                </MpGrid>
              </Grid.Column>
            </StyledRow>
            <Grid.Row>
              <Grid.Column width={16}>
                <MpGrid>
                  <Grid.Row textAlign="right">
                    <Grid.Column width={16}>
                      {
                        this.state.successMessage &&
                        <div className="success-message">
                          {t(this.state.successMessage)}
                        </div>
                      }
                      <Button
                          type="submit"
                          className="action-button"
                          primary
                          style={{display: "inline-block"}}
                      >
                        {t("companyOnboard.button.next")}
                      </Button>
                    </Grid.Column>
                  </Grid.Row>
                </MpGrid>
              </Grid.Column>
            </Grid.Row>
          </StyledMpGrid>
        </form>
    );
  };

}


let AddCompanyEmployeeWrapper = withRouterWorkaround(
    withAuthContext(
        withTranslation(["login"])(
            AddCompanyEmployee)));

export default AddCompanyEmployeeWrapper;