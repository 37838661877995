import _ from 'lodash';
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router";
import {Button} from "semantic-ui-react";
import axios from "service/http";
import {getEmployeeTokenData, registerTestkitBarCode} from "service/testKitServices";
import styled from "styled-components";
import {EmployeeTokenDataDto} from "ts-types/api.types";
import {isValidBarcode} from "util/barcodeUtils";
import {isoToDisplayDate} from "util/dateUtils";
import {applyStyles} from "util/localizationUtils";
import BarcodeReader from "./BarcodeReader";

const StyledContainer = styled.div`
  display: flex;
  align-items: start;
  justify-content: center;
  flex-direction: column;
  max-width: 768px;

  .info-text {
    font-size: 1.2rem;
    margin: 1rem 0 2rem;
  }

  video {
    border: 2px solid;
    width: 90%;
    height: 90%;
    max-width: 350px;
    max-height: 350px;
  }

  .device-select {
    margin-top: 0.75rem;
    width: 90%;
    height: 90%;
    max-width: 350px;
    max-height: 350px;

    padding: 0.3rem;
    border: 1px solid rgba(34, 36, 38, .15);
    border-radius: .28571429rem;
  }

  .info-barcode {
    margin: 2rem 0 0.5rem;
  }

  .field-error {
    color: #9f3a38;
    font-size: 1rem;
  }

  .actions-row {
    display: flex;
    justify-content: space-between;
    margin-top: 1.5rem;

    .ui.button {
      margin-right: 0.75rem;
    }
  }

  .success-box {
    background-color: #BBF3BA;
    padding: 1rem;
    font-size: 1.5rem;
    line-height: 1.9rem;
    margin-bottom: 2rem;
    width: 100%;
  }

  .error-box {
    background-color: #FFCCCC;
    padding: 1rem;
    font-size: 1.5rem;
    margin-bottom: 2rem;
    width: 100%;
  }


  @media only screen and (min-width: 320px) and (max-width: 767px) {
    h1 {
      font-size: 1.4rem;
    }

    .info-text {
      font-size: 1rem;
    }

    video {
      width: 100%;
      height: 100%;
    }

    .device-select {
      width: 100%;
      height: 100%;
    }
  }

`;

const StyledInfoComponentRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 0.5rem;
  font-size: 1.1rem;

  &.dark {
    background-color: #e5e5e5;
  }

  .label {
    flex-basis: 40%;
  }

  .value {
    flex-basis: 60%;
  }

  .value.value-bold {
    font-weight: 500;
  }

  .barcode-value {
    &.valid-barcode {
      color: #7687FF;
    }

    &.invalid-barcode {
      color: #9f3a38;
    }
  }

`;

interface InfoRowProps {
  dark?: boolean;
  label: string;
  value?: string;
  className?: string;
  valueClassName?: string;
}

const InfoRow: React.FunctionComponent<InfoRowProps> = (props) => {

  const {dark = false, label, value, className = "", valueClassName = ""} = props;

  return <StyledInfoComponentRow className={`info-row ${dark ? "dark" : ""} ${className}`}>
    <span className="label">{label}</span>
    {
      value &&
      <span className={`value ${valueClassName}`}>{value}</span>
    }
    {
      props.children &&
      <>{props.children}</>
    }
  </StyledInfoComponentRow>;

};

interface BarcodePageParams {
  token: string;
}

type PageState = "ENTRY" | "ERROR" | "SUCCESS" | "INVALID_TOKEN";

const cancelToken = axios.CancelToken.source();

const BarcodePage = () => {

  const {token} = useParams<BarcodePageParams>();
  const {t} = useTranslation('login');
  const [pageState, setPageState] = React.useState("ENTRY" as PageState);
  const [code, setCode] = React.useState("");
  const [errorMessage, setErrorMessage] = React.useState("");
  const [cameraScanActive, setCameraScanActive] = React.useState(true);
  const [codeValid, setCodeValid] = React.useState(true);
  const [cancelTokenSource/*, setCancelTokenSource*/] = React.useState(axios.CancelToken.source());
  const [tokenData, setTokenData] = React.useState({} as Partial<EmployeeTokenDataDto>);

  const [path] = useState(require('chime.mp3'));

  const playAudio = React.useCallback(() => {
    new Audio(path.default).play();
  }, [path]);

  const titleRef = React.useRef<HTMLHeadingElement>(null);


  useEffect(() => {
    setTimeout(() => {
      if (titleRef.current) {
        titleRef.current.scrollIntoView(true);
      }
    }, 1500);
  }, []);

  useEffect(() => {
    // initial values for testing different page states
    // const isValidCode = isValidBarcode(code);
    // setCodeValid(isValidCode);
    // setErrorMessage(t("testkit.error.general"))

    getEmployeeTokenData(token, cancelTokenSource)
    .then(response => {
      setTokenData(response);
    })
    .catch(error => {
      setPageState("INVALID_TOKEN");
    });
    // eslint-disable-next-line
  }, []);

  const handleBarcodeRead = React.useCallback((code) => {
    const isValidCode = isValidBarcode(code);
    setCodeValid(isValidCode);
    setCode(code);
    setCameraScanActive(false);
  }, []);

  const resetCamera = React.useCallback(() => {
    setCode('');
    setCodeValid(true);
    setCameraScanActive(true);
  }, []);

  const reloadPage = React.useCallback(() => {
    window.location.reload();
  }, []);

  const handleRegisterCode = React.useCallback(() => {
    registerTestkitBarCode({barCode: code, token: token}, cancelToken)
    .then(() => {
      playAudio();
      setPageState("SUCCESS");
    })
    .catch(e => {
      let error = t("testkit.error.general");

      const errorData = e.response.data;
      if (errorData && !_.isEmpty(errorData.violations)) {
        const errorCode = errorData.violations[0].errorCode;
        if ([
          "INVALID_BARCODE",
          "BARCODE_ALREADY_EXISTS",
          "ANOTHER_REGISTERED_TESTKIT_IN_PROGRESS_ALREADY_EXIST"
        ].includes(errorCode)) {
          error = t(`testkitToken.error.${errorCode}`);
        }
      }

      setErrorMessage(error);
      setPageState("ERROR");
    });
  }, [code, token, playAudio, t]);

  const goBackToEntryState = () => {
    setPageState("ENTRY");
    setErrorMessage("");
    resetCamera();
  };

  const renderPageHeader = () => <>
    <InfoRow
        dark
        label={t("testkitToken.content.institution")}
        value={`${tokenData.companyName}, ${tokenData.companyZip} ${tokenData.companyCity}`}
        valueClassName="value-bold"
    />
    <InfoRow
        label={t("testkitToken.content.administrator")}
        value={`${tokenData.sourceEmployeeFirstName} ${tokenData.sourceEmployeeLastName}, ${tokenData.sourceEmployeeEmail}`}
        valueClassName="value-bold"
    />

    <h1 ref={titleRef} id="barcode-header">{t("testkitToken.title")}</h1>

    <InfoRow
        dark
        label={t("testkitToken.content.employee")}
        value={`${tokenData.targetEmployeeFirstName} ${tokenData.targetEmployeeLastName}, ${isoToDisplayDate(tokenData.targetEmployeeBirthDate)}`}
        valueClassName="value-bold"
    />
  </>;

  const renderEntryState = () => <>
    {renderPageHeader()}

    <div className="info-text">
      {t("testkitToken.content.infoText")}
    </div>

    <BarcodeReader
        width={350}
        height={350}
        onCodeRead={handleBarcodeRead}
        cameraScanActive={cameraScanActive}
    />

    <InfoRow
        dark
        label={t("testkitToken.content.result")}
        className="info-barcode"
    >
                <span className={`value value-bold barcode-value ${codeValid ? "valid-barcode" : "invalid-barcode"}`}>
                    {code}
                </span>
    </InfoRow>

    {
      !codeValid &&
      <div className="field-error">
        {t("testkit.code.invalidResult")}
      </div>
    }

    <div className="actions-row">
      <Button
          type="button"
          className="action-button"
          primary
          onClick={handleRegisterCode}
          disabled={!code || !codeValid}
      >
        {t('testkitToken.action.register')}
      </Button>

      <Button
          type="button"
          className="action-button"
          color={'grey'}
          onClick={reloadPage}
      >
        {t('testkitToken.action.reset')}
      </Button>
    </div>
  </>;

  const renderSuccessState = () => <>
    <div className="success-box">
      {applyStyles(t("testkitResult.header.successMessage", {testCode: code}))}
    </div>

    {renderPageHeader()}

    <div className="info-text">
      {t("testkitResult.success.infoText")}
    </div>
  </>;

  const renderErrorState = () => <>
    <div className="error-box">
      {errorMessage}
    </div>

    {renderPageHeader()}

    <div className="info-text">
      {t("testkitResult.error.infoText")}
    </div>

    <div className="actions-row">
      <Button
          type="button"
          className="action-button"
          secondary
          onClick={goBackToEntryState}
      >
        {t('testkitResult.action.newRegistration')}
      </Button>
    </div>
  </>;

  const renderInvalidTokenState = () => <>
    <div className="error-box">
      {t("testkitToken.invalidToken")}
    </div>
  </>;


  return <StyledContainer>
    {
      pageState === "ENTRY" &&
      renderEntryState()
    }
    {
      pageState === "SUCCESS" &&
      renderSuccessState()
    }
    {
      pageState === "ERROR" &&
      renderErrorState()
    }
    {
      pageState === "INVALID_TOKEN" &&
      renderInvalidTokenState()
    }
  </StyledContainer>;
};

export default BarcodePage;
