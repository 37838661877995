export const noop = () => {
};

// It covers tha cases if value is null and value is undefined
export const isNull = (value: any) => (value == null);

export const  isNotNull = (value: any) => (!isNull(value));

export const buildMap = (keys: Array<string | number>, values: Array<any>) => {
    const map = new Map();
    for(let i = 0; i < keys.length; i++){
        map.set(keys[i], values[i]);
    }
    return map;
};
