import {AuthConsumerRenderProps, withAuthContext} from "auth/AuthContext";
import {CompanyDataConsumerRenderProps, withCompanyDataContext} from "component/CompanyDataContext";
import CompanyDataHeader from "component/CompanyDataHeader";
import Input from "component/final-form/Input";
import Select, {
  DropdownOption,
  mapToDropdownOptionArray,
  mapToDropdownOptionArrayWithTextGetter
} from "component/final-form/Select";
import TextArea from "component/final-form/TextArea";
import LoaderComponent from "component/LoaderComponent";
import MpGrid from "component/MpGrid";
import StyledErrorMessage from "component/StyledErrorMessage";
import {FormApi, Mutator} from "final-form";
import createDecorator from "final-form-calculate";
import moment from "moment";
import React, {Component, Fragment} from "react";
import {Field, Form as FinalForm, FormRenderProps} from 'react-final-form';
import {withTranslation, WithTranslation} from "react-i18next";
import {RouteComponentProps} from "react-router";
import {DataEntry} from "route/person-selfonboarded/styled";
import {Button, Grid} from "semantic-ui-react";
import axios from "service/http";
import {
  getIntervention,
  getVaccinationResources,
  scheduleVaccinationBooking,
  searchVaccinationAppointment
} from "service/vaccinationServices";
import styled from "styled-components";
import {InterventionExternalCode, InterventionSlotStatus} from "ts-types/api.enums";
import {
  InterventionAvailabilitySlotSearchDto,
  VaccinationAppointmentRequest,
  VaccinationAvailabilitiesSearchDto
} from "ts-types/api.types";
import {fromIsoDateString, isoToDisplayTime, isoToLongDisplayDate} from "util/dateUtils";
import {errorUtils} from "util/errorUtils";
import {required} from "util/validatorUtils";
import {withRouterWorkaround} from "util/workaroundUtils";

const UpsertContainer = styled.div`

  .section-title {
    color: #7687FF;
    font-weight: bold;
    font-size: 1.5rem;
    margin: 0 0 1rem;
  }

  .section-info {
    margin: 1rem 0 0;
  }

  .success-message {
    color: darkgreen;
    display: inline-block;
    margin-right: 1rem;

    @media only screen and (max-width: 767px) {
      display: block;
      margin-bottom: 0.75rem;
    }
  }

  .form-grid {
    max-width: 1080px;

    label {
      display: inline-block;
      margin-bottom: 0.5rem;
      color: #454545;
    }
  }

  .form-section-start {
    margin-top: 1.75rem;
  }

  .anamnesis-section {
    font-size: 1.1rem;
  }

  .form-fields {

    .value-text {
      font-weight: bold;
      font-size: 1.1rem;
    }
  }

  .field.select {
    max-width: 27rem;
  }

  .field > .ui.input.hidden-field {
    display: none;
  }

  .data-entry {
    flex: 1 1 auto;
    display: grid;
    grid-template-columns: min-content 100%;
    justify-content: start;
    align-content: start;
    row-gap: 0.75rem;

    .label {
      min-width: 150px;
      font-weight: bold;
    }

    .value {
      max-width: 25rem;
    }

    .radio-row {
      grid-column-end: span 2;
      justify-self: start;
      margin-top: 0.5rem;
      margin-bottom: 0.3rem;
    }

    .row-label {
      margin: 1.5rem 0 1rem;
      grid-column-end: span 2;
      justify-self: start;
    }

    .search-button-row {
      margin-bottom: 0.35rem;
      grid-column-end: span 2;
    }

    .next-available-day-info {
      margin-bottom: 0.5rem;
      grid-column-start: 2;
      justify-self: start;
      font-style: italic;

      .next-date {
        display: inline-block;
        font-weight: bold;
        padding-left: 0.5rem;
      }
    }

    .available-slots-row {
      margin: 0.25rem 0 1rem;
      grid-column-start: 2;
      justify-self: start;

      .time-slot {
        display: inline-block;
        margin-right: 0.5rem;
        padding: 2px 4px;
        border-radius: 4px;
        cursor: default;
        border: 1px solid #e5e5e5;
        margin-bottom: 0.5rem;
        min-width: 3.875rem;
        text-align: center;
      }

      .time-slot.selected {
        background: #2185d0;
        color: white;
      }

      .time-slot.BOOKED {
        background: #f1c6dc;
        border: 1px solid #d8a1bd;
        cursor: default;
      }

      .time-slot:not(.BOOKED):HOVER {
        background: #c4dcef;
      }
    }
  }

  .no-available-appointments {
    margin-bottom: 4rem;
    color: darkred;
  }

`;

const cancelTokenSource = axios.CancelToken.source();

interface VaccinationAppointmentRequestExtended extends VaccinationAppointmentRequest {
  availabilityId: number,
  date: string,
  time: string,
  secondVaccinationAvailabilityId: number,
  secondVaccinationDate: string,
  secondVaccinationTime: string,
  locationId: number;
}

interface Props extends RouteComponentProps<any>,
    AuthConsumerRenderProps,
    WithTranslation,
    CompanyDataConsumerRenderProps {

  onSave: () => void,
  onCancel: () => void
}

interface State {
  employeeId?: number,
  locationId: number,
  interventionBookingId: number,
  interventionId: number,
  duration: number,
  resources: DropdownOption[],

  formDataLoaded: boolean,

  twoDoseVaccination: boolean,

  availableSlotsSearchErrorMessage?: string;
  availabilities: InterventionAvailabilitySlotSearchDto[];
  availabilityOptions: DropdownOption[];
  secondDoseAvailabilities: InterventionAvailabilitySlotSearchDto[];
  secondDoseAvailabilityOptions: DropdownOption[];

  pageActionInProgress: boolean,
  successMessage?: string,
  errorMessages: Array<string>
}

class RequestVaccination extends Component<Props, State> {

  initialAppointmentValues: Partial<VaccinationAppointmentRequestExtended> = {
    interventionSlotId: undefined
  };

  formDecorators = [
    createDecorator(
        {
          field: "availabilityId",
          updates:
              {
                "interventionSlotId": (availabilityId, allValues) => undefined,
                "secondVaccinationAvailabilityId": (availabilityId, allValues) => {
                  const availability = this.state.availabilities.find(av => av.id === availabilityId);
                  if (!availability) {
                    return undefined;
                  }

                  const secondDoseAvailability = this.state.secondDoseAvailabilities.find(
                      av => fromIsoDateString(av.date)!.diff(fromIsoDateString(availability.date), 'days') >= 28);
                  return secondDoseAvailability ? secondDoseAvailability.id : undefined;
                },
                "secondVaccinationInterventionSlotId": () => undefined
              }
        },
        {
          field: "secondVaccinationAvailabilityId",
          updates:
              {
                "secondVaccinationInterventionSlotId": (availabilityId, allValues) => undefined
              }
        }
    )
  ];

  private cancelTokenSource = axios.CancelToken.source();

  constructor(props: Props) {
    super(props);

    const state: any = this.props.location.state;
    const interventionId = state.interventionId;
    const locationId = state.locationId;

    this.state = {
      formDataLoaded: false,
      locationId: locationId,
      resources: [],

      interventionBookingId: state.interventionBookingId,
      interventionId: interventionId,
      duration: 5,

      twoDoseVaccination: false,

      availabilities: [],
      availabilityOptions: [],

      secondDoseAvailabilities: [],
      secondDoseAvailabilityOptions: [],

      pageActionInProgress: false,
      errorMessages: []
    };

    this.fetchPageData(interventionId, locationId);
  }

  fetchPageData = async (interventionId: number, locationId: number) => {
    try {

      const intervention = await getIntervention(interventionId, cancelTokenSource);

      const duration = intervention.durationInMinutes;

      const twoDoseInterventions = [
        InterventionExternalCode.VACCINATION_DOSE_1,
        InterventionExternalCode.VACCINATION_CHILD_DOSE_1
      ];
      const externalCode: InterventionExternalCode = intervention.externalCode as InterventionExternalCode;
      const twoDoseVaccination = twoDoseInterventions.includes(externalCode);

      let resources = await getVaccinationResources(externalCode, cancelTokenSource);
      resources = resources.filter(res => res.id === locationId);

      this.setState({
        resources: mapToDropdownOptionArray(resources, "descriptionWithLocation"),
        duration: duration,
        twoDoseVaccination: twoDoseVaccination
      });

      this.initialAppointmentValues.locationId = locationId;

      await this.searchForAvailableSlots(duration);
    } finally {
      this.setState({
        formDataLoaded: true
      });
    }
  };

  setErrorMessage = (errorMessage?: string) => {

    const {errorMessages} = this.state;

    if (errorMessage) {

      const errMsgs = [...errorMessages];
      errMsgs.push(errorMessage);

      this.setState({
        errorMessages: errMsgs
      });
    } else {

      this.setState({
        errorMessages: []
      });
    }
  };

  handleError(error: any) {
    const {t} = this.props;
    const errorCode = error.errorCode;
    const knownErrors: Array<string> = [
      errorUtils.invalidInput,
      errorUtils.unmetMinimumAge,
      errorUtils.inconsistentLocation,
      "INTERVENTION_SLOT_UNAVAILABLE",
      "VACCINATION_BOOKING_ALREADY_EXISTS",
      "SECOND_VACCINATION_SLOT_UNAVAILABLE"
    ];

    const violations: Array<any> = error.violations;

    if (violations && violations.length > 0) {
      violations.forEach(violation => {
        if (knownErrors.includes(violation.errorCode)) {
          this.setErrorMessage(t(`error.${violation.errorCode}`));
        }
      });
    }

    if (!this.state.errorMessages.length) {
      if (knownErrors.includes(errorCode)) {
        this.setErrorMessage(t(`error.${errorCode}`));
      } else {
        this.setErrorMessage(t('error.general'));
      }
    }
  };

  handleSubmit = async (values: Partial<VaccinationAppointmentRequestExtended>) => {

    this.setErrorMessage();
    this.setPageActionInProgress();

    const upsertVaccinationAppointmentRequest: Partial<VaccinationAppointmentRequest> = {
      interventionId: this.state.interventionId,
      interventionSlotId: values.interventionSlotId,
      secondVaccinationInterventionSlotId: values.secondVaccinationInterventionSlotId,
      remarks: values.remarks
    };

    try {

      await scheduleVaccinationBooking(
          this.state.interventionBookingId, upsertVaccinationAppointmentRequest, cancelTokenSource);

      this.setState({
        successMessage: "vaccinationAppointmentBooking.message.saveSuccess"
      });

      setTimeout(() => {
        this.props.history.push("/", {vaccinationAppointmentSuccess: true});
      }, 1200);

    } catch (e) {
      this.clearPageActionInProgress();
      this.handleError(e.response.data);
      this.searchForAvailableSlots(this.state.duration);
    }
  };

  setPageActionInProgress = () => {
    this.setState({
      pageActionInProgress: true
    });
  };

  clearPageActionInProgress = () => {
    this.setState({
      pageActionInProgress: false
    });
  };

  setValue: Mutator = ([name, newValue], state, {changeValue}) => {
    changeValue(state, name, value => newValue);
  };

  searchForAvailableSlots = async (duration: number) => {

    const {currentUser} = this.props;
    const {interventionId} = this.state;

    let userAge = 0;
    const userBirthDate = currentUser?.birthDate.toString();
    if (userBirthDate) {
      userAge = moment().diff(userBirthDate, 'years');
    }

    this.setState({
      availableSlotsSearchErrorMessage: undefined
    });

    var vaccAvailabilitiesResponse: VaccinationAvailabilitiesSearchDto;
    var availabilitiesResponse: InterventionAvailabilitySlotSearchDto[];
    try {
      vaccAvailabilitiesResponse = await searchVaccinationAppointment(interventionId, this.cancelTokenSource);
      availabilitiesResponse = vaccAvailabilitiesResponse.availabilities.filter(avail => avail.minimumAge <= userAge);
      availabilitiesResponse = availabilitiesResponse.filter(avail => avail.resourceId === this.state.locationId);

    } catch (e) {
      this.setState({
        availableSlotsSearchErrorMessage: "error"
      });
      return;
    }

    if (availabilitiesResponse && availabilitiesResponse.length > 0) {

      const availabilityOptions = mapToDropdownOptionArrayWithTextGetter(
          availabilitiesResponse, this.availabilityDescriptionGenerator);

      const secondDoseAvailabilities = vaccAvailabilitiesResponse.secondVaccinationAvailabilities || [];
      const secondDoseAvailabilityOptions = mapToDropdownOptionArrayWithTextGetter(
          secondDoseAvailabilities, this.availabilityDescriptionGenerator);

      this.setState({
        availabilities: availabilitiesResponse,
        availabilityOptions,
        secondDoseAvailabilities,
        secondDoseAvailabilityOptions
      });

      this.initialAppointmentValues.availabilityId = availabilitiesResponse[0].id;
      this.initialAppointmentValues.secondVaccinationAvailabilityId = availabilitiesResponse[0].secondDoseAvailabilityId;
    }
  };

  availabilityDescriptionGenerator = (avail: InterventionAvailabilitySlotSearchDto) => {
    return `${isoToLongDisplayDate(avail.date)}`
        + ` - ${isoToDisplayTime(avail.fromTime)} - ${isoToDisplayTime(avail.toTime)}`;
  };

  render() {

    const {formDataLoaded} = this.state;
    const {t} = this.props;

    return (
        <UpsertContainer>
          <CompanyDataHeader />

          {formDataLoaded
              ? <React.Fragment>
                {this.renderFinalForm()}
              </React.Fragment>
              : <LoaderComponent message={t("vaccinationAppointmentBooking.loadFormData")} />
          }
        </UpsertContainer>
    );
  }

  renderFinalForm(): React.ReactNode {

    return (
        <FinalForm
            onSubmit={(values) => this.handleSubmit(values)}
            decorators={this.formDecorators}
            mutators={{setValue: this.setValue}}
            initialValues={this.initialAppointmentValues}
            subscription={{values: true, valid: true, pristine: true, submitting: true}}
            render={this.renderVaccinationFormContent}
        />
    );
  }

  renderVaccinationFormContent = (
      {
        form,
        handleSubmit,
        submitting
      }: FormRenderProps<Partial<VaccinationAppointmentRequestExtended>>): React.ReactNode => {

    const {t} = this.props;
    const {
      resources,
      availabilityOptions,
      pageActionInProgress,
      errorMessages
    } = this.state;

    const values = form.getState().values;

    const selectedAvailability = this.state.availabilities.find(
        av => av.id === values.availabilityId);

    let validSecondDoseAvailabilities: InterventionAvailabilitySlotSearchDto[] = [];
    if (selectedAvailability) {
      validSecondDoseAvailabilities = this.state.secondDoseAvailabilities.filter(
          av => fromIsoDateString(av.date)!.diff(fromIsoDateString(selectedAvailability.date), 'days') >= 28);
    }

    const secondDoseAvailabilityOptions = mapToDropdownOptionArrayWithTextGetter(
        validSecondDoseAvailabilities, this.availabilityDescriptionGenerator);

    return (
        <form onSubmit={handleSubmit}>
          <MpGrid stackable className="form-grid">
            <Grid.Row>
              <Grid.Column width={16}>
                {/*<div className="title-h1">{t("vaccinationBooking.title")}</div>*/}
                <div className="section-title">{t("vaccinationAppointmentBooking.title")}</div>
                <div className="section-info">{t("vaccinationAppointmentBooking.info")}</div>
              </Grid.Column>
            </Grid.Row>

            {
                errorMessages.length > 0 &&
              <Grid.Row>
                <Grid.Column width={16}>
                  <StyledErrorMessage onDismiss={() => this.setErrorMessage()}>
                    {errorMessages.map(err => <div key={err}>{err}</div>)}
                  </StyledErrorMessage>
                </Grid.Column>
              </Grid.Row>
            }

            <Grid.Row className="form-fields">
              <Grid.Column width={3} verticalAlign="middle">
                <label>{t("vaccinationBooking.location")}:</label>
              </Grid.Column>
              <Grid.Column width={5}>
                <Field
                    fluid
                    name="locationId"
                    className="select"
                    component={Select}
                    options={resources}
                    clearable
                    disabled
                />
                {<Field
                    className="hidden-field"
                    fluid
                    name="interventionSlotId"
                    component={Input}
                    validate={required}
                />}
              </Grid.Column>
            </Grid.Row>

            <Grid.Row className="form-fields">
              <Grid.Column width={3} verticalAlign="middle">
                <label>{t("vaccinationAppointmentBooking.availability")}:</label>
              </Grid.Column>
              <Grid.Column width={5}>
                <Field
                    fluid
                    name="availabilityId"
                    className="select"
                    component={Select}
                    options={availabilityOptions}
                    validate={required}
                />
              </Grid.Column>
            </Grid.Row>

            {/*<Grid.Row className="form-section-start">
                    <Grid.Column width={16} verticalAlign="middle">
                      {t("vaccinationAppointmentBooking.searchTerminInfo")}
                    </Grid.Column>
                  </Grid.Row>*/}

            {/*
              availableSlotsDate &&
              <Grid.Row className="form-fields">
                <Grid.Column width={4} verticalAlign="middle">
                  <label>{t('vaccinationAppointmentBooking.search.nextAvailableDate')}</label>
                </Grid.Column>
                <Grid.Column className="value-text" width={12} verticalAlign="middle">
                  {`${toLongDisplayDate(availableSlotsDate)}`}
                </Grid.Column>
              </Grid.Row>
            */}

            <Grid.Row>
              <Grid.Column width={16}>
                <DataEntry className="data-entry">
                  {/*<div className="search-button-row">
                          <Button type="button"
                                  compact
                                  primary
                                  onClick={() => this.searchForAvailableSlots(this.state.duration)}
                          >
                            {t("vaccinationAppointmentBooking.action.search")}
                          </Button>
                        </div>*/}

                  <div className="available-slots-row">
                    {this.renderAvailableSlots(form)}
                  </div>
                </DataEntry>
                {
                  availabilityOptions.length === 0
                      ? <div className="no-available-appointments">
                        {t("vaccinationAppointmentBooking.noAvailableAppointments.message")}
                      </div>
                      : <></>
                }
              </Grid.Column>
            </Grid.Row>

            {
                this.state.twoDoseVaccination &&
              <>
                <Grid.Row className="form-fields">
                  <Grid.Column width={3} verticalAlign="middle">
                    <label>{t("vaccinationAppointmentBooking.secondDoseAvailability")}:</label>
                  </Grid.Column>
                  <Grid.Column width={5}>
                    <Field
                      fluid
                      name="secondVaccinationAvailabilityId"
                      className="select"
                      component={Select}
                      options={secondDoseAvailabilityOptions}
                      validate={this.minDaysBetweenVaccinations}
                    />
                  </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                  <Grid.Column width={16}>
                    <DataEntry className="data-entry">
                      <div className="available-slots-row">
                        {this.renderSecondVaccinationAvailableSlots(form)}
                      </div>
                    </DataEntry>
                    {
                      availabilityOptions.length === 0
                          ? <div className="no-available-appointments">
                            {t("vaccinationAppointmentBooking.noAvailableAppointments.message")}
                          </div>
                          : <></>
                    }
                  </Grid.Column>
                </Grid.Row>
              </>
            }

            <Grid.Row className="form-fields">
              <Grid.Column width={3} verticalAlign="middle">
                <label>{t("vaccinationAppointmentBooking.remarks")}:</label>
              </Grid.Column>
              <Grid.Column width={5}>
                <Field
                    fluid
                    name="remarks"
                    component={TextArea}
                    rows={2}
                />
              </Grid.Column>
            </Grid.Row>

            <Grid.Row textAlign="right">
              <Grid.Column width={16}>
                {
                    this.state.successMessage &&
                  <div className="success-message">
                    {t(this.state.successMessage)}
                  </div>
                }
                <Button
                    type="submit"
                    className="action-button"
                    primary
                    style={{display: "inline-block", marginRight: "1.5rem"}}
                    onClick={() => {}}
                    disabled={pageActionInProgress || !form.getState().valid}
                >
                  {t("employee.save")}
                </Button>
                <Button
                    type="button"
                    className="action-button"
                    onClick={() => this.props.history.push(`/`)}
                    secondary
                    style={{display: "inline-block"}}
                >
                  {t("action.cancel")}
                </Button>
              </Grid.Column>
            </Grid.Row>
          </MpGrid>
        </form>
    );
  };

  renderAvailableSlots = (form: FormApi): JSX.Element => {

    const values = form.getState().values;

    let slots: any = [];
    if (values.availabilityId) {
      let currentHour = 0;
      let currentMin = 0;

      const selectedAvailability = this.state.availabilities.find(avail => avail.id === values.availabilityId);

      const displayedSlots = selectedAvailability!.slots.filter(
          slot => InterventionSlotStatus.BLOCKED !== slot.status);

      slots = displayedSlots.map((slotDto, ix) => {

            let newRow = false;

            const slot = moment(slotDto.fromTime, "HH:mm");
            const slotHour = slot.hour();
            if (slotHour !== currentHour) {
              newRow = true;
              currentHour = slotHour;
            }

            const slotMin = slot.minute();
            if (currentMin !== Math.floor(slotMin / 15)) {
              newRow = true;
              currentMin = Math.floor(slotMin / 15);
            }

            const selectedClassname = slotDto.id === values.interventionSlotId ? "selected" : "";
            const bookedClassname = slotDto.status ? slotDto.status : "";
            return <Fragment key={ix}>
              {newRow && <div className="new-row-div" />}
              {ix > 0 && !newRow && <> </>}
              <div
                  className={`time-slot ${selectedClassname} ${bookedClassname}`}
                  onClick={() => {
                    if (InterventionSlotStatus.FREE !== slotDto.status) {
                      return;
                    }

                    form.mutators.setValue("interventionSlotId", slotDto.id);
                    form.mutators.setValue("date", selectedAvailability!.date);
                    form.mutators.setValue("time", slotDto.fromTime);
                  }}
              >
                {slot.format("HH:mm")}
              </div>
            </Fragment>;
          }
      );
    }

    return <>
      {slots}
    </>;
  };

  renderSecondVaccinationAvailableSlots = (form: FormApi): JSX.Element => {

    const values = form.getState().values;

    let slots: any = [];
    const availabilityId = values.secondVaccinationAvailabilityId;
    if (availabilityId) {
      let currentHour = 0;
      let currentMin = 0;

      const selectedAvailability =
          this.state.secondDoseAvailabilities.find(avail => avail.id === availabilityId);

      const displayedSlots = selectedAvailability!.slots.filter(
          slot => InterventionSlotStatus.BLOCKED !== slot.status);

      slots = displayedSlots.map((slotDto, ix) => {

            let newRow = false;

            const slot = moment(slotDto.fromTime, "HH:mm");
            const slotHour = slot.hour();
            if (slotHour !== currentHour) {
              newRow = true;
              currentHour = slotHour;
            }

            const slotMin = slot.minute();
            if (currentMin !== Math.floor(slotMin / 15)) {
              newRow = true;
              currentMin = Math.floor(slotMin / 15);
            }

            const selectedClassname = slotDto.id === values.secondVaccinationInterventionSlotId ? "selected" : "";
            const bookedClassname = slotDto.status ? slotDto.status : "";
            return <Fragment key={ix}>
              {newRow && <div className="new-row-div" />}
              {ix > 0 && !newRow && <> </>}
              <div
                  className={`time-slot ${selectedClassname} ${bookedClassname}`}
                  onClick={() => {
                    if (InterventionSlotStatus.FREE !== slotDto.status) {
                      return;
                    }

                    form.mutators.setValue("secondVaccinationInterventionSlotId", slotDto.id);
                    form.mutators.setValue("secondVaccinationDate", selectedAvailability!.date);
                    form.mutators.setValue("secondVaccinationTime", slotDto.fromTime);
                  }}
              >
                {slot.format("HH:mm")}
              </div>
            </Fragment>;
          }
      );
    }

    return <>
      {slots}
    </>;
  };

  minDaysBetweenVaccinations = (value: any, allValues: any): any => {

    const requiredResult = required(value);
    if (requiredResult) {
      return requiredResult;
    }

    if (!allValues.secondVaccinationInterventionSlotId
        || !allValues.secondVaccinationAvailabilityId
        || !allValues.secondVaccinationDate
        || !allValues.secondVaccinationTime) {
      return undefined;
    }

    return fromIsoDateString(allValues.secondVaccinationDate)!.diff(fromIsoDateString(allValues.date), 'days') < 28
        ? "minDaysBetweenVaccinations" : undefined;
  };

}

export default withRouterWorkaround(
    withAuthContext(
        withCompanyDataContext(
            withTranslation(["mipoco"])(
                RequestVaccination))));