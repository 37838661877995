import {AuthConsumerRenderProps, withAuthContext} from "auth/AuthContext";
import axios, {CancelTokenSource} from "axios";
import CompanyDataHeader from "component/CompanyDataHeader";
import FinalFormInput from "component/final-form/Input";
import barcodeSample from 'images/barcode-sample.png';
import _ from "lodash";
import React, {Component} from 'react';
import {Field, Form as FinalForm, FormRenderProps} from "react-final-form";
import {withTranslation, WithTranslation} from "react-i18next";
import {RouteComponentProps} from "react-router";
import {Button, Grid, Image} from "semantic-ui-react";
import {registerPoolTestkit, registerTestkit} from "service/testKitServices";
import styled from "styled-components";
import {TestkitRegistrationRequestDto} from "ts-types/api.types";
import {isNumberOnlyBarcodeValid} from "util/barcodeUtils";
import {applyStyles} from "util/localizationUtils";
import {withRouterWorkaround} from "util/workaroundUtils";

const TestRegistrationManualInputDiv = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;

  .content-container {
    max-width: 40rem;

    .barcode-sample {
      margin: 0 auto 1.5rem;
    }

    .data-row {
      display: flex;
      flex-wrap: wrap;
      background-color: #E5E5E5;
      padding: 0.5rem;
      position: relative;
      font-weight: 700;
      text-align: center;
      margin: 0 0 2rem 0;

      .column-header {
        flex-basis: 40%;
        justify-self: start;
        font-weight: normal;
        text-align: left;
      }

      .column-value {
        flex-basis: 60%;
        justify-self: start;
      }
    }

    .input-info-row {
      font-size: 1.2rem;
      margin: 1rem 0;
    }

    .form-grid {
      margin: 2rem 0;

      &.ui.grid > .column:not(.row),
      &.ui.grid > .row > .column.misanto-prefix {
        font-size: 1.2rem;
        font-weight: bold;
        text-align: right;
        padding-right: 0;
      }

      .field-error,
      .error {
        color: darkred;
        font-size: 1rem;
      }
    }

    .ui.grid.form-grid > .row {
      padding-top: 0.4rem;
      padding-bottom: 0.4rem;
    }

    .ui.grid.form-grid > .column:not(.row):first-child,
    .ui.grid.form-grid > .row > .column:first-child {
      padding-left: 0;
    }

    .ui.grid.form-grid > .column:not(.row):last-child,
    .ui.grid.form-grid > .row > .column:last-child {
      padding-right: 0;
    }
  }

  .icon-button {
    padding: 2px !important;
    background: transparent !important;
  }

  .page-actions {
    justify-self: start;
    margin-top: 1rem;

    .ui.button {
      margin-right: 0.775rem;
    }
  }
`;

const validBarCode = (value: string) => {
  const required = value ? undefined : "required";
  if (required) {
    return required;
  }

  const validBarcode = isNumberOnlyBarcodeValid(value);
  return validBarcode ? undefined : "invalidCode";
};

const mustEqualCode = (value: string, allValues: any) => (value === allValues.code ? undefined : 'mustEqualCode');

interface Props extends RouteComponentProps<any>, AuthConsumerRenderProps, WithTranslation {
  isPoolRegistration?: boolean;
  isEmployeesOverview?: boolean;
}

interface State {
  employeeId: number;
  firstName: string;
  lastName: string;
  birthDate: string;
  error?: string;
  cancelTokenSource: CancelTokenSource;
}

class TestRegistrationManualInput extends Component<Props, State> {

  initialFormValues = {
    code: "",
    codeConfirm: ""
  };

  constructor(props: Props) {
    super(props);

    const cancelTokenSource = axios.CancelToken.source();

    const {isPoolRegistration, isEmployeesOverview} = props;
    const locationState: any = props.location.state;
    let employeeId = undefined;
    let firstName = "";
    let lastName = "";
    let birthDate = "";
    if (isPoolRegistration) {
      employeeId = -1;

    } else if (isEmployeesOverview) {
      employeeId = locationState.id;
      firstName = locationState.firstName;
      lastName = locationState.lastName;
      birthDate = locationState.birthDate;
    } else {
      employeeId = locationState.employeeId;
      firstName = locationState.patientFirstName;
      lastName = locationState.patientLastName;
      birthDate = locationState.patientBirthDate;
    }

    this.state = {
      employeeId: employeeId,
      firstName: firstName,
      lastName: lastName,
      birthDate: birthDate,
      cancelTokenSource
    };
  }

  onRegisterTestkit = async (values: any) => {
    const {isPoolRegistration} = this.props;
    const {employeeId, firstName, lastName, cancelTokenSource} = this.state;

    const numberCode = values.code;
    const numberParts = [
      numberCode.substr(0, 4),
      numberCode.substr(4, 4),
      numberCode.substr(8, 4),
      numberCode.substr(12, 4)
    ];
    const testkitCode = `MSNT-${numberParts.join('-')}`;

    this.setState({
      error: undefined
    });

    try {
      if (!isPoolRegistration) {
        const request: TestkitRegistrationRequestDto = {
          employeeId,
          companyId: -1,
          testkitCode
        };

        await registerTestkit(request, cancelTokenSource);
      } else {
        await registerPoolTestkit(testkitCode, cancelTokenSource);
      }
    } catch (e) {
      const {t} = this.props;
      let error = t("testKitRegistrationManual.error.general");

      const errorData = e.response.data;
      if (errorData && !_.isEmpty(errorData.violations)) {

        const errorCode = errorData.violations[0].errorCode;
        if ([
          "INVALID_BARCODE",
          "BARCODE_ALREADY_EXISTS",
          "ANOTHER_REGISTERED_TESTKIT_IN_PROGRESS_ALREADY_EXIST"
        ].includes(errorCode)) {
          error = t(`testkitToken.error.${errorCode}`, {
            employeeName: isPoolRegistration
                ? ""
                : `${firstName} ${lastName}`,
            code: testkitCode
          });
        }
      }

      this.setState({
        error: error
      });

      return;
    }

    this.goToParent({
      registeredTestkitCode: testkitCode
    });
  };

  goToParent = (state?: any) => {
    const {isPoolRegistration, isEmployeesOverview} = this.props;
    const {employeeId} = this.state;
    let backPath = "/testkit-registration";
    if (isPoolRegistration) {
      backPath = "/pool-testkit-registration";
    } else if (isEmployeesOverview) {
      backPath = "/employees/overview";
      this.props.history.push(backPath, {employeeId: employeeId});
      return;
    }
    this.props.history.push(backPath, state);
  };

  render(): React.ReactNode {

    const {isPoolRegistration, t} = this.props;
    const {firstName, lastName, birthDate} = this.state;

    return <TestRegistrationManualInputDiv className="test-registration">

      <CompanyDataHeader />

      <div className="title-h1">{t(`testKitRegistrationManual${isPoolRegistration ? ".pool" : ""}.title`)}</div>

      <div className="content-container">
        {
          !isPoolRegistration &&
          <div className="data-row">
            <div className="column-header">
              {t("testKitRegistrationManual.info.employee")}
            </div>

            <div className="column-value">
              {`${firstName} ${lastName}, ${birthDate}`}
            </div>
          </div>
        }

        <Image className="barcode-sample" src={barcodeSample} />

        <div className="input-info-row">
          {t("testKitRegistrationManual.info.barcodeInput")}
        </div>

        {this.renderRegisterTestkitForm()}
      </div>

    </TestRegistrationManualInputDiv>;
  }

  renderRegisterTestkitForm = (): React.ReactNode => {
    return (
        <FinalForm
            onSubmit={(values) => this.onRegisterTestkit(values)}
            initialValues={this.initialFormValues}
            subscription={{values: true, pristine: true, submitting: true}}
            render={this.renderForm}
        />
    );
  };

  renderForm = ({submitting, values, handleSubmit, form}: FormRenderProps): React.ReactNode => {

    const {t} = this.props;
    const {error} = this.state;

    return (
        <div style={{height: "100%"}}>
          <Grid className="form-grid">
            <Grid.Row>
              <Grid.Column verticalAlign="middle" width={4}>
                <label htmlFor="code">{t("testKitRegistrationManual.form.input.code")}:</label>
              </Grid.Column>
              <Grid.Column width={2} verticalAlign="middle" textAlign="right" className="misanto-prefix">
                MSNT
              </Grid.Column>
              <Grid.Column width={10}>
                <Field
                    id="code"
                    name="code"
                    component={FinalFormInput}
                    clearable
                    validate={validBarCode}
                >
                  {({input, meta}) => (
                      <>
                        <FinalFormInput
                            id="code"
                            name="code"
                            input={input}
                            onPaste={(evt: any) => {
                              evt.preventDefault();
                              evt.stopPropagation();
                            }}
                            onDrop={(evt: any) => {
                              evt.preventDefault();
                              evt.stopPropagation();
                            }}
                            autoComplete="off"
                            meta={meta}
                        />
                        {
                          meta.error && meta.touched &&
                          <span className="field-error">
                                                {t(`testKitRegistrationManual.form.error.${meta.error}`)}
                                            </span>}
                      </>
                  )}
                </Field>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column verticalAlign="middle" width={4}>
                <label htmlFor="codeConfirm">{t("testKitRegistrationManual.form.input.codeConfirm")}:</label>
              </Grid.Column>
              <Grid.Column width={2} verticalAlign="middle" textAlign="right" className="misanto-prefix">
                MSNT
              </Grid.Column>
              <Grid.Column width={10}>
                <Field
                    id="codeConfirm"
                    name="codeConfirm"
                    component={FinalFormInput}
                    clearable
                    validate={mustEqualCode}
                >
                  {({input, meta}) => (
                      <>
                        <FinalFormInput
                            id="codeConfirm"
                            name="codeConfirm"
                            input={input}
                            onPaste={(evt: any) => {
                              evt.preventDefault();
                              evt.stopPropagation();
                            }}
                            onDrop={(evt: any) => {
                              evt.preventDefault();
                              evt.stopPropagation();
                            }}
                            autoComplete="off"
                            meta={meta}
                        />
                        {
                          meta.error && meta.touched &&
                          <span className="field-error">
                                                {t(`testKitRegistrationManual.form.error.${meta.error}`)}
                                            </span>}
                      </>
                  )}
                </Field>
              </Grid.Column>
            </Grid.Row>

            {
              error &&
              <Grid.Row className="error">
                <Grid.Column verticalAlign="middle" width={4} />
                <Grid.Column width={2} />
                <Grid.Column width={10}>
                  {applyStyles(error)}
                </Grid.Column>
              </Grid.Row>
            }

            <Grid.Row>
              <Grid.Column width={4}>
              </Grid.Column>
              <Grid.Column width={12}>
                <div className="page-actions">
                  <Button
                      type="button"
                      className="action-button"
                      primary
                      onClick={handleSubmit}
                  >
                    {t("testKitRegistrationManual.form.action.register")}
                  </Button>

                  <Button
                      type="button"
                      className="action-button"
                      color={"grey"}
                      onClick={() => this.goToParent()}
                  >
                    {t("action.back")}
                  </Button>
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
    );
  };

}

export default withRouterWorkaround(
    withAuthContext(
        withTranslation(["mipoco"])(
            TestRegistrationManualInput)));
