import {CancelTokenSource} from "axios";
import {getAccessToken} from "service/browserStorageServices";
import axios from "service/http";
import {
  EmployeeDto,
  EmployeeEmailRequest,
  EmployeeExportRequest,
  EmployeeImportDto,
  EmployeeImportRequest,
  EmployeeOverviewDto,
  EmployeeSearchRequest,
  StockOrderDto,
  UpsertEmployeeDto,
  UpsertEmployeeImportDto
} from "ts-types/api.types";

const baseUrl = process.env.REACT_APP_API_BASE_URL;
const apiUrl = `${baseUrl}/employees`;

export const searchEmployees = (
    employeeSearchRequest: Partial<EmployeeSearchRequest>,
    source: CancelTokenSource): Promise<Array<EmployeeDto>> => {

  return axios
  .post(`${apiUrl}/search`, employeeSearchRequest, {cancelToken: source.token})
  .then(response => response.data);
};

export const getEmployeeData = (
    id: number, source: CancelTokenSource): Promise<EmployeeDto> => {

  return axios
  .get(`${apiUrl}/employee/${id}`, {cancelToken: source.token})
  .then(response => response.data);
};

export const getEmployeeOverviewData = (
    id: number, source: CancelTokenSource): Promise<EmployeeOverviewDto> => {

  return axios
  .get(`${apiUrl}/overview/${id}`, {cancelToken: source.token})
  .then(response => response.data);
};

export const getStockOrdersByEmployeeId = (
    id: number, cancelSourceToken: CancelTokenSource): Promise<Array<StockOrderDto>> => {

  return axios
  .get(`${apiUrl}/stock-orders/${id}`, {cancelToken: cancelSourceToken.token})
  .then(response => response.data);
};

export const findEmployeeById = (
    id: number, source: CancelTokenSource): Promise<UpsertEmployeeDto> => {

  return axios
  .get(`${apiUrl}/${id}`, {cancelToken: source.token})
  .then(response => response.data);
};

export const saveEmployee = (
    upsertEmployeeDto: Partial<UpsertEmployeeDto>,
    source: CancelTokenSource): Promise<UpsertEmployeeDto> => {

  return axios
  .post(apiUrl, upsertEmployeeDto, {cancelToken: source.token})
  .then(response => response.data);
};

export const editEmployee = (
    id: number,
    upsertEmployeeDto: Partial<UpsertEmployeeDto>,
    source: CancelTokenSource): Promise<EmployeeDto> => {

  return axios
  .put(`${apiUrl}/${id}`, upsertEmployeeDto, {cancelToken: source.token})
  .then(response => response.data);
};

export const deleteEmployee = (
    id: number, source: CancelTokenSource): Promise<EmployeeDto> => {

  return axios
  .delete(`${apiUrl}/${id}`, {cancelToken: source.token})
  .then(response => response.data);
};

export const uploadEmployeesFile = (
    values: Partial<UpsertEmployeeImportDto>,
    source: CancelTokenSource): Promise<Array<EmployeeImportDto>> => {

  let bodyFormData = new FormData();
  Object.keys(values).forEach((key) => {
    let value = values[key as keyof UpsertEmployeeImportDto];

    if (value) {
      if (typeof value === "number") {
        //@ts-ignore
        value = value.toString();
      }
      //@ts-ignore
      bodyFormData.append(key, value);
    }

  });

  return axios
  .post(`${apiUrl}/upload`, bodyFormData, {cancelToken: source.token})
  .then(response => response.data);
};

export const importEmployees = (
    request: Partial<EmployeeImportRequest>, source: CancelTokenSource): Promise<string> => {

  return axios
  .post(`${apiUrl}/import`, request, {cancelToken: source.token})
  .then(response => response.data);
};

export const sendEmailToEmployees = (
    request: EmployeeEmailRequest, source: CancelTokenSource): Promise<string> => {

  return axios
  .post(`${apiUrl}/send-email`, request, {cancelToken: source.token})
  .then(response => response.data);
};

export const getEmployeeExportUrl = (
    request: Partial<EmployeeExportRequest>) => {

  const searchParams = new URLSearchParams();
  searchParams.append("upn", getAccessToken() ? getAccessToken() + "" : "");
  searchParams.append("companyId", request.companyId ? "" + request.companyId : "");
  searchParams.append("language", request.language ? "" + request.language : "de");
  searchParams.append("includesInactive", request.includesInactive ? "" + request.includesInactive : "");
  searchParams.append("searchKey", request.searchKey ? "" + request.searchKey : "");

  const url = `${apiUrl}/employee-data-export?${searchParams.toString()}`;
  return url;
};