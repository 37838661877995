import {CancelTokenSource} from "axios";
import {getAccessToken} from "service/browserStorageServices";
import axios from "service/http";
import {InterventionExternalCode} from "ts-types/api.enums";
import {
  ArticleGroupDto,
  CompanyDataDto,
  CompanyDto,
  CompanyOverviewDataDto,
  DivisionDto,
  GuarantorCompanyOverviewDataDto,
  GuarantorCompanyOverviewRequest,
  MedicamentDto,
  StockManagementOverviewDataDto,
  StockOrderDto,
  UpsertCompanyDto
} from "ts-types/api.types";

const baseUrl = process.env.REACT_APP_API_BASE_URL;
const apiUrl = `${baseUrl}/company`;

export const getCompanyData = (
    cancelSourceToken: CancelTokenSource): Promise<CompanyDataDto> => {

  return axios
  .get(`${apiUrl}/data`, {cancelToken: cancelSourceToken.token})
  .then(response => response.data);
};

export const getCompanyOverviewData = (
    cancelSourceToken: CancelTokenSource): Promise<CompanyOverviewDataDto> => {

  return axios
  .get(`${apiUrl}/overviewData`, {cancelToken: cancelSourceToken.token})
  .then(response => response.data);
};

export const getGuarantorCompanyOverviewData = (
    request: Partial<GuarantorCompanyOverviewRequest>,
    cancelSourceToken: CancelTokenSource): Promise<GuarantorCompanyOverviewDataDto> => {

  return axios
  .post(`${apiUrl}/guarantor-overview-data`, request, {cancelToken: cancelSourceToken.token})
  .then(response => response.data);
};

export const getGuarantorCompanyOverviewExport = (
    request: Partial<GuarantorCompanyOverviewRequest>,
    cancelSourceToken: CancelTokenSource) => {

  return axios
  .post(`${apiUrl}/guarantor-overview-data-export`, request, {cancelToken: cancelSourceToken.token});
};

export const getGuarantorCompanyOverviewInvoicingExport = (
    request: Partial<GuarantorCompanyOverviewRequest>,
    cancelSourceToken: CancelTokenSource) => {

  return axios
  .post(`${apiUrl}/guarantor-overview-invoicing-data-export`,
      request,
      {cancelToken: cancelSourceToken.token});
};

export const getGuarantorCompanyOverviewExportUrl = (
    request: Partial<GuarantorCompanyOverviewRequest>) => {

  const searchParams = new URLSearchParams();

  searchParams.append("upn", getAccessToken() ? getAccessToken() + "" : "");
  searchParams.append("companyType", request.companyType ? "" + request.companyType : "");
  searchParams.append("companySubType", request.companySubType ? "" + request.companySubType : "");

  const firstDayOfWeek = request.firstDayOfWeek;
  if (firstDayOfWeek) {
    searchParams.append("firstDayOfWeek", firstDayOfWeek);
  }

  searchParams.append("language", request.language ? "" + request.language : "de");

  const url = `${apiUrl}/guarantor-overview-data-export?${searchParams.toString()}`;
  return url;
};

export const getGuarantorCompanyOverviewInvoicingExportUrl = (
    request: Partial<GuarantorCompanyOverviewRequest>) => {

  const searchParams = new URLSearchParams();

  searchParams.append("upn", getAccessToken() ? getAccessToken() + "" : "");
  searchParams.append("companyType", request.companyType ? "" + request.companyType : "");
  searchParams.append("companySubType", request.companySubType ? "" + request.companySubType : "");

  const firstDayOfWeek = request.firstDayOfWeek;
  if (firstDayOfWeek) {
    searchParams.append("firstDayOfWeek", firstDayOfWeek);
  }

  searchParams.append("language", request.language ? "" + request.language : "de");

  const url = `${apiUrl}/guarantor-overview-invoicing-data-export?${searchParams.toString()}`;
  return url;
};

export const findCompanyById = (
    companyId: number, cancelSourceToken: CancelTokenSource): Promise<UpsertCompanyDto> => {

  return axios
  .get(`${apiUrl}/${companyId}`, {cancelToken: cancelSourceToken.token})
  .then(response => response.data);
};

export const getCompanyShippableMedicaments = (
    companyId: number,
    cancelSourceToken: CancelTokenSource): Promise<Array<MedicamentDto>> => {

  return axios
  .get(`${apiUrl}/shippable/${companyId}`, {cancelToken: cancelSourceToken.token})
  .then(response => response.data);
};

export const getCompanyExternalCodeFromCompanyArticleGroups = (companyId: number, source: CancelTokenSource)
    : Promise<Array<InterventionExternalCode>> => {
  return axios
  .get(`${apiUrl}/company-external-codes/${companyId}`, {cancelToken: source.token})
  .then(response => response.data);
};

export const getCompanyExternalCodeFromCompanyArticleGroupsWithCertificates =
    (companyId: number, source: CancelTokenSource): Promise<Array<InterventionExternalCode>> => {
      return axios
      .get(`${apiUrl}/company-external-codes-with-certificates/${companyId}`,
          {cancelToken: source.token})
      .then(response => response.data);
    };

export const getCompanyDivisions = (
    companyId: number,
    cancelSourceToken: CancelTokenSource): Promise<Array<DivisionDto>> => {

  return axios
  .get(`${apiUrl}/divisions/${companyId}`, {cancelToken: cancelSourceToken.token})
  .then(response => response.data);
};

export const editCompany = (
    companyId: number,
    request: Partial<UpsertCompanyDto>,
    cancelSourceToken: CancelTokenSource): Promise<CompanyDto> => {

  return axios
  .put(`${apiUrl}/${companyId}`, request, {cancelToken: cancelSourceToken.token})
  .then(response => response.data);
};

export const getAllArticleGroupsByCompanyId = (
    cancelSourceToken: CancelTokenSource): Promise<Array<ArticleGroupDto>> => {

  return axios
  .get(`${apiUrl}/article-groups`, {cancelToken: cancelSourceToken.token})
  .then(response => response.data);
};

export const getAllStockOrdersByCompanyId = (
    cancelSourceToken: CancelTokenSource): Promise<Array<StockOrderDto>> => {

  return axios
  .get(`${apiUrl}/stock-orders`, {cancelToken: cancelSourceToken.token})
  .then(response => response.data);
};

export const getStockManagementOverviewData = (
    cancelSourceToken: CancelTokenSource): Promise<StockManagementOverviewDataDto> => {

  return axios
  .get(`${apiUrl}/stock-management-overview`, {cancelToken: cancelSourceToken.token})
  .then(response => response.data);
};
