import {CancelTokenSource} from "axios";
import axios from "service/http";
import {
  EmployeeTokenDataDto,
  PoolTestKitOverviewProjection,
  SendTestkitRegistrationRequest,
  TestBarCodeRegistrationRequest,
  TestkitDeregistrationRequestDto,
  TestkitRegistrationRequestDto,
  TestPoolingRegistrationSearchResult,
  TestRegistrationCreationResponse,
  TestRegistrationOverviewDto,
  TestRegistrationQrCodeRequest,
  TestResultUpdateResponseDto
} from "ts-types/api.types";

const authBaseUrl = process.env.REACT_APP_AUTH_BASE_URL;
const baseUrl = process.env.REACT_APP_API_BASE_URL;
const apiUrl = `${baseUrl}/testkit`;

export const searchTestKitRegistrations = (
    query: string,
    cancelSourceToken: CancelTokenSource): Promise<TestRegistrationOverviewDto[]> => {

  return axios
  .get(`${apiUrl}/registration/search`, {
    params: {
      query: query
    },
    cancelToken: cancelSourceToken.token
  })
  .then(response => response.data);
};

export const searchPoolTestKitRegistrations = (
    query: string,
    cancelSourceToken: CancelTokenSource): Promise<TestPoolingRegistrationSearchResult[]> => {

  return axios
  .get(`${apiUrl}/pool-registration/search`, {
    params: {
      query: query
    },
    cancelToken: cancelSourceToken.token
  })
  .then(response => response.data);
};

export const deletePoolTestKit = (
    poolTestId: number,
    cancelSourceToken: CancelTokenSource): Promise<string> => {

  return axios
  .delete(`${apiUrl}/pool/${poolTestId}`,
      {
        cancelToken: cancelSourceToken.token
      })
  .then(response => response.data);
};

export const sendPoolTestKits = (
    request: Partial<SendTestkitRegistrationRequest>,
    cancelSourceToken: CancelTokenSource): Promise<number> => {

  return axios
  .post(`${apiUrl}/pool/send`,
      request,
      {
        cancelToken: cancelSourceToken.token
      })
  .then(response => response.data);
};

export const getPoolTestKits = (
    poolTestId: number,
    cancelSourceToken: CancelTokenSource): Promise<PoolTestKitOverviewProjection[]> => {

  return axios
  .get(`${apiUrl}/pool/${poolTestId}`, {
    cancelToken: cancelSourceToken.token
  })
  .then(response => response.data);
};

export const addTestToPool = (
    poolTestId: number,
    testKitCode: string,
    cancelSourceToken: CancelTokenSource): Promise<string> => {

  return axios
  .post(`${apiUrl}/pool/${poolTestId}/test-kit`,
      {},
      {
        params: {
          testKitCode
        },
        cancelToken: cancelSourceToken.token
      })
  .then(response => response.data);
};

export const removeTestFromPool = (
    poolTestId: number,
    testKitCode: string,
    cancelSourceToken: CancelTokenSource): Promise<string> => {

  return axios
  .delete(`${apiUrl}/pool/${poolTestId}/test-kit/${testKitCode}`, {
    cancelToken: cancelSourceToken.token
  })
  .then(response => response.data);
};

export const registerTestkit = (
    request: TestkitRegistrationRequestDto,
    cancelSourceToken: CancelTokenSource): Promise<String> => {

  return axios
  .post(`${apiUrl}/register`, request, {
    cancelToken: cancelSourceToken.token
  })
  .then(response => response.data);
};

export const registerPoolTestkit = (
    testKitCode: string,
    cancelSourceToken: CancelTokenSource): Promise<TestPoolingRegistrationSearchResult> => {

  const formData = new FormData();
  formData.append("testKitCode", testKitCode);

  return axios
  .post(`${apiUrl}/register-pool`, formData, {
    cancelToken: cancelSourceToken.token
  })
  .then(response => response.data);
};


export const deregisterTestkit = (
    request: TestkitDeregistrationRequestDto,
    cancelSourceToken: CancelTokenSource): Promise<String> => {

  return axios
  .post(`${apiUrl}/deregister`, request, {
    cancelToken: cancelSourceToken.token
  })
  .then(response => response.data);
};

export const createTestkitQrcodeBase64String = (
    request: TestRegistrationQrCodeRequest,
    source: CancelTokenSource): Promise<TestRegistrationCreationResponse> => {

  return axios
  .post(`${apiUrl}/generate-testkit-qrcode`, request, {cancelToken: source.token})
  .then(response => response.data);
};

export const createRapidTestkitQrcodeBase64String = (
    request: TestRegistrationQrCodeRequest,
    source: CancelTokenSource): Promise<TestRegistrationCreationResponse> => {

  return axios
  .post(`${apiUrl}/generate-testkit-qrcode/rapid`, request, {cancelToken: source.token})
  .then(response => response.data);
};

export const createCertificateQrcodeBase64String = (
    request: TestRegistrationQrCodeRequest,
    certificateType: string,
    source: CancelTokenSource): Promise<TestRegistrationCreationResponse> => {

  return axios
  .post(`${apiUrl}/generate-certificate-qrcode/${certificateType}`, request, {cancelToken: source.token})
  .then(response => response.data);
};

export const registerTestkitBarCode = (
    request: TestBarCodeRegistrationRequest,
    source: CancelTokenSource): Promise<string> => {

  return axios
  .post(`${authBaseUrl}/pub/testkit/token-based`, request, {cancelToken: source.token})
  .then(response => response.data);
};

export const registerRapidTestkitResult = (
    token: string, testResult: string, file: any,
    source: CancelTokenSource): Promise<TestResultUpdateResponseDto> => {

  const formData = new FormData();
  formData.append("file", file);
  formData.append("token", token);
  formData.append("testResult", testResult);

  return axios
  .post(`${authBaseUrl}/pub/testkit/token-based/rapid`, formData, {cancelToken: source.token})
  .then(response => response.data);
};

export const registerCertificate = (
    token: string, certificateType: string, file: any,
    source: CancelTokenSource): Promise<string> => {

  const formData = new FormData();
  formData.append("file", file);
  formData.append("token", token);
  formData.append("certificateType", certificateType);

  return axios
  .post(`${authBaseUrl}/pub/certificate/token-based`, formData, {cancelToken: source.token})
  .then(response => response.data);
};


export const getEmployeeTokenData = (
    token: string,
    cancelSourceToken: CancelTokenSource): Promise<EmployeeTokenDataDto> => {

  return axios
  .get(`${authBaseUrl}/pub/testkit`, {
    params: {
      token
    },
    cancelToken: cancelSourceToken.token
  })
  .then(response => response.data);
};

export const sendRegisteredTestkits = (
    request: Partial<SendTestkitRegistrationRequest>,
    cancelSourceToken: CancelTokenSource): Promise<number> => {

  return axios
  .post(`${apiUrl}/send-registered-testkits`, request, {cancelToken: cancelSourceToken.token})
  .then(response => response.data);
};

export const deleteInterventionBooking = (
    interventionBookingId: number,
    cancelSourceToken: CancelTokenSource): Promise<string> => {

  return axios
  .delete(`${apiUrl}/appointment/${interventionBookingId}`,
      {
        cancelToken: cancelSourceToken.token
      })
  .then(response => response.data);
};

export const deleteEmployeeInterventionBooking = (
    interventionBookingId: number,
    patientId: number,
    cancelSourceToken: CancelTokenSource): Promise<string> => {

  return axios
      .delete(`${apiUrl}/appointment/${interventionBookingId}/patient/${patientId}`,
          {
            cancelToken: cancelSourceToken.token
          })
      .then(response => response.data);
};