import {AxiosPromise, AxiosRequestConfig, AxiosResponse, CancelTokenSource} from "axios";
import {getAuthorizationHeaderValue} from "service/browserStorageServices";
import axios from "service/http";
import {
  EmployeeAccountDisplayDto,
  EmployeeInviteRequest,
  PasswordlessLoginRequestDto,
  UserDto
} from "ts-types/api.types";

const baseUrl = process.env.REACT_APP_API_BASE_URL;
const baseAuthUrl = process.env.REACT_APP_AUTH_BASE_URL;

const localeResUrl = process.env.REACT_APP_LOCALE_BASE_URL;
const localePublicResUrl = process.env.REACT_APP_PUBLIC_LOCALE_BASE_URL;

export const sendMagicLink = (
    request: PasswordlessLoginRequestDto): Promise<AxiosResponse> => {

  return axios({
    url: `${baseAuthUrl}/pub/passwordless`,
    method: 'post',
    withCredentials: true,
    data: {
      ...request
    }
  });
};

export const getCandidateEmployees = (
    token: string, cancelSourceToken: CancelTokenSource): Promise<EmployeeAccountDisplayDto[]> => {

  return axios.get(`${baseAuthUrl}/pub/passwordless/employees`, {
    params: {
      token
    }
  }).then(response => {
    return response.data;
  });
};

export const sendInviteToEmployee = (
    employeeId: number): Promise<AxiosResponse> => {

  return axios.post(`${baseUrl}/invite-employee/${employeeId}`);
};

export const sendInviteToAllIntitalEmployees = (): Promise<AxiosResponse> => {

  return axios.post(`${baseUrl}/invite-all-initial-employees`);
};

export const sendInviteToAllSelectedInitialEmployees = (request: EmployeeInviteRequest, source: CancelTokenSource): Promise<AxiosResponse> => {
  return axios
      .post(`${baseUrl}/invite-all-selected-initial-employees`, request, {cancelToken: source.token})
}

export const retrieveAccessToken = (
    username: string, password: string, verificationToken?: string) => {

  return axios({
    url: `${baseAuthUrl}/auth`,
    method: 'post',
    withCredentials: true,
    data: {
      username: username,
      password: password,
      verificationToken
    }
  });
};

export const getCurrentUser = (token?: string): Promise<UserDto> => {

  const config: AxiosRequestConfig = {};
  if (token) {
    config.params = {
      upn: token
    };
  }

  return axios.get(`${baseUrl}/current-user`, config).then(response => response.data);
};

export const verifyToken = (token: string, code?: string, employeeId?: string): AxiosPromise<string> => {

  const formData = new FormData();
  formData.set("token", token);
  if (code) {
    formData.set("code", code);
  }
  if (employeeId) {
    formData.set("employeeId", employeeId);
  }

  return axios.post(`${baseAuthUrl}/auth`, formData, {});
};

export const get2faData = (cancelSourceToken: CancelTokenSource): Promise<string> => {
  return axios.get(
      `${baseUrl}/current-user/mfa`,
      {cancelToken: cancelSourceToken.token})
  .then(response => response.data);
};

export const enable2fa = (code: string, cancelSourceToken: CancelTokenSource): Promise<string> => {
  const formData = new FormData();
  formData.set("code", code);
  return axios.post(
      `${baseUrl}/current-user/mfa/enable`,
      formData,
      {cancelToken: cancelSourceToken.token})
  .then(response => response.data);
};

export const loadLocaleResources = (url: string, isPrivateResource: boolean) => {

  const config: any = {
    url: `${localePublicResUrl}/${url}`,
    method: "get"
  };

  if (isPrivateResource) {
    config.url = `${localeResUrl}/${url}`;
    config.headers = {
      "Authorization": getAuthorizationHeaderValue()
    };
  }

  return axios(config);
};
