import {CancelTokenSource} from "axios";
import axios from "service/http";
import {ContractDocumentsDto, UpsertContractConfirmationsDto} from "../ts-types/api.types";

const baseUrl = process.env.REACT_APP_API_BASE_URL;
const apiUrl = `${baseUrl}/contract`;

export const getContactDocuments = (
    cancelSourceToken: CancelTokenSource): Promise<ContractDocumentsDto> => {

    return axios
    .get(`${apiUrl}/data`, {cancelToken: cancelSourceToken.token})
    .then(response => response.data);
}

export const saveCompanyContractConfirmation = (
    companyId: number,
    request: Partial<UpsertContractConfirmationsDto>,
    cancelSourceToken: CancelTokenSource): Promise<string> => {

    return axios
    .put(`${apiUrl}/company/${companyId}`, request, {cancelToken: cancelSourceToken.token})
    .then(response => response.data);
}

export const saveEmployeeContractConfirmation = (
    employeeId: string,
    request: Partial<UpsertContractConfirmationsDto>,
    cancelSourceToken: CancelTokenSource): Promise<string> => {

    return axios
    .put(`${apiUrl}/employee/${employeeId}`, request, {cancelToken: cancelSourceToken.token})
    .then(response => response.data);
}
