export const errorUtils = {
  invalidInput: "INVALID_INPUT",
  invalidEmail: "INVALID_EMAIL",
  duplicateEmail: "DUPLICATE_EMAIL",
  invalidBirthDate: "INVALID_BIRTHDATE",
  missingMandatoryCredentials: "MISSING_MANDATORY_CREDENTIALS",
  duplicatePersonCredentials: "DUPLICATE_PERSON_CREDENTIALS",
  unknownEmployee: "UNKNOWN_EMPLOYEE",
  employeeEmailAlreadyExists: "EMPLOYEE_EMAIL_ALREADY_EXISTS",
  invalidVerificationCode: "INVALID_VERIFICATION_CODE",
  emailAlreadyRegistered: "EMAIL_ALREADY_REGISTERED",
  questionAnswersAreMandatory: "QUESTION_ANSWERS_ARE_MANDATORY",
  unmetMinimumAge: "UNMET_MINIMUM_AGE",
  unknownZipCode: "UNKNOWN_ZIP",
  inconsistentLocation: "INCONSISTENT_LOCATION",
  productNotAvailableAtLocation: "PRODUCT_NOT_AVAILABLE_AT_LOCATION",
  deletingReferencedAppointmentNotAllowed: "DELETING_REFERENCED_BOOKING_NOT_ALLOWED"
};
