import {AuthConsumerRenderProps, withAuthContext} from "auth/AuthContext";
import {CancelTokenSource} from "axios";
import {BigActionButton} from "component/BigActionButton";
import {CompanyDataConsumerRenderProps, withCompanyDataContext} from "component/CompanyDataContext";
import CompanyDataHeader from "component/CompanyDataHeader";
import DeleteActionButton from "component/DeleteActionButton";
import EditActionButton from "component/EditActionButton";
import HeaderMessage from "component/HeaderMessage";
import LoaderComponent from "component/LoaderComponent";
import StyledErrorMessage from "component/StyledErrorMessage";
import TestResultIcon from "component/TestResultIcon";
import VirtualizedTable from "component/VirtualizedTable";
import _ from "lodash";
import moment from "moment";
import React, {Component, Fragment} from "react";
import {withTranslation, WithTranslation} from "react-i18next";
import Lightbox from 'react-image-lightbox';
import {RouteComponentProps} from "react-router";
import TestInfo from "route/employee/TestInfo";
import QrcodeActionButton from "route/test-registration/QrcodeActionButton";
import {Button, Icon, Loader, Modal, Popup} from "semantic-ui-react";
import {getAllArticleGroupsByCompanyId} from "service/companyServices";
import {getEmployeeOverviewData} from "service/employeeServices";
import axios from "service/http";
import {getAllInterventions} from "service/interventionServices";
import {getAllResources} from "service/resourceServices";
import {
  createCertificateQrcodeBase64String,
  createRapidTestkitQrcodeBase64String,
  createTestkitQrcodeBase64String,
  deleteEmployeeInterventionBooking
} from "service/testKitServices";
import {getTestResultImage, getTestResultsForEmployeeOverview} from "service/testResultServices";
import styled from "styled-components";
import {debounce} from "ts-debounce";
import {InterventionBookingStatus, InterventionExternalCode} from "ts-types/api.enums";
import {
  EmployeeOverviewDto,
  EmployeeTestResultDto,
  EmployeeTestResultImageDto,
  InterventionDto,
  ResourceDisplayDto,
  TestRegistrationQrCodeRequest
} from "ts-types/api.types";
import {isoToDisplayDate} from "util/dateUtils";
import {noop} from "util/functionUtils";
import {interventionDescription} from "util/interventionUtils";
import {applyStyles} from "util/localizationUtils";
import {withRouterWorkaround} from "util/workaroundUtils";

const EmployeeTestResultDiv = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;

  .header-message-text {
    font-size: 1.5rem;
  }

  .search-form {
    padding-left: 0.75rem;
    margin-bottom: 1rem;

    label {
      margin-right: 1rem;
    }

    .ui.input {
      min-width: 15rem;
    }

    button {
      margin-left: 1rem;
    }
  }

  .results-table {
    flex: 1 1 auto;
    min-height: 200px;

    .row-actions {
      i.icon,
      i.icons {
        color: #768aff;
      }
    }
  }

  .icon-button {
    padding: 2px !important;
    background: transparent !important;
  }

  .back-btn, .page-actions {
    align-self: flex-end;
    margin-top: 1rem;
  }

  .page-actions {
    display: inline-block;
  }
`;

const StyledButton = styled(Button)`
  padding: 2px !important;
  margin-bottom: 2px !important;
  background: transparent !important;
`;

const ButtonContentDiv = styled.div`

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: stretch;

  margin-top: 1.5rem;

  > span {
    margin-bottom: 2rem;
  }

  @media (max-width: 768px) {
    text-align: center;

    > span {
      display: block;
      width: 100%;

      button.login-button {
        width: 100%;
        max-width: unset;
      }
    }
  }

  @media (min-width: 768px) {
    span > button {
      height: 100%;
    }

    > span:not(:last-child) {
      margin-right: 2rem;
    }
  }

  .login-button {
    font-size: 1.15rem;
    min-height: 4.5rem;
    padding: 0.5rem 0.75rem;
    max-width: 25rem;
  }

`;

const QrContentDiv = styled.div`

  padding: 0.2rem;

  .employee-data {
    font-size: 1.2rem;
    font-weight: 500;
    margin: 0 0 0.75rem 0.2rem;
    white-space: nowrap;
  }

  .qrcode-wrapper {
    display: inline-block;
    border: 1px solid #e5e5e5;
    padding: 1rem;
    margin-bottom: 0.5rem;

    .qrcode {
      background-size: contain;
      width: 150px;
      height: 150px;
    }
  }

  .actions-row {
    margin: 0.75rem 0;

    .ui.button {
      border-radius: unset;
    }
  }

  .link-info {
    color: #407A8C;
    margin: 0.75rem 0.1rem 0;
    padding-top: 0.5rem;
    border-top: 1px solid #e5e5e5;
  }
`;

const PopupStyle = {
  borderRadius: 5,
  opacity: 0.7,
  padding: '7px'
};

interface InfoRowProps {
  backgroundColor?: string;
}

const StyledInfoRow = styled.div`
  background-color: ${(props: InfoRowProps) => props.backgroundColor || 'inherited'};
  width: 58%;
  display: flex;
  padding: 0.5rem;

  *:first-child {
    width: 150px;
  }

  *:nth-child(2) {
    flex: 1;
  }

  &.target-employee {
    margin-bottom: 1.5rem;
  }
`;

interface Props extends RouteComponentProps<any>,
    AuthConsumerRenderProps,
    CompanyDataConsumerRenderProps,
    WithTranslation {
}

interface State {
  testResults: Array<EmployeeTestResultDto>;
  testResultsLoaded: boolean;
  resources: ResourceDisplayDto[];
  resourceMap: { [id: number]: ResourceDisplayDto };
  employee?: EmployeeOverviewDto;
  qrcodeb64?: string;
  testRegistrationUrl?: string;
  qrcodeVisible: boolean;
  isWelcome: boolean;
  resultImage?: EmployeeTestResultImageDto;
  resultImageLoading: boolean;
  orderTestsDisabled: boolean;
  schedulePcrTestDisabled: boolean;
  scheduleRapidTestDisabled: boolean;
  testResultIx?: number;
  testResultModalOpen: boolean;
  interventions: InterventionDto[];
  errorMessages: Array<string>;
  cancelTokenSource: CancelTokenSource;
}


class EmployeeOverview extends Component<Props, State> {

  constructor(props: Props) {
    super(props);

    const locationState: any = props.location.state;
    const isWelcome = locationState && locationState.welcome;

    const cancelTokenSource = axios.CancelToken.source();

    this.state = {
      testResults: [],
      testResultsLoaded: true,
      resources: [],
      resourceMap: [],
      qrcodeVisible: false,
      isWelcome: isWelcome,
      resultImageLoading: false,
      orderTestsDisabled: true,
      schedulePcrTestDisabled: true,
      scheduleRapidTestDisabled: true,
      interventions: [],
      errorMessages: [],
      testResultModalOpen: false,
      cancelTokenSource
    };

    this.loadReferenceData(cancelTokenSource);

    setTimeout(() => {
      this.fetchTestResults();
    }, 10);

  }

  loadReferenceData = async (cancelTokenSource: CancelTokenSource) => {

    const state: any = this.props.location.state;
    const employeeId = state?.employeeId;

    const {containerData} = this.props;

    await getAllInterventions(cancelTokenSource)
    .then(response => {
      this.setState({
        interventions: response
      });
    })
    .catch((e: any) => this.handleError(e.response.data))
    .finally(noop);

    try {
      if (containerData) {
        getAllArticleGroupsByCompanyId(this.state.cancelTokenSource)
        .then(result => {
          const pcrSingularTest = result.filter(
              articleGroup => articleGroup.externalCode === InterventionExternalCode.PCR_SALIVA_TEST_INDIVIDUAL_TEST);
          const pcrSalivaTest = result.filter(
              articleGroup => articleGroup.externalCode === InterventionExternalCode.PCR_SALIVA_TEST);
          const rapidTestTest = result.filter(
              articleGroup => articleGroup.externalCode === InterventionExternalCode.RAPID_SALIVA_TEST);

          this.setState({
            orderTestsDisabled: pcrSingularTest.length <= 0,
            schedulePcrTestDisabled: pcrSalivaTest.length <= 0,
            scheduleRapidTestDisabled: rapidTestTest.length <= 0
          });
        });
      }
      const resources = await getAllResources(cancelTokenSource);
      const employee = await getEmployeeOverviewData(employeeId, cancelTokenSource);
      this.setState({
        employee,
        resources,
        resourceMap: _.keyBy(resources, "id")
      });
    } finally {

    }
  };

  fetchTestResults = debounce((): void => {
    const {cancelTokenSource} = this.state;

    const state: any = this.props.location.state;
    const employeeId = state?.employeeId;

    if (!employeeId) {
      return;
    }

    const onFinally = () => {
      this.setState({
        testResultsLoaded: true
      });
    };

    this.setState({
      testResultsLoaded: false,
      testResults: []
    });

    getTestResultsForEmployeeOverview(employeeId, cancelTokenSource)
    .then(response => {
          let plannedTestResults =
              response.filter(tr => InterventionBookingStatus.PLANNED === tr.interventionBookingStatus);
          plannedTestResults =
              _.orderBy(plannedTestResults, ['appointmentDate', 'appointmentTime'], ['asc', 'desc']);
          const otherTestResults =
              response.filter(tr => InterventionBookingStatus.PLANNED !== tr.interventionBookingStatus);
          this.setState({
            testResults: [...plannedTestResults, ...otherTestResults]
          });
        }
    )
    .catch((e: any) => this.handleError(e.response.data))
    .finally(onFinally);

  }, 300);

  showTestResultModal = (testResultIx: number) => {
    this.setState({
      testResultModalOpen: true,
      testResultIx
    });
  };

  hideTestResultModal = () => {
    this.setState({
      testResultModalOpen: false,
      testResultIx: undefined
    });
  };

  isTestSchedulingEnabled = () => {
    const {containerData} = this.props;
    return containerData && containerData.testSchedulingEnabled;
  };

  deleteAppointment = async (interventionBookingId: number) => {
    const {employee, cancelTokenSource} = this.state;

    if (employee) {
      await deleteEmployeeInterventionBooking(interventionBookingId, employee.patientId, cancelTokenSource)
      .then(() => {
        this.fetchTestResults();
      })
      .catch(e => this.handleError(e.response.data));
    }
  };


  handleError(error: any) {
    const {t} = this.props;

    if (error) {
      const errorCode = error.errorCode;
      const knownErrors: Array<string> = [
        "UNKNOWN_TEST_ATTACHMENT_ID",
        "EMPTY_BYTE_ARRAY_TEST_ATTACHMENT_BLOB",
        "BLOB_CONTAINER_NOT_INITIALIZED",
        "AZURE_IS_DOWN",
        "DELETING_REFERENCED_BOOKING_NOT_ALLOWED"
      ];

      const violations: Array<any> = error.violations;

      if (violations && violations.length > 0) {
        violations.forEach(violation => {
          if (knownErrors.includes(violation.errorCode)) {
            this.setErrorMessage(t(`error.${violation.errorCode}`));
          }
        });
      }

      if (!this.state.errorMessages.length) {
        if (knownErrors.includes(errorCode)) {
          this.setErrorMessage(t(`error.${errorCode}`));
        } else {
          this.setErrorMessage(t('error.general'));
        }
      }
    }
  };

  setErrorMessage = (errorMessage?: string) => {

    const {errorMessages} = this.state;

    if (errorMessage) {

      const errMsgs = [...errorMessages];
      errMsgs.push(errorMessage);

      this.setState({
        errorMessages: errMsgs
      });
    } else {

      this.setState({
        errorMessages: []
      });
    }
  };

  closeWelcome = () => {
    this.setState({
      isWelcome: false
    });
  };

  onRegisterTestKitManually = () => {
    const {employee} = this.state;
    this.props.history.push("/employees/overview/testkit-registration", employee);
  };

  onTestOrderButtonClick = () => {
    const {employee} = this.state;
    this.props.history.push(
        "/employees/overview/test-kit-orders-list",
        {employeeId: employee!.id}
    );
  };

  render(): React.ReactNode {

    const {
      testResultsLoaded,
      resultImage,
      testResultModalOpen,
      employee,
      errorMessages,
      resultImageLoading
    } = this.state;
    const {t} = this.props;

    const state: any = this.props.location.state;
    const registeredTestkitCode = (state && state.registeredTestkitCode) ? state.registeredTestkitCode : undefined;

    return (
        <>
          {
            resultImageLoading && <LoaderComponent message={t("employeeDashboard.testResultImage.loading")} />
          }
          <EmployeeTestResultDiv>

            {
              registeredTestkitCode &&
              <HeaderMessage visible>
                <div className="header-message-text">
                  {applyStyles(t("testKitRegistration.success.registrationSuccess",
                      {code: registeredTestkitCode}))}
                </div>
              </HeaderMessage>
            }

            <CompanyDataHeader />

            <div className="title-h1">{t("employeeOverview.title")}</div>

            {
              errorMessages.length > 0 &&
              <div className="error">
                <StyledErrorMessage onDismiss={() => this.setErrorMessage()}>
                  {errorMessages.map(err => <div key={err}>{err}</div>)}
                </StyledErrorMessage>
              </div>
            }

            {
              employee &&
              <StyledInfoRow className="target-employee" backgroundColor="#e5e5e5">
                <span>{t("employeeOverview.employee")}</span>
                <span>
              <b>{`${employee.firstName} ${employee.lastName}`}, {`${isoToDisplayDate(employee.birthDate)}`}</b>
            </span>
              </StyledInfoRow>
            }

            <div className="results-table">
              {!testResultsLoaded
                  ? <LoaderComponent message={t("testKitRegistration.loading")} />
                  : this.renderTestResultsTable()
              }
            </div>

            <div className="page-actions">
              {this.renderButtonsContent()}
            </div>

            <div className="back-btn">
              <Button
                  type="button"
                  className="action-button"
                  secondary
                  onClick={() => this.props.history.push(`/employees`)}
                  style={{display: "inline-block"}}
              >
                {t("action.back")}
              </Button>
            </div>

            {
              resultImage &&
              <Lightbox
                mainSrc={`data:${resultImage.testResultFileType};base64, ${resultImage.testResultImage}`}
                onCloseRequest={() => this.setState({resultImage: undefined})}
              />
            }
            {testResultModalOpen && this.renderTestResultModal()}
          </EmployeeTestResultDiv>
        </>
    );
  };

  renderButtonsContent = (): React.ReactNode => {

    const {
      employee,
      testResults,
      qrcodeb64,
      testRegistrationUrl,
      qrcodeVisible,
      orderTestsDisabled,
      schedulePcrTestDisabled,
      scheduleRapidTestDisabled
    } = this.state;

    const {t} = this.props;

    const b64background = `url('data:image/png;base64,${qrcodeb64}')`;

    const pendingTest = testResults.find(testResult =>
        testResult.interventionBookingStatus !== InterventionBookingStatus.COMPLETED);

    const testInterventionType = employee?.testInterventionType;

    const hasPcrTest = testInterventionType === InterventionExternalCode.PCR_SALIVA_TEST
        || testInterventionType === InterventionExternalCode.PCR_SALIVA_TEST_INDIVIDUAL_TEST;

    const hasRapidTest = testInterventionType === InterventionExternalCode.RAPID_SALIVA_TEST;

    const hasRecoveryCertificate = testInterventionType === InterventionExternalCode.COVID_CERTIFICATE_OF_RECOVERY;
    const hasVaccinationCertificate = testInterventionType === InterventionExternalCode.COVID_CERTIFICATE_OF_VACCINATION;
    const hasExternalTestDocument = testInterventionType === InterventionExternalCode.COVID_CERTIFICATE_EXTERNAL_TEST_DOCUMENT;
    const hasCertificateTest = hasRecoveryCertificate || hasVaccinationCertificate || hasExternalTestDocument;

    let disableTestKitRegistration = !!pendingTest;
    if (hasCertificateTest) {
      const recoveryCertificate = hasRecoveryCertificate && testResults.find(testResult =>
          testResult.testType === InterventionExternalCode.COVID_CERTIFICATE_OF_RECOVERY) !== undefined;

      const vaccinationCertificate = hasVaccinationCertificate && testResults.find(testResult =>
          testResult.testType === InterventionExternalCode.COVID_CERTIFICATE_OF_VACCINATION) !== undefined;

      const externalTestCertificate = hasExternalTestDocument && testResults.find(testResult =>
          testResult.testType === InterventionExternalCode.COVID_CERTIFICATE_EXTERNAL_TEST_DOCUMENT) !== undefined;

      disableTestKitRegistration = recoveryCertificate || vaccinationCertificate || externalTestCertificate;
    }

    const inContainer = this.props.companyData.container;
    const testSchedulingEnabled = this.isTestSchedulingEnabled();

    //rowData.testType === InterventionExternalCode.PCR_SALIVA_TEST && rowData.covidTestCode === null
    return (
        <ButtonContentDiv>
          {
            inContainer && !orderTestsDisabled &&
            <span>
              <BigActionButton
                icon="shipping"
                textDomain="mipoco"
                textKey="employeeDashboard.button.orderTests"
                onClick={this.onTestOrderButtonClick}
              />
            </span>
          }
          {
            testSchedulingEnabled && !schedulePcrTestDisabled &&
            <span>
              <BigActionButton
                icon="dna"
                textDomain="mipoco"
                textKey="personDashboard.action.pcrTest"
                onClick={() => this.props.history.push(
                    "/employees/overview/request-pcr-test",
                    {employeeId: employee!.id}
                )}
              />
            </span>
          }

          {
            testSchedulingEnabled && !scheduleRapidTestDisabled &&
            <span>
              <BigActionButton
                icon="flask"
                textDomain="mipoco"
                textKey="personDashboard.action.rapidTest"
                onClick={() => this.props.history.push(
                    "/employees/overview/request-rapid-test",
                    {employeeId: employee!.id}
                )}
              />
            </span>
          }

          {
            hasPcrTest &&
            <>
              <span>
                <QrcodeActionButton
                  disabled={disableTestKitRegistration}
                  poperDependencies={[qrcodeVisible]}
                  renderContent={
                    (toggle?: () => void) => <QrContentDiv>
                      <div className="employee-data">
                        {`${employee?.firstName} ${employee?.lastName}, ${isoToDisplayDate(employee?.birthDate)}`}
                      </div>
                      {
                        qrcodeVisible
                            ?
                            <a target="_blank"
                               rel="noreferrer"
                               href={testRegistrationUrl}
                               title={t("employeeDashboard.goToSnapshot")}>
                              <div className="qrcode-wrapper">
                                <div className="qrcode" style={{
                                  backgroundImage: b64background
                                }}>
                                  &nbsp;
                                </div>
                              </div>
                            </a>
                            :
                            <div>
                              <Loader active inline />
                            </div>
                      }
                      <div className="actions-row">
                        <Button compact onClick={() => {
                          if (toggle) {
                            toggle();
                          }
                          this.fetchTestResults();
                        }}
                        >
                          {t("action.back")}
                        </Button>
                      </div>
                      <div className="link-info">
                        <Icon name="info circle" />
                        {t("employeeDashboard.qrcode.linkInfo")}
                      </div>
                    </QrContentDiv>
                  }
                  onConfirm={() => {
                  }}
                  onOpen={() => {

                    this.setState({
                      qrcodeVisible: false
                    });

                    const request: TestRegistrationQrCodeRequest = {
                      employeeId: Number(employee?.id),
                      sourceEmployeeId: -1,
                      companyId: -1,
                      baseUrl: ""
                    };
                    createTestkitQrcodeBase64String(request, this.state.cancelTokenSource)
                    .then(response => {
                      this.setState({
                        qrcodeb64: response.imageB64,
                        testRegistrationUrl: response.url,
                        qrcodeVisible: true
                      });
                    });
                  }}
                  position="top center"
                  trigger={
                    <BigActionButton
                        icon="mobile alternate"
                        textDomain="mipoco"
                        textKey="employeeDashboard.button.testKitsRegistrationSmartphone"
                        disabled={disableTestKitRegistration}
                    />
                  }
                />
              </span>

              <span>
                <BigActionButton
                  icon="window maximize outline"
                  textDomain="mipoco"
                  textKey="employeeDashboard.button.testKitsRegistrationWebBrowser"
                  onClick={this.onRegisterTestKitManually}
                  disabled={disableTestKitRegistration}
                />
              </span>
            </>
          }

          {
            hasRapidTest &&
            <>
              <QrcodeActionButton
                disabled={disableTestKitRegistration}
                poperDependencies={[qrcodeVisible]}
                renderContent={
                  (toggle?: () => void) => <QrContentDiv>
                    <div className="employee-data">
                      {`${employee?.firstName} ${employee?.lastName}, ${isoToDisplayDate(employee?.birthDate)}`}
                    </div>
                    {
                      qrcodeVisible
                          ? <a target="_blank"
                               rel="noreferrer"
                               href={testRegistrationUrl}
                               title={t("employeeDashboard.goToSnapshot")}>
                            <div className="qrcode-wrapper">
                              <div className="qrcode" style={{
                                backgroundImage: b64background
                              }}>
                                &nbsp;
                              </div>
                            </div>
                          </a>
                          :
                          <div>
                            <Loader active inline />
                          </div>
                    }
                    <div className="actions-row">
                      <Button compact onClick={() => {
                        if (toggle) {
                          toggle();
                        }
                        this.fetchTestResults();
                      }}
                      >
                        {t("action.back")}
                      </Button>
                    </div>
                    <div className="link-info">
                      <Icon name="info circle" />
                      {t("employeeDashboard.qrcode.linkInfo")}
                    </div>
                  </QrContentDiv>
                }
                onConfirm={() => {
                }}
                onOpen={() => {

                  this.setState({
                    qrcodeVisible: false
                  });

                  const request: TestRegistrationQrCodeRequest = {
                    employeeId: Number(employee?.id),
                    sourceEmployeeId: -1,
                    companyId: -1,
                    baseUrl: ""
                  };
                  createRapidTestkitQrcodeBase64String(request, this.state.cancelTokenSource)
                  .then(response => {
                    this.setState({
                      qrcodeb64: response.imageB64,
                      testRegistrationUrl: response.url,
                      qrcodeVisible: true
                    });
                  });
                }}
                position="top center"
                trigger={
                  <BigActionButton
                      icon="camera"
                      textDomain="mipoco"
                      textKey="employeeDashboard.button.registerRapidTestkit"
                      disabled={disableTestKitRegistration}
                  />
                }
              />
            </>
          }

          {
            hasVaccinationCertificate &&
            <>
              <QrcodeActionButton
                disabled={disableTestKitRegistration}
                poperDependencies={[qrcodeVisible]}
                renderContent={
                  (toggle?: () => void) => <QrContentDiv>
                    <div className="employee-data">
                      {`${employee?.firstName} ${employee?.lastName}, ${isoToDisplayDate(employee?.birthDate)}`}
                    </div>
                    {
                      qrcodeVisible
                          ? <a target="_blank"
                               rel="noreferrer"
                               href={testRegistrationUrl}
                               title={t("employeeDashboard.goToSnapshot")}>
                            <div className="qrcode-wrapper">
                              <div className="qrcode" style={{
                                backgroundImage: b64background
                              }}>
                                &nbsp;
                              </div>
                            </div>
                          </a>
                          :
                          <div>
                            <Loader active inline />
                          </div>
                    }
                    <div className="actions-row">
                      <Button compact onClick={() => {
                        if (toggle) {
                          toggle();
                        }
                        this.fetchTestResults();
                      }}
                      >
                        {t("action.back")}
                      </Button>
                    </div>
                    <div className="link-info">
                      <Icon name="info circle" />
                      {t("employeeDashboard.qrcode.linkInfo")}
                    </div>
                  </QrContentDiv>
                }
                onConfirm={() => {
                }}
                onOpen={() => {

                  this.setState({
                    qrcodeVisible: false
                  });

                  const request: TestRegistrationQrCodeRequest = {
                    employeeId: Number(employee?.id),
                    sourceEmployeeId: -1,
                    companyId: -1,
                    baseUrl: ""
                  };

                  createCertificateQrcodeBase64String(
                      request, "vaccination", this.state.cancelTokenSource)
                  .then(response => {
                    this.setState({
                      qrcodeb64: response.imageB64,
                      testRegistrationUrl: response.url,
                      qrcodeVisible: true
                    });
                  });
                }}
                position="top center"
                trigger={
                  <BigActionButton
                      icon="camera"
                      textDomain="mipoco"
                      textKey="employeeDashboard.button.registerCertificateOfVaccination"
                      disabled={disableTestKitRegistration}
                  />
                }
              />
            </>
          }

          {
            hasRecoveryCertificate &&
            <>
              <QrcodeActionButton
                disabled={disableTestKitRegistration}
                poperDependencies={[qrcodeVisible]}
                renderContent={
                  (toggle?: () => void) => <QrContentDiv>
                    <div className="employee-data">
                      {`${employee?.firstName} ${employee?.lastName}, ${isoToDisplayDate(employee?.birthDate)}`}
                    </div>
                    {
                      qrcodeVisible
                          ? <a target="_blank"
                               rel="noreferrer"
                               href={testRegistrationUrl}
                               title={t("employeeDashboard.goToSnapshot")}>
                            <div className="qrcode-wrapper">
                              <div className="qrcode" style={{
                                backgroundImage: b64background
                              }}>
                                &nbsp;
                              </div>
                            </div>
                          </a>
                          :
                          <div>
                            <Loader active inline />
                          </div>
                    }
                    <div className="actions-row">
                      <Button compact onClick={() => {
                        if (toggle) {
                          toggle();
                        }
                        this.fetchTestResults();
                      }}
                      >
                        {t("action.back")}
                      </Button>
                    </div>
                    <div className="link-info">
                      <Icon name="info circle" />
                      {t("employeeDashboard.qrcode.linkInfo")}
                    </div>
                  </QrContentDiv>
                }
                onConfirm={() => {
                }}
                onOpen={() => {

                  this.setState({
                    qrcodeVisible: false
                  });

                  const request: TestRegistrationQrCodeRequest = {
                    employeeId: Number(employee?.id),
                    sourceEmployeeId: -1,
                    companyId: -1,
                    baseUrl: ""
                  };

                  createCertificateQrcodeBase64String(
                      request, "recovery", this.state.cancelTokenSource)
                  .then(response => {
                    this.setState({
                      qrcodeb64: response.imageB64,
                      testRegistrationUrl: response.url,
                      qrcodeVisible: true
                    });
                  });
                }}
                position="top center"
                trigger={
                  <BigActionButton
                      icon="camera"
                      textDomain="mipoco"
                      textKey="employeeDashboard.button.registerCertificateOfRecovery"
                      disabled={disableTestKitRegistration}
                  />
                }
              />
            </>
          }

          {
            hasExternalTestDocument &&
            <>
              <QrcodeActionButton
                disabled={disableTestKitRegistration}
                poperDependencies={[qrcodeVisible]}
                renderContent={
                  (toggle?: () => void) => <QrContentDiv>
                    <div className="employee-data">
                      {`${employee?.firstName} ${employee?.lastName}, ${isoToDisplayDate(employee?.birthDate)}`}
                    </div>
                    {
                      qrcodeVisible
                          ? <a target="_blank"
                               rel="noreferrer"
                               href={testRegistrationUrl}
                               title={t("employeeDashboard.goToSnapshot")}>
                            <div className="qrcode-wrapper">
                              <div className="qrcode" style={{
                                backgroundImage: b64background
                              }}>
                                &nbsp;
                              </div>
                            </div>
                          </a>
                          :
                          <div>
                            <Loader active inline />
                          </div>
                    }
                    <div className="actions-row">
                      <Button compact onClick={() => {
                        if (toggle) {
                          toggle();
                        }
                        this.fetchTestResults();
                      }}
                      >
                        {t("action.back")}
                      </Button>
                    </div>
                    <div className="link-info">
                      <Icon name="info circle" />
                      {t("employeeDashboard.qrcode.linkInfo")}
                    </div>
                  </QrContentDiv>
                }
                onConfirm={() => {
                }}
                onOpen={() => {

                  this.setState({
                    qrcodeVisible: false
                  });

                  const request: TestRegistrationQrCodeRequest = {
                    employeeId: Number(employee?.id),
                    sourceEmployeeId: -1,
                    companyId: -1,
                    baseUrl: ""
                  };

                  createCertificateQrcodeBase64String(
                      request, "externalTestDocument", this.state.cancelTokenSource)
                  .then(response => {
                    this.setState({
                      qrcodeb64: response.imageB64,
                      testRegistrationUrl: response.url,
                      qrcodeVisible: true
                    });
                  });
                }}
                position="top center"
                trigger={
                  <BigActionButton
                      icon="camera"
                      textDomain="mipoco"
                      textKey="employeeDashboard.button.registerCertificateExternalTestDocument"
                      disabled={disableTestKitRegistration}
                  />
                }
              />
            </>
          }
        </ButtonContentDiv>
    );
  };

  testResultsRowGetter = ({index}: any) => {
    const {testResults} = this.state;
    Object.assign(testResults[index], {index: index + 1});

    return testResults[index];
  };

  testResultsRowRenderer = ({rowData, className, columns, index, key, style}: any) => {

    const {t} = this.props;

    const a11yProps = {'aria-rowindex': index + 1};

    let rowStyle = {...style};

    return (
        <div
            {...a11yProps}
            className={className}
            key={key}
            role="row"
            style={rowStyle}
            onDoubleClick={() => {
              if (rowData.interventionBookingStatus === InterventionBookingStatus.PLANNED) {
                this.showTestResultModal(index);
              }
            }}
        >
          {columns.map((column: any) => {
            let col = {...column};

            if (column.props["aria-colindex"] === 4 && column.props.children === ""
                && rowData.interventionBookingStatus !== InterventionBookingStatus.COMPLETED) {

              let statusText = `${t("employeeDashboard.table.status")}: `;
              let messageKey = t(`enum.interventionBookingStatus.${rowData.interventionBookingStatus}`);

              if (rowData.interventionBookingStatus === InterventionBookingStatus.PLANNED) {
                messageKey = "";
                statusText = "";
              }

              col = {
                ...col,
                props: {
                  ...col.props,
                  children: `${statusText} ${messageKey}`
                }
              };

            }

            return col;
          })}
        </div>
    );
  };

  dateCellRenderer = ({cellData}: any) => {
    if (cellData) {
      return moment(cellData).format('DD.MM.YYYY / HH:mm');
    }
    return "";
  };

  covidTestRegistrationTimestampCellRenderer = ({rowData, cellData}: any) => {
    let appointmentDateTime = "";
    if (rowData.interventionBookingStatus === InterventionBookingStatus.PLANNED) {
      if (rowData.appointmentDate) {
        appointmentDateTime = moment(rowData.appointmentDate).format("DD.MM.YYYY");
      }
      if (rowData.appointmentTime) {
        appointmentDateTime = `${appointmentDateTime} / ${rowData.appointmentTime.substr(0, 5)}`;
      }

      return <Fragment>
        <Icon name={'calendar alternate'} />
        {appointmentDateTime}
      </Fragment>;
    }

    if (cellData) {
      return moment(cellData).format('DD.MM.YYYY / HH:mm');
    }

    return "";
  };


  testTypeCellRenderer = ({cellData}: any) => {
    const {language} = this.props;
    const {interventions} = this.state;

    if (cellData && cellData.length > 0) {
      const intervention = interventions.find(i => i.externalCode === cellData);

      return interventionDescription(intervention, language);
    }
  };

  testDescription = (testResult: EmployeeTestResultDto): string => {
    if (!testResult) {
      return "";
    }

    const texts: { [key: string]: string } = {
      "de": testResult.testDescription,
      "en": testResult.testDescriptionEn,
      "fr": testResult.testDescriptionFr,
      "it": testResult.testDescriptionIt
    };

    return texts[this.props.language] || testResult.testDescription;
  };

  testRegistrationCodeCellRenderer = ({rowData, cellData}: any) => {
    if (rowData.interventionBookingStatus === InterventionBookingStatus.PLANNED && rowData.testLocationId) {
      return this.getLocationDescription(rowData.testLocationId);
    }
    if (cellData && cellData.length > 0) {
      return cellData;
    }
    return "";
  };

  getLocationDescription = (locationId?: number): string => {
    const {resourceMap} = this.state;
    let locationDesc = "";
    if (Object.keys(resourceMap).length && locationId) {
      locationDesc = resourceMap[locationId]
          ? resourceMap[locationId].descriptionWithLocation
          : "";
    }

    return locationDesc;
  };

  testResultImageRenderer = (testResultImageId: string) => {

    const {t} = this.props;
    return (
        <EditActionButton
            hoverMessage={t("employeeDashboard.button.testResultImage")}
            trigger={<Icon name={'camera'} style={{verticalAlign: "middle"}} />}
            onConfirm={() => {

              this.setState({
                resultImageLoading: true
              });

              getTestResultImage(testResultImageId, this.state.cancelTokenSource)
              .then(response => {
                this.setState({
                  resultImage: response,
                  resultImageLoading: false
                });
              })
              .catch((e) => {
                this.setState({
                  resultImageLoading: false
                });
                this.handleError(e.response.data);
              });
            }}
        />
        // <TestResultImageButton
        //     poperDependencies={[testResultImageId]}
        //     hoverMessage={t("employeeDashboard.button.testResultImage")}
        //     renderContent={
        //       (toggle?: () => void) => <div>
        //         {resultImageLoading
        //             ? <Loader active inline="centered" />
        //             : resultImage && <Lightbox
        //           mainSrc={`data:${resultImage.testResultFileType};base64, ${resultImage.testResultImage}`}
        //           onCloseRequest={() => noop()}
        //         />
        //           // <img height="200px"
        //           //                         src={`data:${resultImage.testResultFileType};base64, ${resultImage.testResultImage}`}
        //           //                         alt={t("employeeDashboard.button.testResultImage")} />
        //         }
        //         <div className="actions-row">
        //           <Button compact
        //                   style={{borderRadius: "unset", marginTop: "1rem"}}
        //                   onClick={() => {
        //                     if (toggle) {
        //                       toggle();
        //                     }
        //                   }}
        //           >
        //             {t("action.back")}
        //           </Button>
        //         </div>
        //       </div>
        //     }
        //     onConfirm={() => {
        //     }}
        //     onOpen={() => {
        //
        //       this.setState({
        //         resultImageLoading: true
        //       });
        //
        //       getTestResultImage(testResultImageId, this.state.cancelTokenSource)
        //       .then(response => {
        //         this.setState({
        //           resultImage: response,
        //           resultImageLoading: false
        //         });
        //       });
        //     }}
        //     trigger={<Icon name={'camera'} style={{verticalAlign: "middle"}} />}
        // />
    );
  };

  testResultsCellRenderer = ({rowData, cellData, rowIndex}: any) => {
    const {t} = this.props;

    if (rowData.interventionBookingStatus === InterventionBookingStatus.PLANNED) {
      return <>
        <StyledButton icon
                      type="button"
                      onClick={() => this.showTestResultModal(rowIndex)}
        >
          <Popup
              trigger={<Icon color="blue" name="info circle" />}
              content={t('personDashboard.appointment.seeDetails')}
              size="small"
              position="top center"
              style={PopupStyle}
              inverted
          />
        </StyledButton>
        <DeleteActionButton
            hoverMessage={t("personDashboard.appointment.deleteAppointment")}
            popupMessage={t("personDashboard.appointment.confirmDelete")}
            onConfirm={() => this.deleteAppointment(rowData.interventionBookingId)}
        />
      </>;
    }

    if (cellData) {
      return <>
        <TestResultIcon result={cellData} />
        {t(`testResult.${cellData}`)}
        {
          rowData.testResultImageId &&
          this.testResultImageRenderer(rowData.testResultImageId)
        }
      </>;
    }

    return <></>;
  };


  renderTestResultsTable = (): JSX.Element => {

    const {testResults} = this.state;
    const {t} = this.props;

    return (
        <VirtualizedTable
            rowCount={testResults.length}
            rowGetter={this.testResultsRowGetter}
            rowRenderer={this.testResultsRowRenderer}
            columns={[
              {
                width: 150,
                label: (t("employeeDashboard.tableHeader.registrationDate")),
                dataKey: "covidTestRegistrationTimestamp",
                flexGrow: 1,
                cellRenderer: this.covidTestRegistrationTimestampCellRenderer
              },
              {
                width: 200,
                label: (t("employeeDashboard.tableHeader.test")),
                dataKey: "testDescription",
                flexGrow: 1,
                cellRenderer: this.testTypeCellRenderer
              },
              {
                width: 200,
                label: (t("employeeDashboard.tableHeader.registration")),
                dataKey: "testKitCode",
                flexGrow: 1,
                cellRenderer: this.testRegistrationCodeCellRenderer
              },
              {
                width: 150,
                label: (t("employeeDashboard.tableHeader.resultDate")),
                dataKey: "covidTestResultTimestamp",
                flexGrow: 1,
                cellRenderer: this.dateCellRenderer
              },
              {
                width: 200,
                label: (t("employeeDashboard.tableHeader.finding")),
                dataKey: "covidTestResult",
                flexGrow: 1,
                cellRenderer: this.testResultsCellRenderer
              }
            ]}
        />

    );
  };

  renderTestResultModal = () => {
    const {t} = this.props;
    const {testResults, testResultIx, testResultModalOpen} = this.state;

    const testResult = testResults.find((tr, ix) => ix === testResultIx);

    if (testResult && InterventionBookingStatus.PLANNED === testResult.interventionBookingStatus) {
      return (
          <Modal
              open={testResultModalOpen}
              onClose={this.hideTestResultModal}
              style={{width: "32rem", borderRadius: "unset"}}
              closeOnDimmerClick={false}
          >
            <Modal.Header>{this.testDescription(testResult)}</Modal.Header>
            <Modal.Content>
              <TestInfo testResult={testResult}
                        locationDescription={this.getLocationDescription(testResult.testLocationId)}
                        translationKey="mipoco"
              />
            </Modal.Content>
            <Modal.Actions>
              <Button compact type="button"
                      onClick={this.hideTestResultModal}>{t("button.close")}</Button>
            </Modal.Actions>
          </Modal>
      );
    }

    return null;
  };


}

export default withRouterWorkaround(
    withAuthContext(
        withCompanyDataContext(
            withTranslation(["mipoco"])(EmployeeOverview))));
