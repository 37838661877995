import {AuthConsumerRenderProps, withAuthContext} from "auth/AuthContext";
import {CompanyDataConsumerRenderProps, withCompanyDataContext} from "component/CompanyDataContext";
import CompanyDataHeader from "component/CompanyDataHeader";
import Input from "component/final-form/Input";
import RadioGroup from "component/final-form/RadioGroup";
import Select, {
  DropdownOption,
  mapToDropdownOptionArray,
  mapToDropdownOptionArrayWithTextGetter
} from "component/final-form/Select";
import LoaderComponent from "component/LoaderComponent";
import MpGrid from "component/MpGrid";
import StyledErrorMessage from "component/StyledErrorMessage";
import {FormApi, FormState} from "final-form";
import createDecorator from "final-form-calculate";
import _ from 'lodash';
import moment from "moment";
import React, {Component, Fragment} from "react";
import {Field, Form as FinalForm, FormRenderProps, FormSpy} from 'react-final-form';
import {withTranslation, WithTranslation} from "react-i18next";
import {RouteComponentProps} from "react-router";
import QuestionSetView from "route/person-selfonboarded/QuestionSetView";
import {DataEntry, DataEntryValue, Label} from "route/person-selfonboarded/styled";
import {Button, Grid, Icon, List, ListItem, Popup, RadioProps} from "semantic-ui-react";
import axios from "service/http";
import {getInterventionPrice} from "service/interventionPriceServices";
import {getInterventionForExteranlCode} from "service/interventionServices";
import {fetchAllProducts} from "service/productServices";
import {
  getQuestionSetForExternalCode,
  getTestResources,
  scheduleTestAppointment,
  searchTestAppointment
} from "service/testServices";
import styled from "styled-components";
import {debounce} from "ts-debounce";
import {InterventionExternalCode, InterventionSlotStatus} from "ts-types/api.enums";
import {
  InterventionAvailabilitySlotSearchDto,
  InterventionDto,
  ProductDto,
  QuestionDto,
  QuestionSetDto,
  TestAppointmentRequest,
  VaccinationBookingDto
} from "ts-types/api.types";
import {isoToDisplayTime, isoToLongDisplayDate, toIsoDateString} from "util/dateUtils";
import {errorUtils} from "util/errorUtils";
import {noop} from "util/functionUtils";
import {interventionDescription, interventionInfoText} from "util/interventionUtils";
import {questionText} from "util/questionSetUtils";
import {required} from "util/validatorUtils";
import {withRouterWorkaround} from "util/workaroundUtils";

const UpsertContainer = styled.div`

  .section-title {
    color: #7687FF;
    font-weight: bold;
    font-size: 1.5rem;
    margin: 0;
    padding-right: 0.5rem;
    display: inline-block;
  }

  .section-info {
    margin: 1rem 0 0;
  }

  .success-message {
    color: darkgreen;
    display: inline-block;
    margin-right: 1rem;

    @media only screen and (max-width: 767px) {
      display: block;
      margin-bottom: 0.75rem;
    }
  }

  .form-grid {
    label {
      display: inline-block;
      margin-bottom: 0.5rem;
      color: #454545;
    }
  }

  .ui.grid.form-grid {
    margin-left: -1rem;
    max-width: 840px;

    & > .row {
      margin-bottom: 0.578rem;
    }

    .input-field {
      max-width: 27rem;
    }

    .field > .ui.input.hidden-field {
      display: none;
    }
  }

  .form-section-start {
    margin-top: 1rem;
  }

  .anamnesis-section {
    font-size: 1.1rem;
  }

  .data-entry {
    flex: 1 1 auto;
    display: grid;
    grid-template-columns: min-content 100%;
    justify-content: start;
    align-content: start;
    row-gap: 0.75rem;

    .label {
      min-width: 150px;
      font-weight: bold;
    }

    .value {
      max-width: 25rem;
    }

    .radio-row {
      grid-column-end: span 2;
      justify-self: start;
      margin-top: 0.5rem;
      margin-bottom: 0.3rem;
    }

    .row-label {
      margin: 1.5rem 0 1rem;
      grid-column-end: span 2;
      justify-self: start;
    }

    .search-button-row {
      margin-bottom: 0.35rem;
      grid-column-end: span 2;
    }

    .next-available-day-info {
      margin-bottom: 0.5rem;
      grid-column-start: 2;
      justify-self: start;
      font-style: italic;

      .next-date {
        display: inline-block;
        font-weight: bold;
        padding-left: 0.5rem;
      }
    }

    .available-slots-row {
      margin: 0.25rem 0 1rem;
      grid-column-start: 2;
      justify-self: start;

      .time-slot {
        display: inline-block;
        margin-right: 0.5rem;
        padding: 2px 4px;
        border-radius: 4px;
        border: 1px solid #e5e5e5;
        margin-bottom: 0.5rem;
        min-width: 3.875rem;
        text-align: center;
        cursor: pointer;
      }

      .time-slot.selected {
        background: #2185d0;
        color: white;
      }

      .time-slot.BOOKED {
        background: #f1c6dc;
        border: 1px solid #d8a1bd;
        cursor: default;
      }

      .time-slot:not(.BOOKED):HOVER {
        background: #c4dcef;
      }
    }
  }
`;

const PopupStyle = {
  borderRadius: 'unset',
  opacity: 0.7,
  padding: '7px'
};

const cancelTokenSource = axios.CancelToken.source();

interface Props extends RouteComponentProps<any>,
    AuthConsumerRenderProps,
    WithTranslation,
    CompanyDataConsumerRenderProps {

  testExternalCode?: InterventionExternalCode,
  onSave: () => void,
  onCancel: () => void,
  companyAdmin?: boolean
}

interface State {
  employeeId?: number;
  testExternalCode: InterventionExternalCode;
  intervention?: InterventionDto;
  resources: DropdownOption[];
  resourceId?: number;
  questionSet?: QuestionSetDto;
  currentQuestion: number;

  products: ProductDto[];

  interventionPriceInsurance?: number;
  interventionPricePatient?: number;

  availableSlotsSearchErrorMessage?: string;
  availabilities: InterventionAvailabilitySlotSearchDto[];
  availabilityOptions: DropdownOption[];

  formDataLoaded: boolean;
  pageActionInProgress: boolean;
  successMessage?: string;
  errorMessages: Array<string>;
}

const generateRadioButtonOptions = (
    ids: Array<string>,
    labels: Array<string>,
    groupName: string,
    getMessage: Function,
    values?: Array<string | number>): Array<RadioProps> => {

  return labels.map((label, index) => ({
        id: ids[index],
        label: getMessage(`${label}`),
        name: groupName,
        value: values ? values[index] : index
      }
  ));
};

const convertValueToList = (value: string) => value ? [value] : [];
const extractValueFromList = (value: Array<string>) => value && value.length === 1 ? value[0] : "";

class RequestPcrTest extends Component<Props, State> {

  initialBookingValues: Partial<VaccinationBookingDto> = {
    questionAnswerPairs: []
  };

  formDecorators = [
    createDecorator(
        {
          field: "locationId",
          updates:
              {
                "availabilityId": (locationId, allValues) => undefined,
                "productId": (locationId, allValues) => undefined
              }
        }
    )
  ];

  constructor(props: Props) {
    super(props);

    let employeeId: number | undefined = undefined;
    if (props.companyAdmin) {
      const state: any = this.props.location.state;
      const employeeIdString = state?.employeeId;
      employeeId = employeeIdString ? Number(employeeIdString) : undefined;
    }

    const testExternalCode = props.testExternalCode || InterventionExternalCode.PCR_SALIVA_TEST;

    this.state = {
      employeeId,
      testExternalCode,
      resources: [],
      products: [],
      currentQuestion: 0,

      availabilities: [],
      availabilityOptions: [],

      formDataLoaded: false,
      pageActionInProgress: false,
      errorMessages: []
    };

    this.fetchPageData(testExternalCode);
  }

  fetchPageData = async (testExternalCode: InterventionExternalCode): Promise<void> => {

    try {
      let resources = await getTestResources(testExternalCode, cancelTokenSource);
      resources = resources.filter(res => res.active);

      const intervention = await getInterventionForExteranlCode(testExternalCode, cancelTokenSource);

      const questionSet: QuestionSetDto = await getQuestionSetForExternalCode(
          this.state.testExternalCode, cancelTokenSource);

      let products = await fetchAllProducts(cancelTokenSource);
      products = products.filter(product => product.active && !product.systemProduct);

      if (resources && resources.length > 0) {
        this.searchForAvailableSlots(resources[0].id);
      }

      this.setState({
        intervention,
        resources: mapToDropdownOptionArray(resources, "descriptionWithLocation"),
        products,
        questionSet
      });

      const qaPairs = questionSet.questions.map(question => ({
        questionId: question.id,
        questionText: question.text,
        questionTextEn: question.textEn || question.text,
        questionTextFr: question.textFr || question.text,
        questionTextIt: question.textIt || question.text,
        selectedAnswers: []
      }));
      this.initialBookingValues.questionAnswerPairs = qaPairs;
      this.initialBookingValues.locationId = resources && resources.length > 0 ? resources[0].id : undefined;

    } catch (e) {
      this.handleError(e.response.data);
    } finally {
      this.setState({
        formDataLoaded: true
      });
    }

  };

  goToEmployeeOverview = () => {
    const {companyAdmin} = this.props;
    const {employeeId} = this.state;

    if (companyAdmin) {
      this.props.history.push("/employees/overview", {employeeId: employeeId});
    } else {
      this.props.history.push("/");
    }
  };

  searchForAvailableSlots = async (resourceId: number) => {

    this.setState({
      availableSlotsSearchErrorMessage: undefined
    });

    let availabilitiesResponse: InterventionAvailabilitySlotSearchDto[] = [];

    if (resourceId !== undefined) {
      try {
        availabilitiesResponse = await searchTestAppointment(
            resourceId, this.state.testExternalCode, cancelTokenSource);
      } catch (e) {
        this.setState({
          availableSlotsSearchErrorMessage: "error"
        });
        return;
      }
    }

    this.setState({
      availabilities: availabilitiesResponse,
      availabilityOptions: []
    });

    if (availabilitiesResponse && availabilitiesResponse.length > 0) {

      const availabilityDescriptionGenerator = (avail: InterventionAvailabilitySlotSearchDto) => {
        return `${isoToLongDisplayDate(avail.date)}`
            + ` - ${isoToDisplayTime(avail.fromTime)} - ${isoToDisplayTime(avail.toTime)}`;
      };

      const availabilityOptions = mapToDropdownOptionArrayWithTextGetter(
          availabilitiesResponse, availabilityDescriptionGenerator);

      this.setState({
        availabilityOptions
      });

      // this.initialBookingValues.availabilityId = availabilitiesResponse[0].id;
      // this.initialAppointmentValues.secondDoseAvailabilityId = availabilitiesResponse[0].secondDoseAvailabilityId;
    }
  };

  setErrorMessage = (errorMessage?: string) => {

    const {errorMessages} = this.state;

    if (errorMessage) {

      const errMsgs = [...errorMessages];
      errMsgs.push(errorMessage);

      this.setState({
        errorMessages: errMsgs
      });
    } else {

      this.setState({
        errorMessages: []
      });
    }
  };

  handleError(error: any) {
    const {t} = this.props;
    const errorCode = error.errorCode;
    const knownErrors: Array<string> = [
      errorUtils.invalidInput,
      errorUtils.questionAnswersAreMandatory,
      errorUtils.inconsistentLocation,
      errorUtils.productNotAvailableAtLocation
    ];

    const violations: Array<any> = error.violations;

    if (violations && violations.length > 0) {
      violations.forEach(violation => {
        if (knownErrors.includes(violation.errorCode)) {
          this.setErrorMessage(t(`error.${violation.errorCode}`));
        }
      });
    }

    if (!this.state.errorMessages.length) {
      if (knownErrors.includes(errorCode)) {
        this.setErrorMessage(t(`error.${errorCode}`));
      } else {
        this.setErrorMessage(t('error.general'));
      }
    }
  };

  handleChange = debounce((formState: FormState<any>) => {
    const {values} = formState;
    const {resourceId} = this.state;

    if (resourceId !== values.locationId) {
      this.setState({
        resourceId: values.locationId
      });
      this.searchForAvailableSlots(values.locationId);
    }

    const productId = values.productId;
    if (productId) {
      this.setState({
        interventionPriceInsurance: undefined,
        interventionPricePatient: undefined
      });
      getInterventionPrice(productId, toIsoDateString(moment()), cancelTokenSource)
      .then(result => {
        const privateTariffInvoices = result.invoices.filter(inv => inv.privateTariff);
        if (privateTariffInvoices) {
          const totalAmountPrivateTariff = privateTariffInvoices.reduce(
              (total, invoice) => total + invoice.amount, 0);

          this.setState({interventionPricePatient: totalAmountPrivateTariff});
        }

        const insuranceInvoices = result.invoices.filter(inv => !inv.privateTariff);
        if (insuranceInvoices) {
          const totalAmountInsurance = insuranceInvoices.reduce(
              (total, invoice) => total + invoice.amount, 0);

          this.setState({interventionPriceInsurance: totalAmountInsurance});
        }
      })
      .catch(noop);
    }

  }, 1);

  handleSubmit = async (values: Partial<TestAppointmentRequest>) => {

    this.setErrorMessage();
    this.setPageActionInProgress();

    const {employeeId, testExternalCode} = this.state;

    const request: Partial<TestAppointmentRequest> = {
      ...values,
      externalCode: testExternalCode
    };

    try {
      await scheduleTestAppointment(employeeId, request, cancelTokenSource);

      this.setState({
        successMessage: "employee.saveSuccess"
      });

      setTimeout(() => {
        this.goToEmployeeOverview();
      }, 1200);

    } catch (e) {
      this.clearPageActionInProgress();
      this.handleError(e.response.data);
    }
  };

  setPageActionInProgress = () => {
    this.setState({
      pageActionInProgress: true
    });
  };

  clearPageActionInProgress = () => {
    this.setState({
      pageActionInProgress: false
    });
  };

  render() {

    const {formDataLoaded} = this.state;
    const {t} = this.props;

    return (
        <UpsertContainer>
          <CompanyDataHeader />

          {formDataLoaded
              ? <React.Fragment>
                {this.renderFinalForm()}
              </React.Fragment>
              : <LoaderComponent message={t("employee.loadFormData")} />
          }
        </UpsertContainer>
    );
  }

  renderFinalForm(): React.ReactNode {

    return (
        <FinalForm
            onSubmit={(values) => this.handleSubmit(values)}
            decorators={this.formDecorators}
            initialValues={this.initialBookingValues}
            subscription={{pristine: true, values: true, submitting: true}}
            render={this.renderTestFormContent}
        />
    );
  }

  renderTestFormContent = (
      {form, values, handleSubmit}: FormRenderProps<Partial<TestAppointmentRequest>>): React.ReactNode => {

    const {t, language} = this.props;
    const {
      pageActionInProgress,
      resources,
      products,
      interventionPricePatient,
      interventionPriceInsurance,
      intervention,
      availabilityOptions,
      formDataLoaded,
      errorMessages
    } = this.state;

    const intervantionDescription = interventionDescription(intervention, language);
    const interventionInfoTextString = interventionInfoText(intervention, language);

    let availableProducts: ProductDto[] = [];
    if (values.locationId) {
      availableProducts = products.filter(product => {
        return product.externalCode === this.state.testExternalCode
            && (!product.resourceIds
                || product.resourceIds.length === 0
                || product.resourceIds.includes(`${values.locationId}`));
      });
    }
    const productOptions = mapToDropdownOptionArrayWithTextGetter(
        availableProducts, (item) => {
          const description: { [key: string]: string } = {
            "de": item.description,
            "en": item.descriptionEn,
            "fr": item.descriptionFr,
            "it": item.descriptionIt
          };
          return description[language] || item.description;
        });

    let comment = undefined;
    const productId = values.productId;
    if (productId) {
      const selectedProduct = products.find(product => product.id === productId);
      if (selectedProduct) {
        const comments: { [key: string]: string } = {
          "de": selectedProduct.comments,
          "en": selectedProduct.commentsEn || selectedProduct.comments,
          "fr": selectedProduct.commentsFr || selectedProduct.comments,
          "it": selectedProduct.commentsIt || selectedProduct.comments
        };
        comment = comments[language];
      }
    }

    return (
        <form onSubmit={handleSubmit}>
          <MpGrid stackable className="form-grid">
            <Grid.Row>
              <Grid.Column width={16}>
                <div className="section-title" title={interventionInfoTextString}>
                  {t("pcrTestAppointmentBooking.title", {testType: intervantionDescription})}
                </div>
                <Popup
                    trigger={<Icon name={"info circle"} color={"blue"} title={interventionInfoTextString} />}
                    content={interventionInfoTextString}
                    size="small"
                    position="right center"
                    style={PopupStyle}
                    inverted
                    on={"click"}
                />
                <div className="section-info">{t("pcrTestAppointmentBooking.info")}</div>
              </Grid.Column>
            </Grid.Row>
            {errorMessages.length > 0 &&
              <Grid.Row>
                <Grid.Column width={16}>
                  <StyledErrorMessage onDismiss={() => this.setErrorMessage()}>
                    {errorMessages.map(err => <div key={err}>{err}</div>)}
                  </StyledErrorMessage>
                </Grid.Column>
              </Grid.Row>
            }

            <Grid.Row className="form-section-start">
              <Grid.Column width={4}>
                <label htmlFor={"locationId"}>{t("pcrTestAppointmentBooking.location")}:</label>
              </Grid.Column>
              <Grid.Column width={12}>
                <Field
                    id="locationId"
                    name="locationId"
                    className="input-field"
                    component={Select}
                    fluid
                    options={resources}
                    validate={required}
                    clearable
                />
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column width={4}>
                <label>{t("pcrTestAppointmentBooking.anamnesis.questions")}:</label>
              </Grid.Column>
              <Grid.Column width={12}>
                <DataEntry>
                  {this.renderQuestions(form)}
                </DataEntry>
              </Grid.Column>
            </Grid.Row>

            {
                this.state.questionSet &&
              <Grid.Row>
                <Grid.Column width={4}>
                  <label>{t("pcrTestAppointmentBooking.anamnesis.answers")}:</label>
                </Grid.Column>
                <Grid.Column width={12}>
                  <QuestionSetView
                    questionSetName={this.state.questionSet.name}
                    questionAnswerPairs={form.getState().values.questionAnswerPairs!} />
                </Grid.Column>
              </Grid.Row>
            }

            <Grid.Row className="form-section-start">
              <Grid.Column width={4}>
                <label htmlFor={"locationId"}>{t("pcrTestAppointmentBooking.productId")}:</label>
              </Grid.Column>
              <Grid.Column width={12}>
                <Field
                    id="productId"
                    name="productId"
                    className="input-field"
                    component={Select}
                    fluid
                    options={productOptions}
                    validate={required}
                    clearable
                />
              </Grid.Column>
            </Grid.Row>

            {
                comment &&
              <Grid.Row className="">
                <Grid.Column width={4}>
                </Grid.Column>
                <Grid.Column width={12}>
                  {comment}
                </Grid.Column>
              </Grid.Row>
            }

            {
                (interventionPriceInsurance !== undefined || interventionPricePatient !== undefined) &&
              <Grid.Row className="">
                <Grid.Column width={4}>
                  {
                      interventionPriceInsurance !== undefined &&
                    <label htmlFor={"locationId"}>{t("pcrTestAppointmentBooking.amountInsurance")}:</label>
                  }
                </Grid.Column>
                <Grid.Column width={3}>
                  {
                      interventionPriceInsurance !== undefined &&
                    <span>
                      {t("pcrTestAppointmentBooking.costsCovered")}
                    </span>
                  }
                </Grid.Column>
                {
                    interventionPricePatient !== undefined &&
                  <>
                    <Grid.Column width={3}>
                      <label htmlFor={"locationId"}>{t("pcrTestAppointmentBooking.amountPatient")}:</label>
                    </Grid.Column>
                    <Grid.Column width={2}>
                      {interventionPricePatient}&nbsp;CHF
                    </Grid.Column></>
                }
              </Grid.Row>
            }

            <Grid.Row>
              <Grid.Column width={4} verticalAlign="middle">
                <label htmlFor="availabilityId">{t("pcrTestAppointmentBooking.availability")}:</label>
              </Grid.Column>
              <Grid.Column width={12}>
                <Field
                    fluid
                    name="availabilityId"
                    className="input-field"
                    component={Select}
                    options={availabilityOptions}
                    validate={required}
                />
                {<Field
                    className="hidden-field"
                    fluid
                    name="interventionSlotId"
                    component={Input}
                    validate={required}
                />}
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column width={16}>
                <DataEntry className="data-entry">
                  <div className="available-slots-row">
                    {this.renderAvailableSlots(form, values)}
                  </div>
                </DataEntry>
                {
                  availabilityOptions.length === 0 && formDataLoaded
                      ? <div className="no-available-appointments">
                        {t("pcrTestAppointmentBooking.noAvailableAppointments.message")}
                      </div>
                      : <></>
                }
              </Grid.Column>
            </Grid.Row>

            <Grid.Row textAlign="right">
              <Grid.Column width={16}>
                {
                    this.state.successMessage &&
                  <div className="success-message">
                    {t(this.state.successMessage)}
                  </div>
                }
                <Button
                    type="submit"
                    className="action-button"
                    primary
                    style={{display: "inline-block", marginRight: "1.5rem"}}
                    disabled={pageActionInProgress}
                >
                  {t("employee.save")}
                </Button>
                <Button
                    type="button"
                    className="action-button"
                    onClick={() => this.goToEmployeeOverview()}
                    secondary
                    style={{display: "inline-block"}}
                >
                  {t("action.cancel")}
                </Button>
              </Grid.Column>
            </Grid.Row>
          </MpGrid>

          <FormSpy subscription={{values: true}} onChange={formState => this.handleChange(formState)} />
        </form>
    );
  };

  previousQuestion = () => {
    const {currentQuestion} = this.state;

    this.setState({
      currentQuestion: Math.max(currentQuestion - 1, 0)
    });
  };

  nextQuestion = () => {
    const {questionSet, currentQuestion} = this.state;

    this.setState({
      currentQuestion: Math.min(currentQuestion + 1, questionSet!.questions.length - 1)
    });
  };

  setQuestionValue = (fieldPath: string, value: boolean, form: FormApi) => {
    form.change(fieldPath, [value]);
    this.nextQuestion();
  };

  renderQuestions = (form: FormApi<Partial<VaccinationBookingDto>>): JSX.Element => {
    const {t} = this.props;
    const {questionSet, currentQuestion} = this.state;

    if (!questionSet) {
      return <></>;
    }

    const questionMap = _.keyBy(questionSet.questions, q => q.id);

    const currentQuestionIx = currentQuestion < questionSet.questions.length ? currentQuestion : 0;

    const values = form.getState().values;
    const qaPairs = values.questionAnswerPairs!;

    return (
        <>
          <div className="label" />
          <div className="question-num-label">
            {t("pcrTestAppointmentBooking.anamnesis.questionNum")}
            <span className="no-break">({`${currentQuestionIx + 1}/${questionSet.questions.length}`})</span>
          </div>

          <div className="question-container">
            <Button
                className="button-back"
                type="button"
                icon size="mini"
                disabled={currentQuestionIx <= 0}
                onClick={this.previousQuestion}
            >
              <Icon name="chevron left" />
            </Button>

            <div className="question">
              {
                qaPairs.map((qaPair, index: number) => {
                  const question: QuestionDto = questionMap[qaPair.questionId];

                  if (!question) {
                    return <div key={`question-wrapper-unknown-q-${qaPair.questionId}`} />;
                  }

                  const wrapperClass = index !== currentQuestionIx ? "inactive" : "";
                  return <div key={`question-wrapper-${question!.id}`} className={`question-wrapper ${wrapperClass}`}>
                    {this.renderSingleQuestion(`questionAnswerPairs[${index}]`, index, question, form)}
                  </div>;
                })
              }
            </div>

            <Button className="button-next"
                    type="button"
                    icon
                    size="mini"
                    disabled={currentQuestionIx >= questionSet.questions.length - 1}
                    onClick={this.nextQuestion}
            >
              <Icon name="chevron right" />
            </Button>
          </div>
        </>
    );
  };

  renderSingleQuestion = (questionAnswerFieldPath: string, index: number, question: QuestionDto, form: FormApi) => {
    return (
        <Fragment key={`question-fragment-${index}`}>
          <Label className="question-text" key={`question_label_${question.questionKey}`}>
            {questionText(question, this.props.language)}
          </Label>
          <DataEntryValue className="answer-value" key={`question_value_${question.questionKey}`}>
            {this.renderAnswerByType(`${questionAnswerFieldPath}.selectedAnswers`, index, question, form)}
          </DataEntryValue>
        </Fragment>
    );
  };

  renderAnswerByType = (fieldPath: string, index: number, question: QuestionDto, form: FormApi) => {
    const {t} = this.props;
    const {questionType} = question;
    switch (questionType) {
      case 'MULTI_ANSWER':
        const answers = question.possibleAnswers.map((answer, index) => (
                <ListItem key={`${fieldPath}_${index}`}>
                  <Field
                      name={fieldPath}
                      component="input"
                      type="checkbox"
                      value={answer}
                  />
                  {' ' + t(answer)}
                </ListItem>
            )
        );
        return (
            <List>
              {answers}
            </List>
        );
      case 'SINGLE_ANSWER':
        return (
            <Field
                name={fieldPath}
                component={RadioGroup}
                parse={convertValueToList}
                format={extractValueFromList}
                radiowidth="100%"
                radioDefinitions={
                  generateRadioButtonOptions(
                      question.possibleAnswers,
                      question.possibleAnswers,
                      fieldPath,
                      t,
                      question.possibleAnswers
                  )
                }
            />
        );
      case 'FREE_TEXT':
        return (
            <Field
                name={fieldPath}
                component={Input}
                fluid
                placeholder={t('insert.answer')}
                parse={convertValueToList}
                format={extractValueFromList}
            />
        );
      case 'YES_NO':
      default:
        const qa = _.get(form.getState().values, fieldPath);
        return (
            <>
              <Button
                  className="yesno-button"
                  type="button"
                  size="mini"
                  primary={this.answerEquals(qa, true)}
                  onClick={() => this.setQuestionValue(fieldPath, true, form)}
              >
                {t("anamnesis.answer.yes")}
              </Button>

              <Button
                  className="yesno-button"
                  type="button"
                  size="mini"
                  primary={this.answerEquals(qa, false)}
                  onClick={() => this.setQuestionValue(fieldPath, false, form)}
              >
                {t("anamnesis.answer.no")}
              </Button>
            </>
        );
    }
  };

  answerEquals = (answers: string[], value: any): boolean => {
    return answers && answers.length === 1 && (answers[0] === value || answers[0] === "" + value);
  };

  mapFieldRows = (fields: Array<any>, messageKey?: string) => {

    return fields.map((fieldProps, index) => {
      return this.renderFieldRow(fieldProps, index, messageKey);
    });
  };

  renderFieldRow = (
      fieldProps: any,
      index: number,
      messageKey: string | undefined) => {

    const {t} = this.props;

    const labelKey = messageKey ? `${messageKey}.${fieldProps.name}` : fieldProps.name;

    const className = fieldProps.className;
    const labelClassName = className ? `${className}-label` : "";
    const valueClassName = className ? `${className}-value` : "";

    return (
        <Fragment key={`treatment_input_row_${index}`}>
          <Label className={`${labelClassName} label`} key={`treatment_label_${index}`}>{t(labelKey)}:</Label>
          <DataEntryValue key={`treatment_value_${index}`} className={`${valueClassName}`}>
            <Field fluid {...fieldProps} />
          </DataEntryValue>
        </Fragment>
    );
  };

  renderAvailableSlots = (form: FormApi, values: any): JSX.Element => {

    let slots: any = [];
    if (values.availabilityId) {
      let currentHour = 0;
      let currentMin = 0;

      const selectedAvailability = this.state.availabilities.find(avail => avail.id === values.availabilityId);

      const displayedSlots = selectedAvailability!.slots.filter(
          slot => InterventionSlotStatus.BLOCKED !== slot.status);

      slots = displayedSlots.map((slotDto, ix) => {

            let newRow = false;

            const slot = moment(slotDto.fromTime, "HH:mm");
            const slotHour = slot.hour();
            if (slotHour !== currentHour) {
              newRow = true;
              currentHour = slotHour;
            }

            const slotMin = slot.minute();
            if (currentMin !== Math.floor(slotMin / 60)) {
              newRow = true;
              currentMin = Math.floor(slotMin / 60);
            }

            const selectedClassname = slotDto.id === values.interventionSlotId ? "selected" : "";
            const bookedClassname = slotDto.status ? slotDto.status : "";
            return <Fragment key={ix}>
              {newRow && <div className="new-row-div" />}
              {ix > 0 && !newRow && <> </>}
              <div
                  className={`time-slot ${selectedClassname} ${bookedClassname}`}
                  onClick={() => {
                    if (InterventionSlotStatus.FREE !== slotDto.status) {
                      return;
                    }

                    form.change("interventionSlotId", slotDto.id);
                  }}
              >
                {slot.format("HH:mm")}
              </div>
            </Fragment>;
          }
      );
    }

    return <>
      {slots}
    </>;
  };

}

export default withRouterWorkaround(
    withAuthContext(
        withCompanyDataContext(
            withTranslation(["mipoco"])(
                RequestPcrTest))));