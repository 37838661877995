
const TIME_REGEX = "^(0[0-9]|1[0-9]|2[0-3]|[0-9]):[0-5][0-9]$";
const IBAN_REGEX = "(CH|LI)[a-zA-Z0-9]{19}$";
const EMAIL_REGEX = "(^$|^(([^<>()[\\]\\\\.,;:\\s@\"]+(\\.[^<>()[\\]\\\\.,;:\\s@\"]+)*)|(\".+\"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$)"
const TELEPHONE_REGEX = "^\\+?[\\d\\s]+$";

export const required = (value: any) => {
  if (typeof value == "string") {
    return value.trim() !== "" ? undefined : "Required";
  } else if (typeof value == "number") {
    return undefined;
  } else if (Array.isArray(value) && !value.length) {
    return "Required";
  } else {
    return value ? undefined : "Required";
  }
};

export const timeValidator = (value: string): string | undefined => {

  if (value) {
    return value.match(TIME_REGEX) ? undefined : "Required";
  }

  return undefined;
};

export const ibanValidator = (value: string): string | undefined => {

  if (value) {
    return value.match(IBAN_REGEX) ? undefined : "Required";
  }

  return undefined;
};

export const composeValidators = (...validators: any) => (value: any) =>
    validators.reduce((error: any, validator: any) => error || validator(value), undefined);

export const mustBeNumber = (value: any) => {

  if (value) {
    if (!isValueValidNumber(value)) {
      return "validator.mustBeNumber";
    }
  }

  return undefined;
};

export const emailValidator = (value: string): string | undefined => {

  if(value) {
    return value.match(EMAIL_REGEX) ? undefined : "You have entered an incorrect email";
  }

  return undefined;
}

export const telephoneValidator = (getMessage: Function) => (value: string): string | undefined => {

  if (value) {
    return value.match(TELEPHONE_REGEX) ? undefined : getMessage("personOnboard.error.wrongTelephoneFormat");
  }

  return undefined;
}

export const telephoneCanNotBeLongerThen = (maxLength: number, getMessage: Function) => (value: any): string | undefined => {

  if ((value && maxLength)) {
    return (value.length <= maxLength) ? undefined : getMessage("personOnboard.error.telephoneLengthLongerThenMax");
  }

  return undefined;
}

export const mustBePositiveNumber = (value: any) => {

  if (+value < 0) {
    return "validator.mustBePositiveNumber"
  }

  return undefined;
};

export const mustNotBeZero = (value: any) => {

  if (value) {
    if (value === 0) {
      return "validator.mustNotBeZero";
    }
  }

  return undefined;
};

export const maxDigits = (numOfDigits: number) => (value: any) => {
  return value.length > numOfDigits ? " validator.maxDigits " + numOfDigits : undefined;
};

export const maxChars = (numOfChars: number) => (value: string) => {
  return value && value.length > numOfChars ? " validator.maxChars " + numOfChars : undefined;
};

export const validateOnlyIfPresent = (composedValidators: (value: string) => string | undefined) => (value: any) => {
  return value ? composedValidators(value) : undefined;
};

export const isValueEmpty = (value: any): boolean => value === undefined || value === "";

// eslint-disable-next-line
export const isValueValidNumber = (value: string | number): boolean => +value == +value && !value.toString().includes("e");

export interface TarmedErrorMessage {
  messageKey: string,
  procedureCode?: string,
  remainingQuantity?: number,
  maxQuantity?: number
}
