import {AuthConsumerRenderProps, withAuthContext} from "auth/AuthContext";
import axios, {CancelTokenSource} from "axios";
import CompanyDataHeader from "component/CompanyDataHeader";
import ConfirmActionPopup from "component/ConfirmActionPopup";
import DeleteActionButton from "component/DeleteActionButton";
import {CheckboxSc} from "component/final-form/CheckBox";
import HeaderMessage from "component/HeaderMessage";
import LoaderComponent from "component/LoaderComponent";
import StyledErrorMessage from "component/StyledErrorMessage";
import VirtualizedTable from "component/VirtualizedTable";
import React, {ChangeEvent, Component, createRef} from 'react';
import {withTranslation, WithTranslation} from "react-i18next";
import {RouteComponentProps} from "react-router";
import {Button, Icon, Input, InputOnChangeData} from "semantic-ui-react";
import {
  deletePoolTestKit,
  registerPoolTestkit,
  searchPoolTestKitRegistrations,
  sendPoolTestKits
} from "service/testKitServices";
import styled from "styled-components";
import {InterventionBookingStatus} from "ts-types/api.enums";
import {TestPoolingRegistrationSearchResult} from "ts-types/api.types";
import {isKeyCheck} from "util/keyUtils";
import {applyStyles} from "util/localizationUtils";
import {withRouterWorkaround} from "util/workaroundUtils";

const TestRegistrationDiv = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;

  .header-message-text {
    font-size: 1.5rem;
  }

  .search-form {
    padding-left: 0.75rem;
    margin-bottom: 1rem;

    label {
      margin-right: 1rem;
    }

    .ui.input {
      min-width: 15rem;
    }

    button {
      margin-left: 1rem;
    }
  }

  .selected-testkits {
    margin: 0 0.5rem 0.2rem;
    font-size: 0.978rem;
    color: #454545;
  }

  .results-table {
    flex: 1 1 auto;

    .row-actions {
      i.icon,
      i.icons {
        color: #768aff;
      }
    }
  }

  .icon-button {
    padding: 2px !important;
    background: transparent !important;
  }

  .page-actions {
    justify-self: start;
    margin-top: 0.75rem;

    .actions-left {
      float: left;
    }

    .actions-right {
      float: right;

      .ui.button {
        margin-left: 0.5rem;
      }

      .search-form {
        display: inline-block;
        margin-right: 1rem;
      }
    }
  }

  .error {
    margin-bottom: 1rem;
  }
`;

interface Props extends RouteComponentProps<any>, AuthConsumerRenderProps, WithTranslation {
}

interface State {
  query: string,
  poolTestKitCode: string,
  testKitRegistrations: TestPoolingRegistrationSearchResult[],
  selectedTestKitRegistrations: Set<number>,
  numberOfSentTestkitRegistrations?: number,
  qrcodeb64?: string,
  testRegistrationUrl?: string,
  qrcodeVisible: boolean,
  testRegistrationsLoaded: boolean,
  errorMessages: Array<string>,
  cancelTokenSource: CancelTokenSource;
}

class PoolTestRegistration extends Component<Props, State> {

  poolTestKitCodeInputRef = createRef<Input>();

  constructor(props: Props) {
    super(props);

    const cancelTokenSource = axios.CancelToken.source();
    this.state = {
      query: "",
      poolTestKitCode: "",
      testKitRegistrations: [],
      selectedTestKitRegistrations: new Set(),
      testRegistrationsLoaded: true,
      qrcodeVisible: false,
      errorMessages: [],
      cancelTokenSource
    };

    setTimeout(() => {
      this.fetchTestKitRegistrations();
    }, 5);
  }

  fetchTestKitRegistrations = async () => {
    const {query, cancelTokenSource} = this.state;

    this.setState({
      testRegistrationsLoaded: false,
      errorMessages: []
    });

    try {
      const searchResults = await searchPoolTestKitRegistrations(query, cancelTokenSource);
      this.setState({
        testKitRegistrations: searchResults,
        selectedTestKitRegistrations: new Set()
      });
    } catch (ex) {
      this.handleError(ex.response.data);
    } finally {
      this.setState({
        testRegistrationsLoaded: true
      });
    }
  };

  handleError = (error: any) => {
    const {t} = this.props;

    if (error) {
      const errorCode = error.errorCode;
      const knownErrors: Array<string> = [
        "INVALID_BARCODE",
        "BARCODE_ALREADY_EXISTS",
        "ANOTHER_REGISTERED_TESTKIT_IN_PROGRESS_ALREADY_EXIST",
        "INVALID_INPUT"
      ];

      const violations: Array<any> = error.violations;

      if (violations && violations.length > 0) {
        violations.forEach(violation => {
          if (knownErrors.includes(violation.errorCode)) {
            this.setErrorMessage(t(`testkitToken.error.${violation.errorCode}`));
          }
        });
      }

      if (!this.state.errorMessages.length) {
        if (knownErrors.includes(errorCode)) {
          this.setErrorMessage(t(`testkitToken.error.${errorCode}`));
        } else {
          this.setErrorMessage(t('error.general'));
        }
      }
    }
  };

  setErrorMessage = (errorMessage?: string) => {

    const {errorMessages} = this.state;

    if (errorMessage) {
      const errMsgs = [...errorMessages];
      errMsgs.push(errorMessage);

      this.setState({
        errorMessages: errMsgs
      });
    } else {

      this.setState({
        errorMessages: []
      });
    }
  };

  selectTestRegistration = (testKit: TestPoolingRegistrationSearchResult) => {

    const {selectedTestKitRegistrations} = this.state;

    if (this.isTestKitSelectable(testKit)) {

      let selected = new Set(selectedTestKitRegistrations);

      const bookingId = testKit.poolTestId;
      if (selectedTestKitRegistrations.has(bookingId)) {
        selected.delete(bookingId);
      } else {
        selected.add(bookingId);
      }

      this.setState({
        selectedTestKitRegistrations: selected
      });
    }
  };

  isTestKitSelectable = (testKit: TestPoolingRegistrationSearchResult) => {
    return testKit.interventionBookingStatus === InterventionBookingStatus.EXECUTED && testKit.numTestKits > 0;
  };

  isTestKitSelected = (testKit: TestPoolingRegistrationSearchResult) => {
    const {selectedTestKitRegistrations} = this.state;
    return selectedTestKitRegistrations.has(testKit.poolTestId);
  };

  onEditPoolTestKit = (poolTestKitData: any) => {
    this.props.history.push("/pool-testkit-registration/edit", {
      poolTestId: poolTestKitData.poolTestId,
      poolTestKitCode: poolTestKitData.testKitCode
    });
  };

  onDeletePoolTestKit = async (poolTestId: number) => {
    await deletePoolTestKit(poolTestId, this.state.cancelTokenSource);
    this.fetchTestKitRegistrations();
  };

  sendExecutedPoolTestKits = async () => {

    const selectedTestIds = this.state.selectedTestKitRegistrations;
    const request = {covidTestIds: Array.from(selectedTestIds)};

    let numberSent = 0;
    try {

      numberSent = await sendPoolTestKits(request, this.state.cancelTokenSource);

      this.fetchTestKitRegistrations();

    } finally {
      this.setState({
        selectedTestKitRegistrations: new Set(),
        numberOfSentTestkitRegistrations: numberSent
      });
    }
  };

  setQuery = (evt: ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
    this.setState({
      query: data.value
    });
  };

  onQueryKeyDown = (event: any) => {
    event.persist();
    const isKey: any = isKeyCheck(event);

    if (isKey.enter) {
      this.fetchTestKitRegistrations();
    }
  };

  setPoolTestKitCode = (evt: ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
    this.setState({
      poolTestKitCode: data.value
    });
  };

  onPoolTestKitCodeKeyDown = (event: any) => {
    event.persist();
    const isKey: any = isKeyCheck(event);

    if (isKey.enter) {
      this.addNewPoolTestKit();
    }
  };

  addNewPoolTestKit = async () => {
    const {poolTestKitCode, cancelTokenSource} = this.state;
    try {
      const newPoolTestKit = await registerPoolTestkit(poolTestKitCode, cancelTokenSource);
      this.setState({
        poolTestKitCode: ""
      });
      this.onEditPoolTestKit(newPoolTestKit);
    } catch (ex) {
      this.handleError(ex.response.data);
    }
  };

  render(): React.ReactNode {

    const {t} = this.props;
    const {
      query,
      testKitRegistrations,
      selectedTestKitRegistrations,
      numberOfSentTestkitRegistrations,
      poolTestKitCode,
      errorMessages,
      testRegistrationsLoaded
    } = this.state;

    const state: any = this.props.location.state;
    const registeredTestkitCode = (state && state.registeredTestkitCode) ? state.registeredTestkitCode : undefined;

    const countSelectedTestkits = selectedTestKitRegistrations.size;
    const countSelectableTestkits = testKitRegistrations.filter(this.isTestKitSelectable).length;

    return <TestRegistrationDiv className="test-registration">

      {
        registeredTestkitCode &&
        <HeaderMessage visible>
          <div className="header-message-text">
            {applyStyles(t("testKitRegistration.success.registrationSuccess",
                {code: registeredTestkitCode}))}
          </div>
        </HeaderMessage>
      }

      {
        numberOfSentTestkitRegistrations && numberOfSentTestkitRegistrations > 0 &&
        <HeaderMessage visible>
          <div className="header-message-text">
            {applyStyles(t("poolTestKitRegistration.success.restRegistrationsSent",
                {numOfTestkits: numberOfSentTestkitRegistrations}))}
          </div>
        </HeaderMessage>
      }

      <CompanyDataHeader />

      <div className="title-h1">{t("poolTestKitRegistration.title")}</div>

      {errorMessages.length > 0 &&
      <div className="error">
        <StyledErrorMessage onDismiss={() => this.setErrorMessage()}>
          {errorMessages.map(err => <div key={err}>{err}</div>)}
        </StyledErrorMessage>
      </div>
      }

      <div className="search-form">
        <label>{t("testKitRegistration.search")}</label>
        <Input
            placeholder=""
            value={query}
            onChange={this.setQuery}
            onKeyDown={(e: any) => this.onQueryKeyDown(e)}
        />

        <Button
            type="button"
            className="action-button"
            primary
            onClick={this.fetchTestKitRegistrations}
        >
          {t("testKitRegistration.searchButton")}
        </Button>
      </div>

      <div className="selected-testkits">
        {t("poolTestKitRegistration.selectedTestkits")}:&nbsp;
        <strong>{countSelectedTestkits}/{countSelectableTestkits}</strong>
      </div>

      <div className="results-table">
        {!testRegistrationsLoaded
            ? <LoaderComponent message={t("employeeDashboard.table.loading")} />
            : this.renderTestKitRegistrationsTable()
        }
      </div>

      <div className="page-actions">
        {/*<div className="actions-left">
        </div>*/}

        <div className="actions-right">
          <div className="search-form">
            <label>{t("poolTestKitRegistration.addPoolTestKit.label")}:</label>
            <Input
                ref={this.poolTestKitCodeInputRef}
                placeholder=""
                value={poolTestKitCode}
                onChange={this.setPoolTestKitCode}
                onKeyDown={(e: any) => this.onPoolTestKitCodeKeyDown(e)}
            />

            <Button
                type="button"
                className="action-button"
                primary
                onClick={this.addNewPoolTestKit}
            >
              {t("poolTestKitRegistration.addPoolTestKit.addAction")}
            </Button>
          </div>

          <ConfirmActionPopup
              message={t("poolTestKitRegistration.popup.sendTestKits",
                  {
                    numberOfExecutedTestkits: selectedTestKitRegistrations.size
                  })}
              renderTarget={<Button
                  type="button"
                  className="action-button"
                  primary
                  icon={{name: "shipping fast"}}
                  disabled={selectedTestKitRegistrations.size < 1}
                  content={t("poolTestKitRegistration.button.sendTestKits")}
              />}
              onConfirmAction={() => this.sendExecutedPoolTestKits()}
          />

          <Button
              type="button"
              className="action-button"
              color={"grey"}
              onClick={(evt) => this.props.history.push("/")}
          >
            {t("action.back")}
          </Button>
        </div>
      </div>

    </TestRegistrationDiv>;
  }

  renderTestKitRegistrationsTable = (): JSX.Element => {

    const {t} = this.props;
    const {testKitRegistrations} = this.state;

    return (
        <VirtualizedTable
            rowCount={testKitRegistrations.length}
            rowGetter={this.testKitRegistrationsRowGetter}
            rowRenderer={this.testKitRegistrationsRowRenderer}
            columns={[
              {
                width: 60,
                label: this.selectHeaderColumn(),
                dataKey: "index",
                cellRenderer: this.selectableTestRegistrationSelectCellRenderer
              },
              {
                width: 200,
                label: t("poolTestKitRegistration.tableHeader.code"),
                dataKey: "testKitCode",
                flexGrow: 2
              },
              {
                width: 80,
                label: (t("poolTestKitRegistration.tableHeader.numTestKits")),
                dataKey: "numTestKits"
              },
              {
                width: 120,
                label: (t("poolTestKitRegistration.tableHeader.employees")),
                dataKey: "employeeFullNames",
                flexGrow: 3,
                cellRenderer: this.employeesCellRenderer
              },
              {
                width: 150,
                label: (t("poolTestKitRegistration.tableHeader.remarks")),
                dataKey: "testKitId",
                flexGrow: 1,
                cellRenderer: this.remarksCellRenderer
              },
              {
                width: 120,
                label: (t("employee.actions")),
                dataKey: "poolTestId",
                cellRenderer: this.actionCellRenderer
              }
            ]}
        />

    );
  };

  selectHeaderColumn = () => {

    const {testKitRegistrations, selectedTestKitRegistrations} = this.state;

    const countSelectableTestRegistrations =
        testKitRegistrations.filter(this.isTestKitSelectable).length;

    const countSelectedTestRegistrations = selectedTestKitRegistrations.size;
    const indeterminate = countSelectedTestRegistrations > 0
        && countSelectedTestRegistrations < countSelectableTestRegistrations;
    const noSelectedTestRegistrations = countSelectedTestRegistrations === 0
        && countSelectableTestRegistrations > 0;
    const allTestRegistrationsSelected = countSelectableTestRegistrations > 0
        && countSelectedTestRegistrations === countSelectableTestRegistrations;

    return (
        <CheckboxSc
            checked={countSelectedTestRegistrations > 0}
            indeterminate={indeterminate}
            onChange={
              () => {
                if (noSelectedTestRegistrations || indeterminate) {
                  const covidTestIds = testKitRegistrations
                  .filter(this.isTestKitSelectable)
                  .map(tr => tr.poolTestId);

                  this.setState({
                    selectedTestKitRegistrations: new Set(covidTestIds)
                  });
                }

                if (allTestRegistrationsSelected) {
                  this.setState({
                    selectedTestKitRegistrations: new Set()
                  });
                }

              }
            }
            disabled={countSelectableTestRegistrations === 0}
        />
    );
  };

  selectableTestRegistrationSelectCellRenderer = (data: any) => {

    const {rowData} = data;
    const testKit: TestPoolingRegistrationSearchResult = rowData;

    if (this.isTestKitSelectable(testKit)) {
      return (
          <CheckboxSc
              checked={this.isTestKitSelected(testKit)}
              onChange={() => this.selectTestRegistration(testKit)}
          />
      );
    }

    return <></>;
  };

  testKitRegistrationsRowGetter = ({index}: any) => {
    const {testKitRegistrations} = this.state;
    Object.assign(testKitRegistrations[index], {index: index + 1});

    return testKitRegistrations[index];
  };

  testKitRegistrationsRowRenderer = ({className, columns, index, key, style}: any) => {
    const a11yProps = {'aria-rowindex': index + 1};

    // const {testKitRegistrations} = this.state;
    // const registration = testKitRegistrations[index];

    let rowStyle = {...style};

    return (
        <div
            {...a11yProps}
            className={className}
            key={key}
            role="row"
            style={rowStyle}
        >
          {columns}
        </div>
    );
  };

  employeesCellRenderer = (data: any) => {
    const {rowData} = data;
    return <div>
      {
        rowData.employeeFullNames.join(", ")
      }
    </div>;
  };

  remarksCellRenderer = (data: any) => {
    return "";
  };

  actionCellRenderer = ({rowData}: any) => {
    const {t} = this.props;

    return <div className="row-actions">
      <Button className="icon-button" icon
              onClick={() => this.onEditPoolTestKit({...rowData})}
      >
        <Icon name="edit outline" />
      </Button>
      <DeleteActionButton
          popupMessage={
            t("poolTestKitRegistration.action.deleteConfirmMessage",
                {
                  code: rowData.testKitCode
                })
          }
          hoverMessage={t("testKitRegistration.action.deleteHoverMessage")}
          onConfirm={() => this.onDeletePoolTestKit(rowData.poolTestId)}
          trigger={<Icon name={'trash alternate outline'} />}
      />
    </div>;
  };

}

export default withRouterWorkaround(
    withAuthContext(
        withTranslation(["mipoco"])(
            PoolTestRegistration)));
