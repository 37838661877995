import {AuthConsumerRenderProps, withAuthContext} from "auth/AuthContext";
import {CompanyDataConsumerRenderProps, withCompanyDataContext} from "component/CompanyDataContext";
import CompanyDataHeader from "component/CompanyDataHeader";
import CheckBox, {CheckboxSc} from "component/final-form/CheckBox";
import Input from "component/final-form/Input";
import StyledErrorMessage from "component/StyledErrorMessage";
import VirtualizedTable from "component/VirtualizedTable";
import {FormState} from "final-form";
import _ from "lodash";
import moment from "moment";
import React, {Component} from "react";
import {Field, Form as FinalForm, FormRenderProps, FormSpy} from 'react-final-form';
import {withTranslation, WithTranslation} from "react-i18next";
import {RouteComponentProps} from "react-router";
import {Button, Icon, Loader, TextArea, TextAreaProps} from "semantic-ui-react";
import {searchEmployees, sendEmailToEmployees} from "service/employeeServices";
import axios from "service/http";
import styled from "styled-components";
import {debounce} from "ts-debounce";
import {EmployeeDto, EmployeeEmailRequest, EmployeeSearchRequest, InterventionDto} from "ts-types/api.types";
import {isKeyCheck} from "util/keyUtils";
import {withRouterWorkaround} from "util/workaroundUtils";
import {getAllInterventions} from "../../service/interventionServices";
import {noop} from "../../util/functionUtils";
import {interventionDescription} from "../../util/interventionUtils";

const EmployeesContainer = styled.div`
  flex: 1 1 auto;

  display: flex;
  flex-direction: column;

  .search-form {
    padding-left: 0.75rem;
    margin-bottom: 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;

    label {
      margin-right: 1rem;
    }

    .ui.input {
      min-width: 15rem;
    }

    button {
      margin-left: 1rem;
    }

    .checkbox {
      margin-left: 1rem;
    }
  }

  .error {
    margin-bottom: 1rem;
  }
  
  .selected-participants {
    margin: 0 0.5rem 0.2rem;
    font-size: 0.978rem;
    color: #454545;
  }

  .results-table {
    flex: 1 1 auto;
    min-height: 250px;

    .row-actions {
      i.icon,
      i.icons {
        color: #768aff;
      }
    }

    .table-loader.ui.inline.loader.active,
    .table-loader.ui.inline.loader.visible {
      display: block;
      top: 5rem;
    }

    .ui.checkbox label:before {
      border-color: #7687FF !important;
      border-radius: unset;
    }
  }

  .textarea {
    display: inline-block;
    min-height: 200px;
    max-height: 400px;
    margin-top: 2rem;
    margin-right: 1.5rem;

    textarea {
      border: 1px solid #c9d0ff;
      border-radius: unset;
      padding: .5rem 0.675rem;
      min-width: 500px;
      max-width: 700px;
      min-height: 200px;
      max-height: 400px;

      :focus {
        border: 1px solid #7687FF;
      }

      :focus-visible {
        outline: unset;
      }
    }
  }

  .page-actions {
    align-self: flex-end;
    display: inline-block;
    margin-top: 1rem;
  }

  .action-message {
    display: inline-block;
    font-size: 1.2rem;
    padding-right: 1rem;

    &.email-send-success {
      color: darkgreen;
    }

    &.email-send-error {
      color: darkred;
    }
  }
`;

const cancelTokenSource = axios.CancelToken.source();

interface SendEmailEmployee extends EmployeeDto {
  selected: boolean
}

interface EmployeeFieldProps extends FormState<any> {
  values: Partial<EmployeeSearchRequest>
}

interface Props extends RouteComponentProps<any>,
    AuthConsumerRenderProps,
    WithTranslation,
    CompanyDataConsumerRenderProps {

}

interface State {
  foundEmployees: Array<SendEmailEmployee>;
  employeesLoaded: boolean;
  searchValues: Partial<EmployeeSearchRequest>;
  emailContent: string;
  emailSubject: string;
  sendingEmailsInProgress: boolean;
  showEmailSendSuccess: boolean;
  emailSendFailure?: string;
  interventions: InterventionDto[];
  errorMessages: Array<string>;
}

class EmployeesCommunicationView extends Component<Props, State> {

  private mounted: boolean = false;

  constructor(props: Props) {
    super(props);

    const {t, currentUser} = this.props;
    const currentUserName = `\n\n${t("common.regards.line1.fromAdmin")}\n${currentUser?.firstName} ${currentUser?.lastName}`;

    this.state = {
      foundEmployees: [],
      employeesLoaded: true,
      searchValues: {},
      emailContent: currentUserName,
      emailSubject: "",
      sendingEmailsInProgress: false,
      showEmailSendSuccess: false,
      interventions: [],
      errorMessages: []
    };

    setTimeout(() => {
      this.fetchEmployees();
    }, 10);
  }

  componentDidMount(): void {
    this.mounted = true;
  }

  setEmailContent = (event: React.ChangeEvent<HTMLTextAreaElement>, data: TextAreaProps) => {
    const text = data.value;
    if (text) {
      this.setState({
        emailContent: text.toString()
      });
    } else {
      this.setState({
        emailContent: ""
      });
    }
  };

  setEmailSubject = (event: React.ChangeEvent<HTMLTextAreaElement>, data: TextAreaProps) => {
    const text = data.value;
    if (text) {
      this.setState({
        emailSubject: text.toString()
      });
    } else {
      this.setState({
        emailSubject: ""
      });
    }
  };

  handleError(error: any) {
    const {t} = this.props;

    if (error) {
      const errorCode = error.errorCode;
      const knownErrors: Array<string> = [];

      const violations: Array<any> = error.violations;

      if (violations && violations.length > 0) {
        violations.forEach(violation => {
          if (knownErrors.includes(violation.errorCode)) {
            this.setErrorMessage(t(`error.${violation.errorCode}`));
          }
        });
      }

      if (!this.state.errorMessages.length) {
        if (knownErrors.includes(errorCode)) {
          this.setErrorMessage(t(`error.${errorCode}`));
        } else {
          this.setErrorMessage(t('error.general'));
        }
      }
    }
  };

  setErrorMessage = (errorMessage?: string) => {

    const {errorMessages} = this.state;

    if (errorMessage) {

      const errMsgs = [...errorMessages];
      errMsgs.push(errorMessage);

      this.setState({
        errorMessages: errMsgs
      });
    } else {

      this.setState({
        errorMessages: []
      });
    }
  };

  onQueryKeyDown = (event: any) => {
    event.persist();
    const isKey: any = isKeyCheck(event);

    if (isKey.enter) {
      this.fetchEmployees();
    }
  };

  handleChange = ({values}: EmployeeFieldProps): void => {

    if (!this.mounted) {
      return;
    }

    this.setState({
      searchValues: values
    });
  };

  fetchEmployees = debounce((): void => {

    const onFinally = () => {
      this.setState({
        employeesLoaded: true
      });
    };

    this.setState({
      employeesLoaded: false,
      foundEmployees: []
    });

    let searchValues = {...this.state.searchValues};
    if (_.isEmpty(searchValues)) {
      searchValues = {
        searchKey: "",
        includesInactive: false
      };
    }

    getAllInterventions(cancelTokenSource)
        .then(response => {
          this.setState({
            interventions: response
          });
        })
        .catch((e: any) => this.handleError(e.response.data))
        .finally(noop);

    searchEmployees(searchValues, cancelTokenSource)
    .then(response => {

          const employees: Array<SendEmailEmployee> = response.map((employee) => ({
            ...employee,
            selected: false
          }));

          this.setState({
            foundEmployees: employees
          });
        }
    )
    .catch((e: any) => this.handleError(e.response.data))
    .finally(onFinally);

  }, 300);

  selectEmployee = (index: number) => {
    const {foundEmployees} = this.state;
    let employee = foundEmployees[index];
    if (employee && employee.companyEmail && employee.companyEmail.length > 0) {
      let employees = [...foundEmployees];
      employees[index].selected = !employees[index].selected;
      this.setState({
        foundEmployees: employees
      });
    }
  };

  sendEmailToEmployees = async () => {

    const {foundEmployees, emailContent, emailSubject} = this.state;

    const employeeIds = foundEmployees
    .filter(e => e.selected)
    .map(e => e.id);

    this.setState({
      sendingEmailsInProgress: true,
      showEmailSendSuccess: false,
      emailSendFailure: undefined
    });

    const request: EmployeeEmailRequest = {
      employeeIds: employeeIds,
      emailSubject: emailSubject,
      emailContent: emailContent
    };

    try {
      await sendEmailToEmployees(request, cancelTokenSource);

      this.setState({
        showEmailSendSuccess: true
      });

      setTimeout(() => {
        this.setState({
          showEmailSendSuccess: false
        });
      }, 2000);
    } catch (e) {
      this.setState({
        emailSendFailure: "employee.emailSendFailure"
      });

      setTimeout(() => {
        this.setState({
          emailSendFailure: undefined
        });
      }, 5000);
    } finally {
      this.setState({
        sendingEmailsInProgress: false
      });

      this.fetchEmployees();
    }
  };

  render() {
    return (
        <EmployeesContainer>
          <CompanyDataHeader />

          {this.renderSearchEmployeeForm()}
        </EmployeesContainer>
    );
  }

  renderSearchEmployeeForm = (): JSX.Element => {
    return (
        <FinalForm
            onSubmit={() => {
            }}
            initialValues={{
              searchKey: "",
              includesInactive: false
            }}
            subscription={{pristine: true}}
            render={this.renderSearchFormContent}
        />
    );
  };

  renderSearchFormContent = ({form}: FormRenderProps): React.ReactNode => {

    const {t} = this.props;
    const {foundEmployees, emailContent, employeesLoaded, errorMessages} = this.state;

    const countSelectedEmployees = foundEmployees.filter(e => e.selected).length;
    const countSelectableEmployees =
        foundEmployees.filter(e => e.companyEmail && e.companyEmail.length > 0).length;
    const disableSendEmail = !(countSelectedEmployees > 0 && emailContent !== "");

    return (
        <>
          <div className="title-h1">{t("employee.sendEmail.header")}</div>

          {errorMessages.length > 0 &&
          <div className="error">
            <StyledErrorMessage onDismiss={() => this.setErrorMessage()}>
              {errorMessages.map(err => <div key={err}>{err}</div>)}
            </StyledErrorMessage>
          </div>
          }

          <div className="search-form">
            <label htmlFor="searchKey">{t("employee.search")}:</label>

            <Field
                id="searchKey"
                name="searchKey"
                component={Input}
                onKeyDown={(e: any) => this.onQueryKeyDown(e)}
                autoFocus
                fluid
            />

            <Button
                type="button"
                className="action-button"
                primary
                onClick={() => this.fetchEmployees()}
            >
              {t("employee.searchButton")}
            </Button>

            <Field
                name="includesInactive"
                className="checkbox"
                component={CheckBox}
                label={t("employee.includingInactive")}
                toggle={true}
            />
          </div>

          <div className="selected-participants">
            {t("employee.selectedParticipants")}:&nbsp;&nbsp;
            <strong>{countSelectedEmployees} / {countSelectableEmployees}</strong>
          </div>

          <div className="results-table">
            {this.renderEmployeesTable()}
            {!employeesLoaded &&
            <Loader className="table-loader" active inline content={t("employee.loading")} />}
          </div>

          <div className="page-actions">
            {
              this.state.showEmailSendSuccess &&
              <div className="action-message email-send-success">
                {t("employee.emailSendSuccess")}
              </div>
            }
            {
              this.state.emailSendFailure &&
              <div className="action-message email-send-error">
                {t("employee.emailSendFailure")}
              </div>
            }

            <div className="textarea">
              {/*// TODO OC: here we need input field for Subject*/}
              <TextArea placeholder={t("employee.sendEmail.textArea")}
                        value={emailContent}
                        onChange={this.setEmailContent}
              />
            </div>

            <Button
                type="button"
                className="action-button"
                onClick={() => this.sendEmailToEmployees()}
                primary
                style={{display: "inline-block", marginRight: "1.5rem", marginTop: "2rem", verticalAlign: "top"}}
                disabled={disableSendEmail}
            >
              {t("employee.sendEmail.button")}
            </Button>

            <Button
                type="button"
                className="action-button"
                secondary
                onClick={() => this.props.history.push(`/`)}
                style={{display: "inline-block", marginTop: "2rem", verticalAlign: "top"}}
            >
              {t("action.back")}
            </Button>
          </div>

          <FormSpy subscription={{values: true}} onChange={this.handleChange} />
        </>
    );
  };

  renderEmployeesTable = (): JSX.Element => {

    const {t} = this.props;
    const {foundEmployees} = this.state;
    return (
        <VirtualizedTable
            rowCount={foundEmployees.length}
            rowGetter={this.employeesRowGetter}
            rowRenderer={this.employeesRowRenderer}
            columns={[
              {
                width: 60,
                label: this.selectHeaderColumn(),
                dataKey: "index",
                cellRenderer: this.employeeSelectCellRenderer
              },
              {
                width: 100,
                label: (t("employee.registrationState")),
                dataKey: "registrationState",
                cellRenderer: this.registrationStateCellRenderer
              },
              {
                width: 100,
                label: (t("employee.status")),
                dataKey: "active",
                cellRenderer: this.statusCellRenderer
              },
              {
                width: 150,
                label: (t("employee.admin")),
                dataKey: "companyAdmin",
                cellRenderer: this.adminCellRenderer
              },
              {
                width: 600,
                label: (t("employee.title")),
                dataKey: "firstName",
                cellRenderer: this.employeeCellRenderer
              },
              {
                width: 200,
                label: (t("employee.birthDate")),
                dataKey: "birthDate",
                cellRenderer: this.birthDateCellRenderer
              },
              {
                width: 500,
                label: (t("employee.companyEmail")),
                dataKey: "companyEmail"
              },
              {
                width: 300,
                label: (t("employee.division")),
                dataKey: "divisionName",
                cellRenderer: this.divisionCellRenderer
              },
              {
                width: 300,
                label: (t("employee.testType")),
                dataKey: "testType",
                cellRenderer: this.testTypeCellRenderer
              },
              {
                width: 200,
                label: (t("employee.testFrequency")),
                dataKey: "testFrequency",
                cellRenderer: this.testFrequencyCellRenderer
              }
            ]}
        />

    );
  };

  employeesRowGetter = ({index}: any) => {
    const {foundEmployees} = this.state;

    Object.assign(foundEmployees[index], {index: index + 1});

    return foundEmployees[index];
  };

  employeesRowRenderer = ({className, columns, index, key, style}: any) => {
    const a11yProps = {'aria-rowindex': index + 1};

    const {foundEmployees} = this.state;

    const employee = foundEmployees[index];

    let rowStyle = {...style};
    if (!employee.companyEmail) {
      rowStyle = {...rowStyle, color: "rgb(198, 198, 203)"};
    }

    return (
        <div
            {...a11yProps}
            className={className}
            key={key}
            role="row"
            style={rowStyle}
            onDoubleClick={() => this.selectEmployee(index)}
        >
          {columns}
        </div>
    );
  };

  selectHeaderColumn = () => {

    const {foundEmployees} = this.state;
    const countSelectableEmployees = foundEmployees.filter(e => e.companyEmail && e.companyEmail.length > 0).length;
    const countSelectedEmployees = foundEmployees.filter(e => e.selected).length;
    const indeterminate = countSelectedEmployees > 0 && countSelectedEmployees < countSelectableEmployees;
    const noSelectedEmployees = countSelectedEmployees === 0 && countSelectableEmployees > 0;
    const allEmployeesSelected = countSelectableEmployees > 0 && countSelectedEmployees === countSelectableEmployees;

    return (
        <CheckboxSc
            checked={countSelectedEmployees > 0}
            indeterminate={indeterminate}
            onChange={
              () => {
                if (noSelectedEmployees || indeterminate) {
                  let employees = [...foundEmployees];
                  const selectedEmployees = employees.map(e => {
                    if (e.companyEmail && e.companyEmail.length > 0) {
                      return {...e, selected: true};
                    }
                    return e;
                  });
                  this.setState({
                    foundEmployees: selectedEmployees
                  });
                }

                if (allEmployeesSelected) {
                  let employees = [...foundEmployees];
                  const selectedEmployees = employees.map(e => ({...e, selected: false}));
                  this.setState({
                    foundEmployees: selectedEmployees
                  });
                }

              }
            }
            disabled={countSelectableEmployees === 0}
        />
    );
  };

  employeeSelectCellRenderer = (data: any) => {

    const {cellData, rowData} = data;

    const index = cellData - 1;

    const email = rowData.companyEmail;

    return (
        <CheckboxSc
            checked={rowData.selected}
            onChange={() => this.selectEmployee(index)}
            disabled={!email}
        />
    );
  };

  registrationStateCellRenderer = ({cellData}: any) => {
    const {t} = this.props;

    const tooltipTitle = t(`registrationState.${cellData}`);

    if (cellData === "REJECTED") {
      return <Icon title={tooltipTitle} name="minus circle" color="red" size="large" />;
    } else if (cellData === "ACCEPTED") {
      return <Icon title={tooltipTitle} name="check circle" color="green" size="large" />;
    } else if (cellData === "PENDING") {
      return <Icon title={tooltipTitle} name="question circle" color="blue" size="large" />;
    } else if (cellData === "INITIAL") {
      return <Icon title={tooltipTitle} name="circle outline" color="grey" size="large" />;
    }
    return "";
  };

  employeeCellRenderer = ({rowData}: any) => {
    if (rowData.firstName || rowData.name) {
      return `${rowData.firstName} ${rowData.name}`;
    }

    if (rowData.companyEmail) {
      return rowData.companyEmail;
    }

    return "";
  };

  birthDateCellRenderer = ({cellData}: any) => <div>{moment(cellData).format("DD.MM.YYYY")}</div>;

  divisionCellRenderer = ({cellData}: any) => {
    if (cellData && cellData.length > 0) {
      return cellData;
    }
    return "";
  };

  testFrequencyCellRenderer = ({cellData}: any) => {
    const {t} = this.props;
    if (cellData && cellData.length > 0) {
      return t(`testFrequency.${cellData}`);
    }
    return "";
  };

  testTypeCellRenderer = ({cellData}: any) => {
    const {language} = this.props;
    const {interventions} = this.state;

    if (cellData && cellData.length > 0) {
      const intervention = interventions.find(i => i.externalCode === cellData)

      return interventionDescription(intervention, language);
    }
  };

  statusCellRenderer = ({cellData}: any) => {
    const {t} = this.props;
    if (cellData) {
      return t("employee.active");
    }
    return t("employee.inactive");
  };


  adminCellRenderer = ({cellData}: any) => {
    const {t} = this.props;
    if (cellData && cellData === true) {
      return t("employee.admin");
    }
    return "";
  };

}


let EmployeesCommunicationViewWrapper = withRouterWorkaround(
    withAuthContext(
        withCompanyDataContext(
            withTranslation(["mipoco"])(
                EmployeesCommunicationView))));

export default EmployeesCommunicationViewWrapper;