import {AuthConsumerRenderProps, withAuthContext} from "auth/AuthContext";
import {BigActionButton} from "component/BigActionButton";
import {CompanyDataConsumerRenderProps, withCompanyDataContext} from "component/CompanyDataContext";
import CompanyDataHeader from "component/CompanyDataHeader";
import Welcome from "component/Welcome";
import moment from "moment";
import React, {Component} from "react";
import {withTranslation, WithTranslation} from "react-i18next";
import {RouteComponentProps} from "react-router";
import {Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis} from 'recharts';
import {Container} from "semantic-ui-react";
import {getCompanyOverviewData} from "service/companyServices";
import axios from "service/http";
import styled from "styled-components";
import {InterventionExternalCode} from "ts-types/api.enums";
import {CompanyOverviewDataDto} from "ts-types/api.types";
import {noop} from "util/functionUtils";
import {withRouterWorkaround} from "util/workaroundUtils";

const BoldedSpan = styled.span`
  font-weight: bold;
`;

const ContentWrapperDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px solid #0f1a52;
  margin-bottom: 2rem;
  padding-bottom: 2rem;

  .data {
    flex-basis: 60%;
    padding-right: 2rem;

    > div.data-row {
      padding: 0.5rem;

      :nth-child(even) {
        background-color: #E5E5E5;
      }
    }

    .data-row {
      display: flex;
      flex-wrap: wrap;

      .column-header {
        flex-basis: 40%;
      }

      .column-list-header {
        flex-basis: 40%;
        padding-left: 5rem;

        .list-item {
          display: list-item;
          list-style-type: disc;
        }
      }

      .column-value {
        flex-basis: 60%;
      }
    }

    .break-row {
      padding: 1rem 0 1rem 0;

      .break {
        flex-basis: 100%;
        border-bottom: 1px solid #0f1a52;
      }
    }
  }

  .graph {
    flex-basis: 40%;
    background-color: #E5E5E5;
    padding: 0.5rem;

    .graph-title {
      font-size: 1.2rem;
    }
  }

  .graph-container {
    height: 400px;
  }
`;

const ButtonContentDiv = styled.div`


  @media (max-width: 853px) {
    text-align: center;
  }

  @media (min-width: 1274px) {
    > span:not(:last-child) {
      margin-right: 2rem;
    }
  }

  @media (min-width: 854px) and (max-width: 1301px) {
    > span:not(:nth-child(2n)) {
      margin-right: 2rem;
    }
  }

  @media (max-width: 853px) {
    > span {
      display: block;
    }
  }

  span > button {
    margin-bottom: 2rem;
  }

`;

const NormalText = styled.span`
  font-weight: 400;
`;

export interface DataRow {
  header: string,
  value: any,
  bold: boolean,
  partialBold?: string,
  break?: boolean,
  bullet?: boolean,
  visibility?: boolean
}

interface Props extends RouteComponentProps<any>,
    AuthConsumerRenderProps,
    WithTranslation,
    CompanyDataConsumerRenderProps {

}

interface State {
  companyDataOverview: CompanyOverviewDataDto;
}

const cancelTokenSource = axios.CancelToken.source();

class Dashboard extends Component<Props, State> {

  constructor(props: Props) {
    super(props);

    this.state = {
      companyDataOverview: {
        numEmployees: 0,
        employeesAccepted: 0,
        employeesRejected: 0,
        employeesPending: 0,
        employeesInitialized: 0,
        totalTests: 0,
        totalTestsInStock: 0,
        totalPCR: 0,
        totalRapid: 0,
        testTypeResults: [],
        weeklyResults: [],
        testCourseDateFrom: "",
        testCourseDateTo: ""
      }
    };
  }

  componentDidMount() {
    this.fetchCompanyData();
  }

  fetchCompanyData = () => {
    getCompanyOverviewData(cancelTokenSource)
    .then(response => {

      this.setState({
        companyDataOverview: response
      });
    })
    .finally(() => noop());
  };

  mapCompanyDataForDisplay = (): Array<React.ReactNode> => {

    const {t} = this.props;
    const {companyDataOverview} = this.state;

    const pcrTests = companyDataOverview.testTypeResults
    .find(t => t.testType === InterventionExternalCode.PCR_SALIVA_TEST);

    const rapidTests = companyDataOverview.testTypeResults
    .find(t => t.testType === InterventionExternalCode.RAPID_SALIVA_TEST);

    const mappedData: Array<DataRow> = [
      {
        header: t("dashboard.content.numEmployees"),
        value: `${companyDataOverview.numEmployees} (${t("dashboard.content.managedEmployees")})`,
        bold: true
      },
      {
        header: t("dashboard.content.employeesAccepted"),
        value: `${companyDataOverview.employeesAccepted}`,
        bold: false
      },
      {
        header: t("dashboard.content.employeesRejected"),
        value: `${companyDataOverview.employeesRejected}`,
        bold: false
      },
      {
        header: t("dashboard.content.employeesPending"),
        value: `${companyDataOverview.employeesPending}`,
        bold: false
      },
      {
        header: t("dashboard.content.employeesInitialized"),
        value: `${companyDataOverview.employeesInitialized}`,
        bold: false,
        break: true
      },
      {
        header: companyDataOverview.totalRapid > 0 ? t("testType.PCR_SALIVA_TEST") : t("dashboard.content.tests"),
        value: pcrTests ? pcrTests.tests : 0,
        bold: true
      },
      {
        header: t("dashboard.content.testsWithResult"),
        value: (
            <React.Fragment>
            <span style={{fontWeight: "bold"}}>
              {t('dashboard.content.testResultsContent',
                  {
                    testsWithResult: pcrTests ? pcrTests.testsWithResult : 0,
                    testsWithResultPositive: pcrTests ? pcrTests.testsWithResultPositive : 0
                  })
              }
            </span>
              &nbsp;
              <span style={{fontWeight: "bold"}}>
              {t("dashboard.content.positivityRate", {
                positivityRate:
                    !pcrTests || (pcrTests.testsWithResult === 0 && pcrTests.testsWithResultPositive === 0)
                        ? Number(0).toFixed(2)
                        : ((pcrTests.testsWithResultPositive / pcrTests.testsWithResult) * 100)
                        .toFixed(2)
              })
              }
            </span>
            </React.Fragment>
        ),
        bold: false
      },
      {
        header: t("dashboard.content.positive"),
        value: pcrTests ? pcrTests.testsWithResultPositive : 0,
        bullet: true,
        bold: false
      },
      {
        header: t("dashboard.content.negative"),
        value: pcrTests
            ? pcrTests.testsWithResultNegative
            : 0,
        bullet: true,
        bold: false
      },
      {
        header: t("dashboard.content.unclear"),
        value: pcrTests
            ? pcrTests.testsWithResultUncertain + pcrTests.testsWithResultPoolPositive
            : 0,
        bullet: true,
        bold: false
      },
      {
        header: t("dashboard.content.testsInTransit"),
        value: pcrTests ? pcrTests.testsInTransit : 0,
        bold: false
      },
      {
        header: t("dashboard.content.testsRegistered"),
        value: pcrTests ? pcrTests.testsRegistered : 0,
        bold: false
      },
      {
        header: t("testType.RAPID_SALIVA_TEST"),
        value: (
            <React.Fragment>
            <span style={{fontWeight: "bold"}}>
              {t('dashboard.content.testResultsContent',
                  {
                    testsWithResult: rapidTests ? rapidTests.testsWithResult : 0,
                    testsWithResultPositive: rapidTests ? rapidTests.testsWithResultPositive : 0
                  })
              }
            </span>
              &nbsp;
              <span style={{fontWeight: "bold"}}>
              {t("dashboard.content.positivityRate", {
                positivityRate:
                    !rapidTests || (rapidTests.testsWithResult === 0 && rapidTests.testsWithResultPositive === 0)
                        ? Number(0).toFixed(2)
                        : ((rapidTests.testsWithResultPositive / rapidTests.testsWithResult) * 100)
                        .toFixed(2)
              })
              }
            </span>
            </React.Fragment>
        ),
        bold: true,
        visibility: companyDataOverview.totalRapid > 0 ? true : false
      },
      {
        header: t("dashboard.content.positive"),
        value: rapidTests ? rapidTests.testsWithResultPositive : 0,
        bullet: true,
        bold: false,
        visibility: companyDataOverview.totalRapid > 0 ? true : false
      },
      {
        header: t("dashboard.content.negative"),
        value: rapidTests
            ? rapidTests.testsWithResultNegative
            : 0,
        bullet: true,
        bold: false,
        visibility: companyDataOverview.totalRapid > 0 ? true : false
      },
      {
        header: t("dashboard.content.unclear"),
        value: rapidTests ? rapidTests.testsWithResultUncertain + rapidTests.testsWithResultPoolPositive : 0,
        bullet: true,
        bold: false,
        break: true,
        visibility: companyDataOverview.totalRapid > 0 ? true : false
      },
      {
        header: t("dashboard.content.testsInStock"),
        value: companyDataOverview.totalTestsInStock ? companyDataOverview.totalTestsInStock : 0,
        partialBold: t("dashboard.content.inStock"),
        bold: true
      }
    ];
    return mappedData.map((data: DataRow, index: number) => {

          return (
              <React.Fragment key={index}>
                {
                  data.visibility === undefined || data.visibility ?
                      <div className="data-row">
                        {
                          data.bullet
                              ? <div className="column-list-header">
                                <div className="list-item">
                                  {data.header}
                                </div>
                              </div>
                              : <div className="column-header" style={{fontWeight: data.bold ? "bold" : "normal"}}>
                                {data.header}
                              </div>
                        }
                        {
                          data.value !== undefined &&
                          <div
                            className="column-value"
                            style={{fontWeight: data.bold ? "bold" : "normal"}}
                          >
                            {data.value}&nbsp;
                            <NormalText>
                              {data.partialBold !== undefined && data.partialBold}
                            </NormalText>
                          </div>
                        }
                      </div>
                      :
                      ""
                }
                {data.break &&
                <div className="break-row">
                  <div className="break" />
                </div>
                }
              </React.Fragment>
          );
        }
    );
  };
  onTestResultsButtonClick = () => {
    this.props.history.push("/test-results");
  };

  onTestKitsButtonClick = () => {
    this.props.history.push("/testkit-registration");
  };

  onEmployeesButtonClick = () => {
    this.props.history.push("/employees");
  };

  onCompanyButtonClick = () => {
    this.props.history.push("/company");
  };

  onEmployeeCommunicationButtonClick = () => {
    this.props.history.push("/employees-communication");
  };

  gotoPoolTestKitRegistrations = async () => {
    const {history} = this.props;
    history.push("/pool-testkit-registration");
  };

  onStockManagementButtonClick = () => {
    this.props.history.push("/stock-management");
  };

  render() {

    const {companyData, location} = this.props;

    const locationState: any = location.state;
    const isWelcome = locationState && locationState.welcome;

    return (
        <Container fluid>
          <Welcome isWelcome={isWelcome} companyName={companyData.name} />

          <CompanyDataHeader />

          <ContentWrapperDiv>
            {this.renderDataContent()}
            {this.renderGraphContent()}
          </ContentWrapperDiv>
          {this.renderButtonsContent()}
        </Container>
    );
  }

  renderDataContent = (): React.ReactNode => {
    const {t} = this.props;
    return (
        <div className="data">
          <div className="title-h1">{t("dashboard.title.overallOveriew")}</div>

          {this.mapCompanyDataForDisplay()}
        </div>
    );
  };

  renderGraphContent = (): React.ReactNode => {

    const {t} = this.props;
    const {companyDataOverview} = this.state;

    return (
        <div className="graph">
          <span className="graph-title">
              <BoldedSpan>{t("dashboard.graph.testCourse")}</BoldedSpan>&nbsp;
            {
              t("dashboard.graph.testCourseDateRange",
                  {
                    dateFrom: moment(companyDataOverview.testCourseDateFrom, "YYYY-MM-DD").format("DD. MMMM"),
                    dateTo: moment(companyDataOverview.testCourseDateTo, "YYYY-MM-DD").format("DD. MMMM YYYY")
                  })
            }
          </span>
          <div className="graph-container">
            <ResponsiveContainer width="99%">
              <BarChart data={companyDataOverview.weeklyResults}
                        margin={{
                          top: 30,
                          right: 30,
                          left: 0,
                          bottom: 0
                        }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="weekToDisplay" />
                <YAxis label={{value: t('dashboard.graph.axis.y.label'), position: "top", dx: 70, dy: -10}} />
                <Tooltip />
                <Bar
                    name={t('dashboard.graph.bar.testsWithResult.label')}
                    dataKey="testsWithResult"
                    stackId="tests"
                    fill="#7687FF"
                />
                <Bar
                    name={t('dashboard.graph.bar.testsWithoutResult.label')}
                    dataKey="testsWithoutResult"
                    stackId="tests"
                    fill="#aeb6f2"
                />
                <Bar
                    name={t('dashboard.graph.bar.testsNegative.label')}
                    dataKey="testsNegative"
                    stackId="result"
                    fill="#21BA45"
                />
                < Bar
                    name={t('dashboard.graph.bar.testsUncertain.label')}
                    dataKey="testsUncertain"
                    stackId="result"
                    fill="#FDECA6"
                />
                <Bar
                    name={t('dashboard.graph.bar.testsPositive.label')}
                    dataKey="testsPositive"
                    stackId="result"
                    fill="#DB2828"
                />
                <Legend align="right" verticalAlign="top" height={60} />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>
    );
  };

  renderButtonsContent = (): React.ReactNode => {

    const {currentUser} = this.props;
    const impersonate = currentUser?.skip2fa;

    return <ButtonContentDiv>
      <span>
        <BigActionButton
            icon="area graph"
            textDomain="mipoco"
            textKey="dashboard.button.testResults"
            onClick={this.onTestResultsButtonClick}
        />
      </span>

      <span>
        <BigActionButton
            icon="pie chart"
            textDomain="mipoco"
            textKey="dashboard.button.testKitsRegistration"
            onClick={this.onTestKitsButtonClick}
        />
      </span>

      <span>
        <BigActionButton
            icon="boxes"
            textDomain="mipoco"
            textKey="dashboard.button.testKitsPooling"
            onClick={this.gotoPoolTestKitRegistrations}
        />
      </span>

      <span>
        <BigActionButton
            icon="user"
            textDomain="mipoco"
            textKey="dashboard.button.manageEmployees"
            onClick={this.onEmployeesButtonClick}
        />
      </span>

      <span>
        <BigActionButton
            icon="circle notch"
            textDomain="mipoco"
            textKey="dashboard.button.manageCompany"
            onClick={this.onCompanyButtonClick}
        />
      </span>

      <span>
        <BigActionButton
            icon="mail"
            textDomain="mipoco"
            textKey="dashboard.button.massEmployeeEmail"
            onClick={this.onEmployeeCommunicationButtonClick}
        />
      </span>

      {
        impersonate &&
        <span>
          <BigActionButton
            icon="lab"
            textDomain="mipoco"
            textKey="dashboard.button.stockManagement"
            onClick={this.onStockManagementButtonClick}
          />
        </span>
      }
    </ButtonContentDiv>;
  };
}


let DashboardWrapper = withRouterWorkaround(
    withAuthContext(
        withCompanyDataContext(
            withTranslation(["mipoco"])(
                Dashboard))));

export default DashboardWrapper;