import {AuthConsumerRenderProps, withAuthContext} from "auth/AuthContext";
import DatePicker from "component/final-form/DatePicker";
import Input from "component/final-form/Input";
import Radio, {generateRadioButtonOptions} from "component/final-form/Radio";
import RemoteSelect from "component/final-form/RemoteSelect";
import Select, {
  DropdownOption,
  mapToDropdownOptionArray,
  stringArrayToDropdownOptionArray
} from "component/final-form/Select";
import LoaderComponent from "component/LoaderComponent";
import MpGrid from "component/MpGrid";
import {IndexedZipData} from "component/UpsertEmployee";
import createDecorator from "final-form-calculate";
import React, {Component} from "react";
import {Field, Form as FinalForm, FormRenderProps} from 'react-final-form';
import {withTranslation, WithTranslation} from "react-i18next";
import {RouteComponentProps} from "react-router";
import {PersonOnboardViews} from "route/person-onboard/PersonOnboardView";
import {Button, Container, Grid, RadioProps} from "semantic-ui-react";
import {getAllCountries, searchZipDataForInput} from "service/countryAndZipCityServices";
import axios from "service/http";
import {savePersonOnboarding} from "service/onboardingServices";
import styled from "styled-components";
import {UpsertEmployeeDto} from "ts-types/api.types";
import {IndexSignature} from "util/commonTypes";
import {errorUtils} from "util/errorUtils";
import {
  composeValidators,
  emailValidator,
  required,
  telephoneCanNotBeLongerThen,
  telephoneValidator
} from "util/validatorUtils";
import {withRouterWorkaround} from "util/workaroundUtils";

const UpsertContainer = styled(Container)`
  .success-message {
    color: darkgreen;
    display: inline-block;
    margin-right: 1rem;
  }
`;

const StyledMpGrid = styled(MpGrid)`

  &.ui.grid > .row > .column:first-child {
    padding-left: 0;
  }
`;

const StyledRow = styled(Grid.Row)`
  font-size: 14px;
`;

const cancelTokenSource = axios.CancelToken.source();

enum ZipDataOrderBy {
  ZIP = "ZIP",
  CITY = "CITY",
}

interface Props extends RouteComponentProps<any>,
    AuthConsumerRenderProps,
    WithTranslation {

  personOnboarding: Partial<UpsertEmployeeDto>,
  setPersonOnboardingData: (data: Partial<UpsertEmployeeDto>) => void,

  setActiveView: (activeView: PersonOnboardViews) => void,
  onRegistrationSuccess: () => void,

  errorMessages: string[],
  setErrorMessage: (m?: string) => void
}

interface State {
  genders: Array<RadioProps>,
  languages: Array<DropdownOption>,
  countries?: Array<DropdownOption>,
  cities: Array<DropdownOption>,
  zip: Array<DropdownOption>,
  formDataLoaded?: boolean,
  successMessage?: string
}

class PersonDetails extends Component<Props, State> {

  initialValues: Partial<UpsertEmployeeDto> = {};

  constructor(props: Props) {
    super(props);

    const person = props.personOnboarding;

    this.initialValues = {...person};

    const languageLabels: Array<string> = ["german", "french", "italian", "english"];
    const languageKeys: Array<string> = ["de", "fr", "it", "en"];
    const genderLabels: Array<string> = ["male", "female", "notAvailable"];

    const initialCities: Array<DropdownOption> = [];
    const initialZip: Array<DropdownOption> = [];

    this.state = {
      formDataLoaded: false,
      genders: generateRadioButtonOptions(genderLabels, "gender", props.t),
      languages: stringArrayToDropdownOptionArray(languageLabels, props.t, "language", languageKeys),
      cities: initialCities,
      zip: initialZip,
    };

    getAllCountries(cancelTokenSource)
    .then(response => {

      this.setState({
        countries: mapToDropdownOptionArray(response, "name", "shortName")
      });
      this.setInitialCountry();
    })
    .finally(() => {
      this.setState({
        formDataLoaded: true
      });
    });
  }

  setInitialCountry = () => {
    const {countries} = this.state;
    const countryId = this.initialValues.countryId;
    if (countries && countries.length > 0 && countryId === undefined) {
      const countryCH = countries.find(c => c.key === "CH");
      if (countryCH) {
        this.initialValues.countryId = countryCH.value;
      }
    }
  };

  sortZipData = (zipData: Array<IndexedZipData>, orderBy: ZipDataOrderBy) => {
    if (zipData) {
      if (orderBy === ZipDataOrderBy.ZIP) {
        return zipData.sort((a, b) => (a.zip > b.zip) ? 1 : ((b.zip > a.zip) ? -1 : 0));
      } else {
        return zipData.sort((a, b) => (a.city > b.city) ? 1 : ((b.city > a.city) ? -1 : 0));
      }
    }

    return [];
  }

  mapZipData = (field: string, zipData: Array<IndexedZipData>, orderBy: ZipDataOrderBy): Array<DropdownOption> => {

    const sortedZipData = this.sortZipData(zipData, orderBy);

    return sortedZipData.map(data => ({
      key: data.id,
      text: data[field],
      value: `${data.zip}, ${data.city}, ${data.canton ? data.canton : "N/A"}, ${data.countryId}`,
      content: `${data.zip}, ${data.city}, ${data.canton}`,
      predefinedOption: true
    }))
  };

  searchZipData = (zip: string, countryId: number) => {

    const {cities, zip: zips, countries} = this.state;

    if (countries && countries.length > 0) {

      const country = countries.find(c => c.value === countryId);
      if (country) {

        const countryCode = country.key.toString();

        searchZipDataForInput(zip, countryCode, cancelTokenSource, 75)
        .then(response => {

          const allInitialAndNewCityAdditions = cities.filter(city => city.value === city.key);
          const allInitialAndNewZipAdditions = zips.filter(zip => zip.value === zip.key);

          this.setState({
            cities: [...allInitialAndNewCityAdditions, ...this.mapZipData("city", response, ZipDataOrderBy.CITY)],
            zip: [...allInitialAndNewZipAdditions, ...this.mapZipData("zip", response, ZipDataOrderBy.ZIP)]
          })
        })
        .catch(() => {
          this.setState({
            cities: [],
            zip: []
          })
        })
      }


    }
  };

  onNewAddition = (options: "zip" | "cities") => (newAddition: DropdownOption) => {

    const {cities, zip: zips} = this.state;

    if (options === "cities") {

      const foundCity = cities.find(city => city.key === newAddition.key);

      if (!foundCity) {
        newAddition.predefinedOption = false;
        const newCities = cities.filter(city => city.predefinedOption === true);
        this.setState({
          cities: [newAddition, ...newCities]
        });
      }
    } else if (options === "zip") {

      const foundZip = zips.find(zip => zip.key === newAddition.key);

      if (!foundZip) {
        newAddition.predefinedOption = false;
        const newZips = zips.filter(city => city.predefinedOption === true);
        this.setState({
          zip: [newAddition, ...newZips]
        });
      }
    }
  };

  updateZipDataFieldDecorator = (field: string) =>
    (zipData?: string, allValues?: Partial<UpsertEmployeeDto & IndexSignature>) => {

      if (zipData) {
        const splitZipData = zipData.split(", ");

        if (splitZipData.length === 4 && allValues && allValues[field] !== zipData) {
          return zipData;
        }
      }

      return allValues ? allValues[field] : undefined;
    };

  zipDataDecorator = createDecorator(
    {
      field: "city",
      updates: {
        zip: this.updateZipDataFieldDecorator("zip"),
        canton: this.updateZipDataFieldDecorator("canton")
      }
    },
    {
      field: "zip",
      updates: {
        city: this.updateZipDataFieldDecorator("city"),
        canton: this.updateZipDataFieldDecorator("canton")
      }
    }
  );

  handleSubmit = (values: Partial<UpsertEmployeeDto & IndexSignature>) => {

    this.props.setErrorMessage();

    let zip = "";
    let city = "";
    // let canton = "";

    if (values.zip) {
      if (values.zip.includes(', ')) {
        zip = values.zip.split(", ")[0];
      } else {
        zip = values.zip;
      }
    }

    if (values.city) {
      if (values.city.includes(', ')) {
        city = values.city.split(", ")[1];
      } else {
        city = values.city;
      }
    }

    // if (values.canton) {
    //   if (values.canton.includes(', ')) {
    //     canton = values.canton.split(", ")[2];
    //   } else {
    //     canton = values.canton;
    //   }
    // }

    const upsertPersonOnboarding: Partial<UpsertEmployeeDto> = {
      ...values,
      zip: zip,
      city: city,
      //active: values.activeStatus === 1 -> TODO OC: check if we set active like in other places
    };

    savePersonOnboarding(upsertPersonOnboarding, undefined, cancelTokenSource)
    .then(response => {
      this.props.setPersonOnboardingData(upsertPersonOnboarding);
      this.props.onRegistrationSuccess();
    })
    .catch((e: any) => this.handleError(e.response.data));
  };

  handleError = (error: any) => {
    const {t} = this.props;
    const errorCode = error.errorCode;
    const knownErrors: Array<string> = [
      errorUtils.invalidInput,
      errorUtils.duplicateEmail,
      errorUtils.unknownZipCode
    ];

    const violations: Array<any> = error.violations;

    if (violations && violations.length > 0) {
      violations.forEach(violation => {
        if (knownErrors.includes(violation.errorCode)) {
          this.props.setErrorMessage(t(`error.${violation.errorCode}`));
        } else if ("UNSUPPORTED_CANTON" === violation.errorCode) {
          this.props.setErrorMessage(t("personOnboard.error.unsupportedCanton"));
        } else if ("UNKNOWN_ZIP" === violation.errorCode) {
          this.props.setErrorMessage(t("error.UNKNOWN_ZIP"))
        }
      });
    }

    const errorMessages = this.props.errorMessages;
    if (errorMessages && errorMessages.length > 0) {
      if (knownErrors.includes(errorCode)) {
        this.props.setErrorMessage(t(`error.${errorCode}`));
      } else {
        this.props.setErrorMessage(t('error.general'));
      }
    }
  };

  render() {

    const {formDataLoaded} = this.state;
    const {t} = this.props;

    return (
        <UpsertContainer fluid>

          {formDataLoaded
              ? <React.Fragment>
                {this.renderFinalForm()}
              </React.Fragment>
              : <LoaderComponent message={t("employee.loadFormData")} />
          }
        </UpsertContainer>
    );
  }

  renderFinalForm(): React.ReactNode {

    return (
        <FinalForm
            onSubmit={(values) => this.handleSubmit(values)}
            decorators={[this.zipDataDecorator]}
            initialValues={this.initialValues}
            subscription={{pristine: true, submitting: true, values: true}}
            render={this.renderPersonFormContent}
        />
    );
  }

  renderPersonFormContent = (
      {form, handleSubmit, submitting}: FormRenderProps): React.ReactNode => {

    const {t} = this.props;
    const {
      languages,
      countries,
      zip,
      cities,
      genders
    } = this.state;

    const countryId = form.getState().values.countryId;

    return (
        <form onSubmit={handleSubmit}>
          <StyledMpGrid stackable>
            <Grid.Row>
              <Grid.Column width={8}>
                {t("personOnboard.personDetails.infoText")}
              </Grid.Column>
            </Grid.Row>
            <StyledRow>
              <Grid.Column width={8}>
                <MpGrid stackable>
                  <Grid.Row>
                    <Grid.Column width={3} verticalAlign="middle">
                      {t("employee.name")}:
                    </Grid.Column>
                    <Grid.Column width={13} verticalAlign="middle">
                      <Field
                          fluid
                          name="name"
                          component={Input}
                          validate={required}
                          autoFocus
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column width={3} verticalAlign="middle">
                      {t("employee.firstName")}:
                    </Grid.Column>
                    <Grid.Column width={13} verticalAlign="middle">
                      <Field
                          fluid
                          name="firstName"
                          component={Input}
                          validate={required}
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column width={3} verticalAlign="middle">
                      {t("employee.street")}:
                    </Grid.Column>
                    <Grid.Column width={13} verticalAlign="middle">
                      <Field
                        fluid
                        name="street"
                        component={Input}
                        validate={required}
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column width={3} verticalAlign="middle">
                      {t("employee.zip")}:
                    </Grid.Column>
                    <Grid.Column width={4} verticalAlign="middle">
                      <Field
                        fluid
                        name="zip"
                        component={RemoteSelect}
                        allowAdditions
                        remoteSearch
                        customRemoteSearchMethod={(searchQuery: string) => this.searchZipData(searchQuery, countryId)}
                        onNewAddition={this.onNewAddition("zip")}
                        options={zip}
                        validate={required}
                      />
                    </Grid.Column>
                    <Grid.Column width={1} verticalAlign="middle">
                      {t("employee.city")}:
                    </Grid.Column>
                    <Grid.Column width={8} verticalAlign="middle">
                      <Field
                        fluid
                        name="city"
                        component={RemoteSelect}
                        allowAdditions
                        remoteSearch
                        customRemoteSearchMethod={(searchQuery: string) => this.searchZipData(searchQuery, countryId)}
                        onNewAddition={this.onNewAddition("cities")}
                        options={cities}
                        validate={required}
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column width={3} verticalAlign="middle">
                      {t("company.countryId")}:
                    </Grid.Column>
                    <Grid.Column width={13} verticalAlign="middle">
                      <Field
                        name="countryId"
                        component={RemoteSelect}
                        options={countries}
                        validate={required}
                        fluid
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column width={3} verticalAlign="middle">
                      {t("employee.birthDate")}:
                    </Grid.Column>
                    <Grid.Column width={8} verticalAlign="middle">
                      <Field
                          fluid
                          name="birthDate"
                          component={DatePicker}
                          validate={required}
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column width={3} verticalAlign="middle">
                      {t("employee.gender")}:
                    </Grid.Column>
                    <Grid.Column width={3} verticalAlign="middle">
                      <Field
                          name="sex"
                          component={Radio}
                          radioDefinition={genders[0]}
                      />
                    </Grid.Column>
                    <Grid.Column width={3} verticalAlign="middle">
                      <Field
                          name="sex"
                          component={Radio}
                          radioDefinition={genders[1]}
                      />
                    </Grid.Column>
                    <Grid.Column width={3} verticalAlign="middle">
                      <Field
                          name="sex"
                          component={Radio}
                          radioDefinition={genders[2]}
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column width={3} verticalAlign="middle">
                      {t("employee.telephone")}:
                    </Grid.Column>
                    <Grid.Column width={13} verticalAlign="middle">
                      <Field
                          fluid
                          name="telephone"
                          component={Input}
                          validate={composeValidators(required, telephoneValidator(t), telephoneCanNotBeLongerThen(16, t))}
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column width={3} verticalAlign="middle">
                      {t("employee.companyEmail")}:
                    </Grid.Column>
                    <Grid.Column width={13} verticalAlign="middle">
                      <Field
                          fluid
                          name="companyEmail"
                          component={Input}
                          validate={composeValidators(required, emailValidator)}
                          disabled={true}
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column width={3} verticalAlign="middle">
                      {t("employee.language")}:
                    </Grid.Column>
                    <Grid.Column width={13} verticalAlign="middle">
                      <Field
                          name="language"
                          component={Select}
                          options={languages}
                          validate={required}
                          fluid
                      />
                    </Grid.Column>
                  </Grid.Row>
                </MpGrid>
              </Grid.Column>
            </StyledRow>
            <Grid.Row>
              <Grid.Column width={16}>
                <MpGrid>
                  <Grid.Row textAlign="right">
                    <Grid.Column width={16}>
                      {
                        this.state.successMessage &&
                        <div className="success-message">
                          {t(this.state.successMessage)}
                        </div>
                      }
                      <Button
                          type="submit"
                          className="action-button"
                          primary
                          style={{display: "inline-block"}}
                      >
                        {t("companyOnboard.button.next")}
                      </Button>
                    </Grid.Column>
                  </Grid.Row>
                </MpGrid>
              </Grid.Column>
            </Grid.Row>
          </StyledMpGrid>
        </form>
    );
  };

}

export default withRouterWorkaround(
    withAuthContext(
        withTranslation(["login"])(
            PersonDetails)));