
export const getContainerFromPathname = (pathname: string): string | undefined => {
  if (pathname.match(/\/login\/(company|person|individual)$/)) {
    return;
  }

  const companyContainerMatch = pathname.match(/\/login\/company\/(\w+)$/);
  if (companyContainerMatch) {
    return companyContainerMatch[1];
  }

  const personContainerMatch = pathname.match(/\/login\/person\/(\w+)$/);
  if (personContainerMatch) {
    return personContainerMatch[1];
  }

  const containerLoginMatch = pathname.match(/\/login\/(\w+)$/);
  if (containerLoginMatch) {
    return containerLoginMatch[1];
  }
};
