import {AuthConsumerRenderProps, withAuthContext} from "auth/AuthContext";
import {CompanyDataConsumerRenderProps, withCompanyDataContext} from "component/CompanyDataContext";
import CompanyDataHeader from "component/CompanyDataHeader";
import RemoteSelect from "component/final-form/RemoteSelect";
import Select, {DropdownOption} from "component/final-form/Select";
import MpGrid from "component/MpGrid";
import StyledErrorMessage from "component/StyledErrorMessage";
import {FormApi} from "final-form";
import React, {Component} from "react";
import {Field, Form as FinalForm, FormRenderProps} from 'react-final-form';
import {withTranslation, WithTranslation} from "react-i18next";
import {RouteComponentProps} from "react-router";
import {Button, GridColumn, GridRow} from "semantic-ui-react";
import {getAllArticleGroupsByCompanyId} from "service/companyServices";
import axios from "service/http";
import {saveStockOrderForEmployee} from "service/stockerOrderServices";
import styled from "styled-components";
import {ArticleGroupDto, UpsertLightStockOrderDto} from "ts-types/api.types";
import {required} from "util/validatorUtils";
import {withRouterWorkaround} from "util/workaroundUtils";


const TestOrderContainer = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;

  .orders-table {
    flex: 1 1 auto;
    min-height: 250px;
    margin-bottom: 1rem;

    .row-actions {
      i.icon,
      i.icons {
        color: #768aff;
      }
    }

    .table-loader.ui.inline.loader.active,
    .table-loader.ui.inline.loader.visible {
      display: block;
      top: 5rem;
    }
  }

  .action-message {
    display: inline-block;
    font-size: 1.2rem;
    padding-right: 1rem;
    margin: 1rem;

    &.stock-ordered-success {
      color: darkgreen;
    }

  }

  .error {
    margin-bottom: 1rem;
  }

  .page-actions {
    display: flex;
    margin-top: 1rem;
  }

  .table-form-container {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .buttons {
    display: inline-block;
    margin-top: 1rem;
  }

  .final-form {
    display: flex;
    flex-direction: column;
  }

  .quantity-info {
    display: flex;
    flex-direction: column;
    max-width: 300px;

  }

`;

interface Props extends RouteComponentProps<any>,
    AuthConsumerRenderProps,
    WithTranslation,
    CompanyDataConsumerRenderProps {

  companyAdmin?: boolean;
}

interface State {
  pageDataLoaded: boolean;
  errorMessages: string[];

  employeeId?: number,

  articleGroup?: ArticleGroupDto;
  articleGroupOptions: DropdownOption[];
  quantityOptions: DropdownOption[];
  showStockOrderedSuccess: boolean;
}

class TestKitOrderContainerPersonViewComponent extends Component<Props, State> {

  private mounted: boolean = false;

  private cancelTokenSource = axios.CancelToken.source();

  private initialValues: Partial<UpsertLightStockOrderDto> = {
    quantity: 1
  };

  constructor(props: Props) {
    super(props);

    let employeeId: number | undefined = undefined;
    if (props.companyAdmin) {
      const state: any = this.props.location.state;
      let employeeIdString = state?.employeeId;
      employeeId = employeeIdString ? Number(employeeIdString) : undefined;
    }

    const quantityOptions: DropdownOption[] = [1, 5, 10].map(quantity => ({
      key: quantity,
      text: `${quantity}`,
      value: quantity
    }));

    this.state = {
      pageDataLoaded: false,
      errorMessages: [],

      employeeId,

      articleGroupOptions: [],
      quantityOptions,
      showStockOrderedSuccess: false
    };
  }

  componentDidMount(): void {
    this.mounted = true;
    this.loadPageData();
  }

  loadPageData = () => {

    const {t} = this.props;

    const methods: Array<Promise<any>> = [
      getAllArticleGroupsByCompanyId(this.cancelTokenSource)
    ];

    Promise.all(methods)
    .then(responses => {
      const articleGroups: Array<ArticleGroupDto> = responses[0];

      const articleGroup: ArticleGroupDto = articleGroups.filter(
          ag => ag.externalCode === this.props.companyData.testType)[0];

      const articleGroupOptions: DropdownOption[] = [articleGroup].map((articleGroup: ArticleGroupDto) => ({
        key: articleGroup.id,
        text: t("enum.InterventionExternalCode." + articleGroup.externalCode) as string,
        value: articleGroup.id
      }));

      this.setState({
        articleGroup: articleGroup,
        articleGroupOptions: articleGroupOptions
      });

      this.initialValues.articleGroupId = articleGroup.id;
    }).finally(() => {
      this.setState({
        pageDataLoaded: true
      });
    });
  };

  handleError = (error: any) => {
    const {t} = this.props;

    if (error) {
      const errorCode = error.errorCode;
      const knownErrors: Array<string> = [];

      const violations: Array<any> = error.violations;

      if (violations && violations.length > 0) {
        violations.forEach(violation => {
          if (knownErrors.includes(violation.errorCode)) {
            this.setErrorMessage(t(`error.${violation.errorCode}`));
          }
        });
      }

      if (!this.state.errorMessages.length) {
        if (knownErrors.includes(errorCode)) {
          this.setErrorMessage(t(`error.${errorCode}`));
        } else {
          this.setErrorMessage(t('error.general'));
        }
      }
    }
  };

  setErrorMessage = (errorMessage?: string) => {

    const {errorMessages} = this.state;

    if (errorMessage) {

      const errMsgs = [...errorMessages];
      errMsgs.push(errorMessage);

      this.setState({
        errorMessages: errMsgs
      });
    } else {

      this.setState({
        errorMessages: []
      });
    }
  };

  handleSubmit = (values: Partial<UpsertLightStockOrderDto>, form: FormApi) => {

    const {employeeId, articleGroup} = this.state;

    let upsertLightStockOrderDto = {
      quantity: values.quantity,
      articleGroupId: articleGroup!.id,
      packageQuantity: articleGroup!.packageQuantity
    };

    saveStockOrderForEmployee(employeeId, upsertLightStockOrderDto, this.cancelTokenSource)
    .then(response => {
      this.setState({
        showStockOrderedSuccess: true
      });
      setTimeout(() => {
        this.setState({
          showStockOrderedSuccess: false
        });
        this.goToPreviousPage();
      }, 3500);
      form.reset();
    })
    .catch(this.handleError);
  };

  goToPreviousPage = () => {
    const {companyAdmin} = this.props;
    const {employeeId} = this.state;

    if (companyAdmin && employeeId) {
      this.props.history.push("/employees/overview/test-kit-orders-list", {employeeId: employeeId});
    } else {
      this.props.history.push("/person/test-kit-orders-list");
    }
  };

  render() {
    const {pageDataLoaded} = this.state;

    if (!pageDataLoaded) {
      return <></>;
    }

    return (
        <TestOrderContainer>
          <CompanyDataHeader />
          {this.renderPageContent()}
        </TestOrderContainer>
    );
  }

  renderPageContent = (): JSX.Element => {

    const {t} = this.props;

    const {errorMessages} = this.state;

    return (
        <div className="table-form-container">
          <div className="title-h1">{t("employee.testKitOrder.title")}</div>

          {errorMessages.length > 0 &&
          <div className="error">
            <StyledErrorMessage onDismiss={() => this.setErrorMessage()}>
              {errorMessages.map(err => <div key={err}>{err}</div>)}
            </StyledErrorMessage>
          </div>
          }

          <div>
            {t("employee.testKitOrder.infoText")}
          </div>

          <div className="table-form-container">
            <div className="page-actions">
              <div className="final-form">
                {this.renderOrderForm()}
              </div>
            </div>
          </div>
        </div>
    );
  };

  renderOrderForm(): React.ReactNode {
    return (
        <FinalForm
            onSubmit={(values, form) => this.handleSubmit(values, form)}
            initialValues={this.initialValues}
            render={this.renderOrdersFrom}
            subscription={{pristine: true, submitting: true}}
        />
    );
  }

  renderOrdersFrom = ({handleSubmit, submitting}: FormRenderProps): React.ReactNode => {

    const {t} = this.props;

    const {
      articleGroupOptions,
      quantityOptions,
      showStockOrderedSuccess
    } = this.state;

    return (
        <form onSubmit={handleSubmit}>
          <MpGrid>
            <GridRow>
              <GridColumn width={6}>
                <strong>{t("stockManagement.productSelection.dropdown.title")}</strong>
              </GridColumn>
              <GridColumn width={10}>
                <Field
                    name="articleGroupId"
                    component={RemoteSelect}
                    options={articleGroupOptions}
                    validate={required}
                    disabled
                    fluid
                />
              </GridColumn>
            </GridRow>

            <GridRow>
              <GridColumn width={6}>
                <strong>{t("stockManagement.quantity.title")}</strong>
              </GridColumn>
              <GridColumn width={10}>
                <Field
                    name="quantity"
                    component={Select}
                    options={quantityOptions}
                    validate={required}
                    fluid
                />
              </GridColumn>
            </GridRow>
          </MpGrid>

          <div className="buttons">
            <Button
                type="submit"
                className="action-button"
                primary
                disabled={submitting}
                style={{display: "inline-block", marginRight: "1.5rem"}}
            >
              {t("stockManagement.stockOrder.orderButton")}
            </Button>

            <Button
                type="button"
                className="action-button"
                secondary
                disabled={submitting}
                onClick={this.goToPreviousPage}
                style={{display: "inline-block"}}
            >
              {t("action.back")}
            </Button>
          </div>

          {
            showStockOrderedSuccess &&
            <div className="action-message stock-ordered-success">
              {t("stockManagement.stockOrder.orderSendSuccess")}
            </div>
          }
        </form>
    );
  };

}

let TestKitOrderContainerPersonView = withRouterWorkaround(
    withAuthContext(
        withCompanyDataContext(
            withTranslation(["mipoco"])(
                TestKitOrderContainerPersonViewComponent))));

export default TestKitOrderContainerPersonView;
