import {AuthConsumerRenderProps, withAuthContext} from "auth/AuthContext";
import {CompanyDataConsumerRenderProps, withCompanyDataContext} from "component/CompanyDataContext";
import CompanyDataHeader from "component/CompanyDataHeader";
import CheckBox from "component/final-form/CheckBox";
import DatePicker from "component/final-form/DatePicker";
import Input from "component/final-form/Input";
import Radio, {generateRadioButtonOptions, generateRadioButtonOptionsFromEnum} from "component/final-form/Radio";
import RemoteSelect from "component/final-form/RemoteSelect";
import Select, {
  DropdownOption,
  mapToDropdownOptionArray,
  stringArrayToDropdownOptionArray
} from "component/final-form/Select";
import LoaderComponent from "component/LoaderComponent";
import MpGrid from "component/MpGrid";
import StyledErrorMessage from "component/StyledErrorMessage";
import createDecorator from 'final-form-calculate';
import React, {Component} from "react";
import {Field, Form as FinalForm, FormRenderProps} from 'react-final-form';
import {withTranslation, WithTranslation} from "react-i18next";
import {RouteComponentProps} from "react-router";
import {enabledTestFrequencies} from "route/employees/employeeUtils";
import {Button, Container, Grid, RadioProps} from "semantic-ui-react";
import {
  getCompanyData,
  getCompanyDivisions,
  getCompanyExternalCodeFromCompanyArticleGroupsWithCertificates,
  getCompanyShippableMedicaments
} from "service/companyServices";
import {getAllCountries, searchZipDataForInput} from "service/countryAndZipCityServices";
import {editEmployee, findEmployeeById, saveEmployee} from "service/employeeServices";
import {getAllGuarantors, searchGuarantorsForInput} from "service/guarantorServices";
import axios from "service/http";
import styled from "styled-components";
import {
  CovidTestSampleMethod,
  EmployeeAccessTokenType,
  RegistrationStateType,
  TestFrequency,
  TestRegistrationType
} from "ts-types/api.enums";
import {GuarantorDto, UpsertEmployeeDto, ZipDataDto} from "ts-types/api.types";
import {IndexSignature} from "util/commonTypes";
import {errorUtils} from "util/errorUtils";
import {required} from "util/validatorUtils";
import {withRouterWorkaround} from "util/workaroundUtils";

const UpsertContainer = styled(Container)`
  .success-message {
    color: darkgreen;
    display: inline-block;
    margin-right: 1rem;
  }

  .vertical-form.ui.grid {

    margin-top: 0.1rem;

    .row.label {
      padding-top: 0.5rem;
      padding-bottom: 0.2rem;
    }

    .row:not(.label) {
      padding-top: 0.5rem;
    }

    .row .vertical-radio .field {
      display: inline-block;
      padding-bottom: 0.7rem;

      .ui.radio.checkbox {
        min-width: unset;
      }
    }
  }
`;

const cancelTokenSource = axios.CancelToken.source();

enum ZipDataOrderBy {
  ZIP = "ZIP",
  CITY = "CITY",
}

export interface IndexedZipData extends IndexSignature, ZipDataDto {
}

const ColumnWithRightBorder = styled(Grid.Column)`
  margin-right: 1rem;
  border-right: ${props => props.addBorder ? "1px solid #0f1a52" : "none"};
`;

interface ExtendedUpsertEmployeeDto extends UpsertEmployeeDto {
  activeStatus: number;
}

interface Props extends RouteComponentProps<any>,
    AuthConsumerRenderProps,
    WithTranslation,
    CompanyDataConsumerRenderProps {

  onSave: () => void,
  onCancel: () => void
}

interface State {
  employeeId?: number,
  employee?: ExtendedUpsertEmployeeDto,
  genders: RadioProps[],
  testRegistrationTypes: RadioProps[],
  testFrequencies: RadioProps[],
  statuses: RadioProps[],
  countries?: DropdownOption[],
  employeeGuarantors: DropdownOption[],
  cities: DropdownOption[],
  zip: DropdownOption[],
  languages: DropdownOption[],
  companyShippable: DropdownOption[],
  testTypes: DropdownOption[],
  testSampleMethods: RadioProps[],
  divisions: DropdownOption[],
  formDataLoaded: boolean,
  successMessage?: string,
  errorMessages: Array<string>
}

class UpsertEmployee extends Component<Props, State> {

  initialEmployeeValues: Partial<ExtendedUpsertEmployeeDto> = {};

  constructor(props: Props) {
    super(props);

    const languageLabels: Array<string> = ["german", "french", "italian", "english"];
    const languageKeys: Array<string> = ["de", "fr", "it", "en"];
    const genderLabels: Array<string> = ["male", "female", "notAvailable"];
    const testRegTypesLabels: Array<string> = Object.values(TestRegistrationType);
    const testFrequenciesLabels: Array<string> = enabledTestFrequencies;
    const statusLabels: Array<string> = ["active", "inactive"];

    const testFrequencies = generateRadioButtonOptionsFromEnum(
        testFrequenciesLabels,
        "testFrequency",
        props.t
    );

    const testRegistrationTypes = generateRadioButtonOptionsFromEnum(
        testRegTypesLabels,
        "testRegistrationType",
        props.t
    );

    const statuses = generateRadioButtonOptions(
        statusLabels,
        "activeStatus",
        props.t,
        "employee",
        [1, 0]
    );

    this.state = {
      genders: generateRadioButtonOptions(genderLabels, "gender", props.t),
      testRegistrationTypes: testRegistrationTypes,
      testFrequencies: testFrequencies,
      statuses: statuses,
      formDataLoaded: false,
      employeeGuarantors: [],
      cities: [],
      zip: [],
      languages: stringArrayToDropdownOptionArray(languageLabels, props.t, "language", languageKeys),
      companyShippable: [],
      testTypes: [],
      testSampleMethods: generateRadioButtonOptionsFromEnum(
          Object.values(CovidTestSampleMethod),
          "testSampleDate",
          props.t,
          "CovidTestSampleMethod"),
      divisions: [],
      errorMessages: []
    };

    this.initFormData(props);
  }

  initFormData = async (props: Props) => {

    const state: any = this.props.location.state;
    const employeeId = (state && state.id) ? state.id : undefined;
    const hasEmployee = employeeId || employeeId === 0;

    const initialCities: Array<DropdownOption> = [];
    const initialZip: Array<DropdownOption> = [];
    const initialGuarantor: Array<DropdownOption> = [];

    let employee: ExtendedUpsertEmployeeDto | undefined = undefined;
    if (hasEmployee) {
      const response = await findEmployeeById(employeeId, cancelTokenSource);

      employee = {
        ...response,
        activeStatus: response.active ? 1 : 0
      };

      if (employee.city) {
        initialCities.push({
          key: employee.city,
          text: employee.city,
          value: employee.city,
          predefinedOption: true
        });
      }

      if (employee.zip) {
        initialZip.push({
          key: employee.zip,
          text: employee.zip,
          value: employee.zip,
          predefinedOption: true
        });
      }

      if (employee.guarantorId) {
        const guarantors = await getAllGuarantors(cancelTokenSource);
        const foundGuarantor = guarantors?.find(g => g.id === employee!.guarantorId);

        if (foundGuarantor) {
          initialGuarantor.push({
            key: employee.guarantorId,
            text: `${foundGuarantor.description}, ${foundGuarantor.city}`,
            value: employee.guarantorId,
            predefinedOption: true
          });
        }
      }

      this.setState({
        employee: employee,
        employeeId: employeeId,
        cities: initialCities,
        zip: initialZip,
        employeeGuarantors: initialGuarantor
      });
    }

    const companyId = props.companyData.id;

    const methods: Array<Promise<any>> = [
      getCompanyShippableMedicaments(companyId, cancelTokenSource),
      getCompanyExternalCodeFromCompanyArticleGroupsWithCertificates(companyId, cancelTokenSource),
      getCompanyDivisions(companyId, cancelTokenSource),
      getAllCountries(cancelTokenSource),
      getCompanyData(cancelTokenSource)
    ];

    Promise.all(methods)
    .then(responses => {

      this.setState({
        companyShippable: mapToDropdownOptionArray(responses[0], "description", "id", "id"),
        testTypes: stringArrayToDropdownOptionArray(responses[1], props.t, "testType"),
        divisions: mapToDropdownOptionArray(responses[2], "name", "id", "id"),
        countries: mapToDropdownOptionArray(responses[3], "name", "shortName")
      });

      const companyData = responses[4];

      if (!hasEmployee) {
        this.initialEmployeeValues = this.getInitialEmployeeValues(props);
        this.initialEmployeeValues.testSampleMethod = companyData.testSampleMethod;
        this.initialEmployeeValues.testType = companyData.testType;
      }

    }).finally(() => {
      this.setState({
        formDataLoaded: true
      });
    });
  };

  getInitialEmployeeValues = (props: Props): Partial<ExtendedUpsertEmployeeDto> => {

    const {countries} = this.state;

    return {
      mandateId: 1,
      activeStatus: 1,
      testFrequency: TestFrequency.WEEK_ONE,
      testRegistrationType: TestRegistrationType.CENTRAL,
      name: "",
      firstName: "",
      street: "",
      zip: "",
      city: "",
      birthDate: "",
      sex: 2,
      telephone: "",
      ahvNumber: "",
      countryId: countries ? countries[0].value : undefined,
      language: "de",
      remark: "",
      automaticCertificateNotification: props.companyData.automaticCertificateNotification,
      acceptCD: props.companyData.acceptCD,
      acceptCDRemark: props.companyData.acceptCDRemark
    };
  };

  sortZipData = (zipData: Array<IndexedZipData>, orderBy: ZipDataOrderBy) => {
    if (zipData) {
      if (orderBy === ZipDataOrderBy.ZIP) {
        return zipData.sort((a, b) => (a.zip > b.zip) ? 1 : ((b.zip > a.zip) ? -1 : 0));
      } else {
        return zipData.sort((a, b) => (a.city > b.city) ? 1 : ((b.city > a.city) ? -1 : 0));
      }
    }

    return [];
  };

  mapZipData = (field: string, zipData: Array<IndexedZipData>, orderBy: ZipDataOrderBy): Array<DropdownOption> => {

    const sortedZipData = this.sortZipData(zipData, orderBy);

    return sortedZipData.map(data => ({
      key: data.id,
      text: data[field],
      value: `${data.zip}, ${data.city}, ${data.countryId}`,
      content: `${data.zip}, ${data.city}, ${data.canton}`,
      predefinedOption: true
    }));
  };

  searchZipData = (zip: string, countryId: number) => {

    const {cities, zip: zips, countries} = this.state;

    if (countries && countries.length > 0) {

      const country = countries.find(c => c.value === countryId);
      if (country) {

        const countryCode = country.key.toString();

        searchZipDataForInput(zip, countryCode, cancelTokenSource, 75)
        .then(response => {

          const allInitialAndNewCityAdditions = cities.filter(city => city.value === city.key);
          const allInitialAndNewZipAdditions = zips.filter(zip => zip.value === zip.key);

          this.setState({
            cities: [...allInitialAndNewCityAdditions, ...this.mapZipData("city", response, ZipDataOrderBy.CITY)],
            zip: [...allInitialAndNewZipAdditions, ...this.mapZipData("zip", response, ZipDataOrderBy.ZIP)]
          });
        })
        .catch(() => {
          this.setState({
            cities: [],
            zip: []
          });
        });
      }


    }
  };

  mapGuarantorsData = (guarantorData: Array<GuarantorDto>): Array<DropdownOption> => {

    return guarantorData.map(data => ({
      key: data.id,
      text: `${data.description}, ${data.city} `,
      value: data.id,
      content: `${data.description}, ${data.city}`,
      predefinedOption: true
    }));
  };

  searchGuarantor = (description: string) => {

    const tariffTypeKVG = "10";

    if (description !== "") {
      searchGuarantorsForInput(description, tariffTypeKVG, cancelTokenSource, 75)
      .then(response => {
        this.setState({
          employeeGuarantors: this.mapGuarantorsData(response)
        });
      })
      .catch(() => {
        this.setState({
          employeeGuarantors: []
        });
      });
    } else {
      this.setState({
        employeeGuarantors: []
      });
    }
  };

  onNewAddition = (options: "zip" | "cities") => (newAddition: DropdownOption) => {

    const {cities, zip: zips} = this.state;

    if (options === "cities") {

      const foundCity = cities.find(city => city.key === newAddition.key);

      if (!foundCity) {
        newAddition.predefinedOption = false;
        const newCities = cities.filter(city => city.predefinedOption === true);
        this.setState({
          cities: [newAddition, ...newCities]
        });
      }
    } else if (options === "zip") {

      const foundZip = zips.find(zip => zip.key === newAddition.key);

      if (!foundZip) {
        newAddition.predefinedOption = false;
        const newZips = zips.filter(city => city.predefinedOption === true);
        this.setState({
          zip: [newAddition, ...newZips]
        });
      }
    }
  };

  updateZipDataFieldDecorator = (field: string) =>
      (zipData?: string, allValues?: Partial<UpsertEmployeeDto & IndexSignature>) => {

        if (zipData) {
          const splitZipData = zipData.split(", ");

          if (splitZipData.length === 3 && allValues && allValues[field] !== zipData) {
            return zipData;
          }
        }

        return allValues ? allValues[field] : undefined;
      };

  zipDataDecorator = createDecorator(
      {
        field: "city",
        updates: {
          zip: this.updateZipDataFieldDecorator("zip")
        }
      },
      {
        field: "zip",
        updates: {
          city: this.updateZipDataFieldDecorator("city")
        }
      }
  );

  setErrorMessage = (errorMessage?: string) => {

    const {errorMessages} = this.state;

    if (errorMessage) {

      const errMsgs = [...errorMessages];
      errMsgs.push(errorMessage);

      this.setState({
        errorMessages: errMsgs
      });
    } else {

      this.setState({
        errorMessages: []
      });
    }
  };

  handleError(error: any) {
    const {t} = this.props;
    const errorCode = error.errorCode;
    const knownErrors: Array<string> = [
      errorUtils.invalidInput,
      errorUtils.invalidEmail,
      errorUtils.duplicateEmail,
      errorUtils.employeeEmailAlreadyExists
    ];

    const violations: Array<any> = error.violations;

    if (violations && violations.length > 0) {
      violations.forEach(violation => {
        if (knownErrors.includes(violation.errorCode)) {
          this.setErrorMessage(t(`error.${violation.errorCode}`));
        }
      });
    }

    if (!this.state.errorMessages.length) {
      if (knownErrors.includes(errorCode)) {
        this.setErrorMessage(t(`error.${errorCode}`));
      } else {
        this.setErrorMessage(t('error.general'));
      }
    }
  };

  handleSubmit = async (values: Partial<ExtendedUpsertEmployeeDto>) => {
    const {employeeId} = this.state;
    const {currentUser} = this.props;

    let accountType = '';
    if (currentUser) {
      accountType = currentUser.accountType;
    }

    this.setErrorMessage();

    let zip = "";
    let city = "";

    if (values.zip) {
      if (values.zip.includes(', ')) {
        zip = values.zip.split(", ")[0];
      } else {
        zip = values.zip;
      }
    }

    if (values.city) {
      if (values.city.includes(', ')) {
        city = values.city.split(", ")[1];
      } else {
        city = values.city;
      }
    }

    const upsertEmployeeDto: Partial<UpsertEmployeeDto> = {
      ...values,
      zip: zip,
      city: city,
      active: values.activeStatus === 1
    };

    // const {onSave} = this.props;
    const onSave = () => {
      this.setState({
        successMessage: "employee.saveSuccess"
      });
      this.props.reloadCurrentUser();
      setTimeout(() => {
        accountType === EmployeeAccessTokenType.COMPANY
            ? this.props.history.push("/employees")
            : this.props.history.push("/");
      }, 1200);
    };

    if (employeeId) {
      await editEmployee(employeeId, upsertEmployeeDto, cancelTokenSource)
      .then(onSave)
      .catch((e: any) => this.handleError(e.response.data));
    } else {
      await saveEmployee(upsertEmployeeDto, cancelTokenSource)
      .then(onSave)
      .catch((e: any) => this.handleError(e.response.data));
    }
  };

  render() {

    const {formDataLoaded} = this.state;
    const {t} = this.props;

    return (
        <UpsertContainer fluid>
          <CompanyDataHeader />

          {formDataLoaded
              ? <React.Fragment>
                {this.renderFinalForm()}
              </React.Fragment>
              : <LoaderComponent message={t("employee.loadFormData")} />
          }
        </UpsertContainer>
    );
  }

  renderFinalForm(): React.ReactNode {

    const {employee = this.initialEmployeeValues} = this.state;

    return (
        <FinalForm
            onSubmit={(values) => this.handleSubmit(values)}
            decorators={[this.zipDataDecorator]}
            initialValues={employee}
            subscription={{pristine: true, submitting: true}}
            render={this.renderEmployeeFormContent}
        />
    );
  }

  renderEmployeeFormContent = ({form, handleSubmit, submitting}: FormRenderProps): React.ReactNode => {

    const {t, companyData, currentUser} = this.props;
    let accountType = '';
    if (currentUser) {
      accountType = currentUser.accountType;
    }

    const {
      genders,
      testRegistrationTypes,
      testFrequencies,
      statuses,
      testTypes,
      divisions,
      zip,
      cities,
      countries,
      languages,
      errorMessages,
      employeeId,
      employee,
      employeeGuarantors
    } = this.state;

    const countryId = form.getState().values.countryId;
    const visibleAcceptCD = companyData.acceptCD || (employee && employee.acceptCD);
    const disableAcceptCD = employeeId && employee
        && (employee.acceptCD || employee.registrationState === RegistrationStateType.ACCEPTED);
    const disableAcceptCDRemark = !form.getState().values.acceptCD;

    const visibleAutomaticCertificateNotification = companyData.automaticCertificateNotification
        || (employee && employee.automaticCertificateNotification);

    const isEmployeeLoggedIn = accountType !== EmployeeAccessTokenType.COMPANY;

    const labelWidth = 4;
    const valueWidth = 12;

    return (
        <form onSubmit={handleSubmit}>
          <MpGrid>
            <Grid.Row>
              <Grid.Column width={16}>
                <div className="title-h1">{t("employee.addEdit")}</div>
              </Grid.Column>
            </Grid.Row>
            {
                errorMessages.length > 0
                && <Grid.Row>
                <Grid.Column width={16}>
                  <StyledErrorMessage onDismiss={() => this.setErrorMessage()}>
                    {errorMessages.map(err => <div key={err}>{err}</div>)}
                  </StyledErrorMessage>
                </Grid.Column>
              </Grid.Row>
            }
            <Grid.Row>
              <ColumnWithRightBorder width={8} addBorder={!isEmployeeLoggedIn}>
                <MpGrid>
                  <Grid.Row>
                    <Grid.Column width={labelWidth} verticalAlign="middle">
                      {t("employee.name")}:
                    </Grid.Column>
                    <Grid.Column width={valueWidth} verticalAlign="middle">
                      <Field
                          fluid
                          name="name"
                          component={Input}
                          validate={required}
                          autoFocus
                          disabled={isEmployeeLoggedIn}
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column width={labelWidth} verticalAlign="middle">
                      {t("employee.firstName")}:
                    </Grid.Column>
                    <Grid.Column width={valueWidth} verticalAlign="middle">
                      <Field
                          fluid
                          name="firstName"
                          component={Input}
                          validate={required}
                          disabled={isEmployeeLoggedIn}
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column width={labelWidth} verticalAlign="middle">
                      {t("employee.street")}:
                    </Grid.Column>
                    <Grid.Column width={valueWidth} verticalAlign="middle">
                      <Field
                          fluid
                          name="street"
                          component={Input}
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column width={labelWidth} verticalAlign="middle">
                      {t("employee.zip")}:
                    </Grid.Column>
                    <Grid.Column width={4} verticalAlign="middle">
                      <Field
                          fluid
                          name="zip"
                          component={RemoteSelect}
                          allowAdditions
                          remoteSearch
                          customRemoteSearchMethod={(searchQuery: string) => this.searchZipData(searchQuery, countryId)}
                          onNewAddition={this.onNewAddition("zip")}
                          options={zip}
                      />
                    </Grid.Column>
                    <Grid.Column width={1} verticalAlign="middle">
                      {t("employee.city")}:
                    </Grid.Column>
                    <Grid.Column width={7} verticalAlign="middle">
                      <Field
                          fluid
                          name="city"
                          component={RemoteSelect}
                          allowAdditions
                          remoteSearch
                          customRemoteSearchMethod={(searchQuery: string) => this.searchZipData(searchQuery, countryId)}
                          onNewAddition={this.onNewAddition("cities")}
                          options={cities}
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column width={labelWidth} verticalAlign="middle">
                      {t("employee.countryId")}:
                    </Grid.Column>
                    <Grid.Column width={valueWidth} verticalAlign="middle">
                      <Field
                          name="countryId"
                          component={RemoteSelect}
                          options={countries}
                          validate={required}
                          fluid
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column width={labelWidth} verticalAlign="middle">
                      {t("employee.birthDate")}:
                    </Grid.Column>
                    <Grid.Column width={8} verticalAlign="middle">
                      <Field
                          fluid
                          name="birthDate"
                          component={DatePicker}
                          validate={required}
                          disabled={isEmployeeLoggedIn}
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column width={labelWidth} verticalAlign="middle">
                      {t("employee.gender")}:
                    </Grid.Column>
                    <Grid.Column width={3} verticalAlign="middle">
                      <Field
                          name="sex"
                          component={Radio}
                          radioDefinition={genders[0]}
                      />
                    </Grid.Column>
                    <Grid.Column width={3} verticalAlign="middle">
                      <Field
                          name="sex"
                          component={Radio}
                          radioDefinition={genders[1]}
                      />
                    </Grid.Column>
                    <Grid.Column width={3} verticalAlign="middle">
                      <Field
                          name="sex"
                          component={Radio}
                          radioDefinition={genders[2]}
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column width={labelWidth} verticalAlign="middle">
                      {t("employee.telephone")}:
                    </Grid.Column>
                    <Grid.Column width={valueWidth} verticalAlign="middle">
                      <Field
                          fluid
                          name="telephone"
                          component={Input}
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column width={labelWidth} verticalAlign="middle">
                      {t("employee.email")}:
                    </Grid.Column>
                    <Grid.Column width={valueWidth} verticalAlign="middle">
                      <Field
                          fluid
                          name="companyEmail"
                          component={Input}
                          disabled={isEmployeeLoggedIn}
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column width={labelWidth} verticalAlign="middle">
                      {t("employee.passportNumber")}:
                    </Grid.Column>
                    <Grid.Column width={valueWidth} verticalAlign="middle">
                      <Field
                          fluid
                          name="passportNumber"
                          component={Input}
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column width={labelWidth} verticalAlign="middle">
                      {t("employee.ahvNumber")}:
                    </Grid.Column>
                    <Grid.Column width={valueWidth} verticalAlign="middle">
                      <Field
                          fluid
                          name="ahvNumber"
                          component={Input}
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column width={labelWidth} verticalAlign="middle">
                      {t("employee.guarantor")}:
                    </Grid.Column>
                    <Grid.Column width={valueWidth} verticalAlign="middle">
                      <Field
                          fluid
                          name="guarantorId"
                          component={RemoteSelect}
                          remoteSearch
                          customRemoteSearchMethod={(searchQuery: string) => this.searchGuarantor(searchQuery)}
                          options={employeeGuarantors}
                          clearable
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column width={labelWidth} verticalAlign="middle">
                      {t("employee.divisionId")}:
                    </Grid.Column>
                    <Grid.Column width={valueWidth}>
                      <Field
                          fluid
                          name="divisionId"
                          component={Select}
                          options={divisions}
                          clearable
                          disabled={isEmployeeLoggedIn}
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column width={labelWidth} verticalAlign="middle">
                      {t("employee.language")}:
                    </Grid.Column>
                    <Grid.Column width={valueWidth} verticalAlign="middle">
                      <Field
                          name="language"
                          component={Select}
                          options={languages}
                          validate={required}
                          fluid
                      />
                    </Grid.Column>
                  </Grid.Row>
                </MpGrid>
              </ColumnWithRightBorder>

              {
                  !isEmployeeLoggedIn &&
                <Grid.Column width={5}>
                  <Grid className="vertical-form">
                    <Grid.Row className="label">
                      <Grid.Column width={4} verticalAlign="middle">
                        {t("employee.testType")}:
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      {/*
                                    <Grid.Column width={8} verticalAlign="middle">
                                        <Field
                                            name="medicamentId"
                                            component={Select}
                                            clearable
                                            options={companyShippable}
                                            validate={required}
                                        />
                                    </Grid.Column>
*/}
                      <Grid.Column width={16} verticalAlign="middle">
                        <Field
                          name="testType"
                          component={Select}
                          clearable
                          options={testTypes}
                          validate={required}
                          disabled={isEmployeeLoggedIn}
                        />
                      </Grid.Column>
                    </Grid.Row>

                    <Grid.Row className="label">
                      <Grid.Column width={16} verticalAlign="middle">
                        {t("employee.testSampleMethod")}:
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column className="vertical-radio" width={16} verticalAlign="middle">
                        {
                          this.state.testSampleMethods.map(option => {
                            return <Field
                                key={`testSampleMethod-option-${option.value}`}
                                name="testSampleMethod"
                                component={Radio}
                                radioDefinition={option}
                                disabled={isEmployeeLoggedIn}
                            />;
                          })
                        }
                      </Grid.Column>
                    </Grid.Row>

                    {
                        visibleAutomaticCertificateNotification &&
                      <Grid.Row className="label">
                        <Grid.Column width={16} verticalAlign="middle">
                          <Field
                            name="automaticCertificateNotification"
                            component={CheckBox}
                            label={`${t("employee.automaticCertificateNotification")}`}
                            disabled={isEmployeeLoggedIn}
                          />
                        </Grid.Column>
                      </Grid.Row>
                    }

                    <Grid.Row className="label">
                      <Grid.Column width={4} verticalAlign="middle">
                        {t("employee.testRegistrationType")}:
                      </Grid.Column>
                    </Grid.Row>

                    <Grid.Row>
                      <Grid.Column width={8} verticalAlign="middle">
                        <Field
                          name="testRegistrationType"
                          component={Radio}
                          radioDefinition={testRegistrationTypes[0]}
                          disabled={isEmployeeLoggedIn}
                        />
                      </Grid.Column>
                      <Grid.Column width={8} verticalAlign="middle">
                        <Field
                          name="testRegistrationType"
                          component={Radio}
                          radioDefinition={testRegistrationTypes[1]}
                          disabled={isEmployeeLoggedIn}
                        />
                      </Grid.Column>
                    </Grid.Row>

                    <Grid.Row className="label">
                      <Grid.Column width={4} verticalAlign="middle">
                        {t("employee.testFrequency")}:
                      </Grid.Column>
                    </Grid.Row>

                    <Grid.Row>
                      <Grid.Column width={8} verticalAlign="middle">
                        <Field
                          name="testFrequency"
                          component={Radio}
                          radioDefinition={testFrequencies[0]}
                          disabled={isEmployeeLoggedIn}
                        />
                      </Grid.Column>
                      <Grid.Column width={8} verticalAlign="middle">
                        <Field
                          name="testFrequency"
                          component={Radio}
                          radioDefinition={testFrequencies[1]}
                          disabled={isEmployeeLoggedIn}
                        />
                      </Grid.Column>
                    </Grid.Row>

                    <Grid.Row className="label">
                      <Grid.Column width={16} verticalAlign="middle">
                        {t("employee.status")}:
                      </Grid.Column>
                    </Grid.Row>

                    <Grid.Row>
                      <Grid.Column width={8} verticalAlign="middle">
                        <Field
                          name="activeStatus"
                          component={Radio}
                          radioDefinition={statuses[0]}
                          disabled={isEmployeeLoggedIn}
                        />
                      </Grid.Column>
                      <Grid.Column width={8} verticalAlign="middle">
                        <Field
                          name="activeStatus"
                          component={Radio}
                          radioDefinition={statuses[1]}
                          disabled={isEmployeeLoggedIn}
                        />
                      </Grid.Column>
                    </Grid.Row>

                    <Grid.Row className="label">
                      <Grid.Column width={16} verticalAlign="middle">
                        {t("employee.remark")}:
                      </Grid.Column>
                    </Grid.Row>

                    <Grid.Row>
                      <Grid.Column width={16} verticalAlign="middle">
                        <Field
                          name="remark"
                          component={Input}
                          disabled={isEmployeeLoggedIn}
                        />
                      </Grid.Column>
                    </Grid.Row>

                    {
                        visibleAcceptCD &&
                      <Grid.Row>
                        <Grid.Column width={16} verticalAlign="middle">
                          <Field
                            name="acceptCD"
                            component={CheckBox}
                            label={`${t("employee.acceptContractDocuments")}`}
                            disabled={disableAcceptCD || isEmployeeLoggedIn}
                          />
                        </Grid.Column>
                      </Grid.Row>
                    }

                    {
                        visibleAcceptCD &&
                      <Grid.Row>
                        <Grid.Column width={16} verticalAlign="middle">
                          <Field
                            name="acceptCDRemark"
                            component={Input}
                            disabled={disableAcceptCD || disableAcceptCDRemark || isEmployeeLoggedIn}
                            fluid
                          />
                        </Grid.Column>
                      </Grid.Row>
                    }
                  </Grid>
                </Grid.Column>
              }
            </Grid.Row>
            <Grid.Row textAlign="right">
              <Grid.Column width={16}>
                {
                    this.state.successMessage &&
                  <div className="success-message">
                    {t(this.state.successMessage)}
                  </div>
                }
                <Button
                    type="submit"
                    className="action-button"
                    primary
                    style={{display: "inline-block", marginRight: "1.5rem"}}
                >
                  {t("employee.save")}
                </Button>
                <Button
                    type="button"
                    className="action-button"
                    onClick={() => isEmployeeLoggedIn
                        ? this.props.history.push(`/`)
                        : this.props.history.push(`/employees`)}
                    secondary
                    style={{display: "inline-block"}}
                >
                  {t("action.cancel")}
                </Button>
              </Grid.Column>
            </Grid.Row>
          </MpGrid>
        </form>
    );
  };

}


let UpsertEmployeeWrapper = withRouterWorkaround(
    withAuthContext(
        withCompanyDataContext(
            withTranslation(["mipoco"])(
                UpsertEmployee))));

export default UpsertEmployeeWrapper;