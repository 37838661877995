import {AuthConsumerRenderProps, withAuthContext} from "auth/AuthContext";
import {CompanyDataConsumerRenderProps, withCompanyDataContext} from "component/CompanyDataContext";
import CompanyDataHeader from "component/CompanyDataHeader";
import StyledErrorMessage from "component/StyledErrorMessage";
import VirtualizedTable from "component/VirtualizedTable";
import moment from "moment/moment";
import React, {Component} from "react";
import {withTranslation, WithTranslation} from "react-i18next";
import {RouteComponentProps} from "react-router";
import {Button, Loader} from "semantic-ui-react";
import {getAllArticleGroupsByCompanyId} from "service/companyServices";
import {getStockOrdersByEmployeeId} from "service/employeeServices";
import axios from "service/http";
import styled from "styled-components";
import {ArticleGroupDto, StockOrderDto} from "ts-types/api.types";
import {withRouterWorkaround} from "util/workaroundUtils";

const TestkitOrders = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  
  .table-form-container {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .orders-table {
    flex: 1 1 auto;
    min-height: 250px;
    margin-bottom: 1rem;

    .row-actions {
      i.icon,
      i.icons {
        color: #768aff;
      }
    }

    .table-loader.ui.inline.loader.active,
    .table-loader.ui.inline.loader.visible {
      display: block;
      top: 5rem;
    }
  }

  .page-actions {
    display: flex;
    margin-top: 1rem;
  }
  
  .buttons {
    display: inline-block;
    margin-top: 1rem;
  }
`;

interface Props extends RouteComponentProps<any>,
    AuthConsumerRenderProps,
    WithTranslation,
    CompanyDataConsumerRenderProps {

  companyAdmin?: boolean;
}

interface State {
  employeeId: number;
  employeeStockOrders: Array<StockOrderDto>;
  articleGroups: Array<ArticleGroupDto>;
  stockOrdersLoaded: boolean;
  errorMessages: string[];
}

class DashboardEmployeeTestkitOrdersList extends Component<Props, State> {

  private mounted: boolean = false;
  private cancelTokenSource = axios.CancelToken.source();

  constructor(props: Props) {

    super(props);

    let employeeId = 0;
    if (this.props.currentUser) {
      employeeId = parseInt(this.props.currentUser.id);
    }

    this.state = {
      employeeId,
      employeeStockOrders: [],
      articleGroups: [],
      stockOrdersLoaded: false,
      errorMessages: []
    }

  }

  componentDidMount(): void {
    this.mounted = true;
    this.loadStockOrders();
  }

  loadStockOrders = () => {

    const methods: Array<Promise<any>> = [
      getStockOrdersByEmployeeId(this.state.employeeId, this.cancelTokenSource),
      getAllArticleGroupsByCompanyId(this.cancelTokenSource),
    ];

    Promise.all(methods)
        .then(responses => {
          const stockOrders: Array<StockOrderDto> = responses[0];
          const articleGroups: Array<ArticleGroupDto> = responses[1];

          this.setState({
            employeeStockOrders: stockOrders,
            articleGroups: articleGroups
          });
        })
        .catch(this.handleError)
        .finally(() => {
          this.setState({
            stockOrdersLoaded: false
          })
        })
  };

  handleError = (error: any) => {
    const {t} = this.props;

    if (error) {
      const errorCode = error.errorCode;
      const knownErrors: Array<string> = [];

      const violations: Array<any> = error.violations;

      if (violations && violations.length > 0) {
        violations.forEach(violation => {
          if (knownErrors.includes(violation.errorCode)) {
            this.setErrorMessage(t(`error.${violation.errorCode}`));
          }
        });
      }

      if (!this.state.errorMessages.length) {
        if (knownErrors.includes(errorCode)) {
          this.setErrorMessage(t(`error.${errorCode}`));
        } else {
          this.setErrorMessage(t('error.general'));
        }
      }
    }
  };

  setErrorMessage = (errorMessage?: string) => {

    const {errorMessages} = this.state;

    if (errorMessage) {

      const errMsgs = [...errorMessages];
      errMsgs.push(errorMessage);

      this.setState({
        errorMessages: errMsgs
      });
    } else {

      this.setState({
        errorMessages: []
      });
    }
  };

  onTestOrderButtonClick = () => {
    const {employeeId} = this.state;
    this.props.history.push(
        "/person/test-kit-order",
        {employeeId: employeeId}
    );
  }

  goToPreviousPage = () => {
    this.props.history.push("/");
  };

  render(): React.ReactNode {

    return (
        <TestkitOrders>
          <CompanyDataHeader />
          {this.renderPageContent()}
        </TestkitOrders>
    );
  }

  renderPageContent = (): JSX.Element => {
    const {t} = this.props;
    const {errorMessages, stockOrdersLoaded} = this.state;

    return (
        <div className="table-form-container">
          <div className="title-h1">{t("employees.testkitOrdersList.title")} </div>

          {errorMessages.length > 0 &&
          <div className="error">
            <StyledErrorMessage onDismiss={() => this.setErrorMessage()}>
              {errorMessages.map(err => <div key={err}>{err}</div>)}
            </StyledErrorMessage>
          </div>
          }

          <div className="table-form-container">
            {
              stockOrdersLoaded &&
              <Loader className="table-loader" active inline content={t("stockManagement.stockOrder.loading")} />
            }
            <div className="orders-table">
              {this.renderOrdersTable()}
            </div>
            <div className="page-actions">
              {this.renderButtons()}
            </div>
          </div>
        </div>
    );
  };

  ordersRowGetter = ({index}: any) => {
    const {employeeStockOrders} = this.state;

    Object.assign(employeeStockOrders[index], {index: index + 1});

    return employeeStockOrders[index];
  };

  ordersRowRenderer = ({className, columns, index, key, style}: any) => {
    const a11yProps = {'aria-rowindex': index + 1};

    return (
        <div
            {...a11yProps}
            className={className}
            key={key}
            role="row"
            style={style}
        >
          {columns}
        </div>
    );
  };

  renderButtons = (): JSX.Element => {

    const {t} = this.props;
    return (
        <div className="buttons">
          <Button
              type="button"
              className="action-button"
              primary
              style={{display: "inline-block"}}
              onClick={this.onTestOrderButtonClick}
          >
            {t("employeeDashboard.button.orderTests")}
          </Button>

          <Button
              type="button"
              className="action-button"
              secondary
              style={{display: "inline-block"}}
              onClick={this.goToPreviousPage}
          >
            {t("action.back")}
          </Button>
        </div>
    );
  }

  renderOrdersTable = (): JSX.Element => {
    const {t} = this.props;
    const {employeeStockOrders} = this.state;

    return (
        <VirtualizedTable
            rowCount={employeeStockOrders.length}
            rowGetter={this.ordersRowGetter}
            rowRender={this.ordersRowRenderer}
            rowHeight={42}
            columns={[
              {
                width: 150,
                label: "#",
                dataKey: "index"
              },
              {
                width: 400,
                label: (t("stockManagement.orderedProduct.title")),
                dataKey: "articleGroupId",
                cellRenderer: this.productTypeRenderer
              },
              {
                width: 300,
                label: (t("stockManagement.stockOrder.orderDate")),
                dataKey: "orderDate",
                cellRenderer: this.dateCellRenderer
              },
              {
                width: 300,
                label: (t("stockManagement.stockOrder.deliverDate")),
                dataKey: "deliverDate",
                cellRenderer: this.dateCellRenderer
              },
              {
                width: 300,
                label: (t("stockManagement.stockOrder.quantity")),
                dataKey: "quantity",
                cellRenderer: this.quantityCellRenderer
              },
              {
                width: 300,
                label: (t("stockManagement.stockOrder.packageQuantity")),
                dataKey: "articleGroupId",
                cellRenderer: this.packageQuantityCellRenderer
              },
              {
                width: 300,
                label: (t("stockManagement.stockOrder.totalQuantity")),
                dataKey: "articleGroupId",
                cellRenderer: this.totalQuantityCellRenderer
              },
              {
                width: 300,
                label: (t("stockManagement.stockOrder.status")),
                dataKey: "status",
                cellRenderer: this.stockOrderStatusCellRenderer
              }
            ]}
        />
    );
  }

  productTypeRenderer = ({cellData}: any) => {
    const {articleGroups} = this.state;
    const {t} = this.props;

    if (cellData && cellData > 0) {
      const foundAG = articleGroups.filter((ag: ArticleGroupDto) => ag.id === cellData);
      if (foundAG && foundAG.length > 0) {
        let productType = foundAG[0].externalCode;

        return t(`enum.InterventionExternalCode.${productType}`);
      }
    }

    return "";
  };

  dateCellRenderer = ({cellData}: any) => {

    if (cellData) {
      return moment(cellData).format("DD.MM.YY");
    }
    return "";
  };

  quantityCellRenderer = ({cellData}: any) => {
    if (cellData) {
      return cellData;
    }
    return (
        <div></div>
    );
  };

  stockOrderStatusCellRenderer = ({cellData}: any) => {
    const {t} = this.props;
    if (cellData) {
      return t(`enum.status.${cellData}`);
    }
  };

  packageQuantityCellRenderer = ({cellData}: any) => {
    const {articleGroups} = this.state;

    if (cellData && cellData > 0) {
      const foundAG = articleGroups.find((ag: ArticleGroupDto) => ag.id === cellData);
      if (foundAG) {
        return foundAG.packageQuantity;
      }
    }

    return 0;
  };

  totalQuantityCellRenderer = ({cellData, rowData}: any) => {
    const {articleGroups} = this.state;

    if (cellData && rowData) {

      const foundAG = articleGroups.find((ag: ArticleGroupDto) => ag.id === rowData.articleGroupId);

      if (foundAG) {
        const packageQuantity = foundAG.packageQuantity;
        const quantity = rowData.quantity;

        return quantity * packageQuantity;
      }
    }

    return 0;
  };

}

let TestkitOrdersListWrapper = withRouterWorkaround(
    withAuthContext(
        withCompanyDataContext(
            withTranslation(["mipoco"])(
                DashboardEmployeeTestkitOrdersList))));

export default TestkitOrdersListWrapper;