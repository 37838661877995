import {BigActionButton} from "component/BigActionButton";
import React, {Component} from "react";
import {withTranslation, WithTranslation} from "react-i18next";
import LoginTypeView from "route/login/LoginTypeView";
import {SelfServiceContainerDto} from "ts-types/api.types";

interface Props extends WithTranslation {
  containerData?: SelfServiceContainerDto,
  onCompanySignin?: () => void;
  onPersonSignin?: () => void;
  onPersonSelfOnboardContainer?: () => void;
}

class LoginTypeSelectionContainer extends Component<Props> {

  containerTextDefault = (textKey: string): string => {
    const {t} = this.props;

    let text = undefined;

    const {containerData} = this.props;
    if (containerData) {
      const key = `login.typeSelectionContainer_${containerData.type}.${textKey}`;
      text = t(key, "NO_VALUE");
    }

    if (text === "NO_VALUE") {
      text = t(`login.typeSelectionContainer.${textKey}`);
    }
    return text || textKey;
  };

  render(): React.ReactNode {

    const {
      t, containerData, onCompanySignin, onPersonSignin, onPersonSelfOnboardContainer
    } = this.props;

    if (!containerData) {
      return <></>;
    }

    const individualLogin = "yes" === this.containerTextDefault("enableIndividualLogin");
    const institutionLogin = "yes" === this.containerTextDefault("enableInstitutionLogin");

    return <LoginTypeView titleKey="login.typeSelectionContainer.title">
      <div className="row">
        <div className="column no-border">

          <div className="tile">
            <div className="column-title">
              {this.containerTextDefault("subTitle")}
            </div>
            <div className="column-text">
              {t("login.typeSelectionContainer.imWorkingAlreadyWith.text")}
            </div>

            {
              individualLogin &&
              <BigActionButton
                icon="user"
                textDomain="login"
                textKey={this.containerTextDefault("individual.button")}
                onClick={onPersonSignin}
              />
            }

            {
              institutionLogin &&
              <BigActionButton
                icon="building"
                textDomain="login"
                textKey={this.containerTextDefault("company.button")}
                onClick={onCompanySignin}
              />
            }

          </div>

          <div className="tile">
            <div className="column-text">
              {t("login.typeSelectionContainer.firstTimeHere.text")}
            </div>
            <BigActionButton
                icon="user"
                textDomain="login"
                textKey={this.containerTextDefault("personSelfOnboard.button")}
                onClick={onPersonSelfOnboardContainer}
            />
          </div>

        </div>

      </div>

    </LoginTypeView>;
  }
}

export default withTranslation(["login"])(LoginTypeSelectionContainer);
