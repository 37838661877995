import {AuthConsumerRenderProps, withAuthContext} from "auth/AuthContext";
import axios, {CancelTokenSource} from "axios";
import CompanyDataHeader from "component/CompanyDataHeader";
import CheckBox from "component/final-form/CheckBox";
import Input from "component/final-form/Input";
import LoaderComponent from "component/LoaderComponent";
import StyledErrorMessage from "component/StyledErrorMessage";
import TestResultIcon from "component/TestResultIcon";
import VirtualizedTable from "component/VirtualizedTable";
import {FormState} from "final-form";
import _ from "lodash";
import moment from "moment";
import React, {ChangeEvent, Component} from 'react';
import {Field, Form as FinalForm, FormRenderProps, FormSpy} from "react-final-form";
import {withTranslation, WithTranslation} from "react-i18next";
import Lightbox from "react-image-lightbox";
import {RouteComponentProps} from "react-router";
import {Button, InputOnChangeData} from "semantic-ui-react";
import {getTestResultImage, searchTestResults} from "service/testResultServices";
import styled from "styled-components";
import {
  EmployeeTestResultImageDto, InterventionDto,
  TestResultDisplay,
  TestResultOverviewDto,
  TestResultSearchRequestDto
} from "ts-types/api.types";
import {isKeyCheck} from "util/keyUtils";
import {withRouterWorkaround} from "util/workaroundUtils";
import {getAllInterventions} from "../../service/interventionServices";
import {noop} from "../../util/functionUtils";
import {interventionDescription} from "../../util/interventionUtils";

const TestResultDiv = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;

  .header-message-text {
    font-size: 1.5rem;
  }

  .search-form {
    padding-left: 0.75rem;
    margin-bottom: 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;

    label {
      margin-right: 1rem;
    }

    .ui.input {
      min-width: 15rem;
    }

    button {
      margin-left: 1rem;
    }
    
    .checkbox {
      margin-left: 1rem;
    }
    
    .error {
      margin-bottom: 1rem;
    }
  
  }

  .results-table {
    flex: 1 1 auto;

    .row-actions {
      i.icon,
      i.icons {
        color: #768aff;
      }
    }
  }

  .icon-button {
    padding: 2px !important;
    background: transparent !important;
  }

  .page-actions {
    justify-self: start;
    margin-top: 0.75rem;

    .actions-left {
      float: left;
    }

    .actions-right {
      float: right;

      .ui.button {
        margin-left: 0.5rem;
      }

      .search-form {
        display: inline-block;
        margin-right: 1rem;
      }
    }
  }
`;

interface Props extends RouteComponentProps<any> {

}

interface Props extends RouteComponentProps<any>, AuthConsumerRenderProps, WithTranslation {
}

interface TestResultFieldProps extends FormState<any> {
  values: Partial<TestResultSearchRequestDto>
}

interface State {
  query: string,
  testResults: TestResultOverviewDto[],
  testResultsLoaded: boolean,
  errorMessages: Array<string>,
  resultImage?: EmployeeTestResultImageDto,
  searchValues: Partial<TestResultSearchRequestDto>
  resultImageLoading: boolean,
  interventions: InterventionDto[],
  cancelTokenSource: CancelTokenSource;
}

class TestResult extends Component<Props, State> {

  private mounted: boolean = false;

  constructor(props: Props) {
    super(props);

    const cancelTokenSource = axios.CancelToken.source();
    this.state = {
      query: "",
      testResults: [],
      testResultsLoaded: true,
      searchValues: {},
      resultImageLoading: false,
      interventions: [],
      errorMessages: [],
      cancelTokenSource
    };

    setTimeout(() => {
      this.fetchTestResults();
    }, 5);
  }

  componentDidMount(): void {
    this.mounted = true;
  }

  handleChange = ({values}: TestResultFieldProps): void => {
    if (!this.mounted) {
      return;
    }

    this.setState({
      searchValues: values
    });
  }

  fetchTestResults = async () => {

    const {cancelTokenSource} = this.state;

    const onFinally = () => {
      this.setState({
        testResultsLoaded: true
      });
    };

    this.setState({
      testResultsLoaded: false
    });

    let searchValues = {...this.state.searchValues};
    if (_.isEmpty(searchValues)) {
      searchValues = {
        query: "",
        includesInactive: false
      }
    }

    await getAllInterventions(cancelTokenSource)
        .then(response => {
          this.setState({
            interventions: response
          });
        })
        .catch((e: any) => this.handleError(e.response.data))
        .finally(noop);

    await searchTestResults(searchValues, cancelTokenSource)
        .then(response => {
          this.setState({
            testResults: response
          });
        })
        .catch((e: any) => this.handleError(e.response.data))
        .finally(onFinally);

  };

  handleError(error: any) {
    const {t} = this.props;

    if (error) {
      const errorCode = error.errorCode;
      const knownErrors: Array<string> = [
        "UNKNOWN_TEST_ATTACHMENT_ID",
        "EMPTY_BYTE_ARRAY_TEST_ATTACHMENT_BLOB",
        "BLOB_CONTAINER_NOT_INITIALIZED",
        "AZURE_IS_DOWN"
      ];

      const violations: Array<any> = error.violations;

      if (violations && violations.length > 0) {
        violations.forEach(violation => {
          if (knownErrors.includes(violation.errorCode)) {
            this.setErrorMessage(t(`error.${violation.errorCode}`));
          }
        });
      }

      if (!this.state.errorMessages.length) {
        if (knownErrors.includes(errorCode)) {
          this.setErrorMessage(t(`error.${errorCode}`));
        } else {
          this.setErrorMessage(t('error.general'));
        }
      }
    }
  };

  setErrorMessage = (errorMessage?: string) => {

    const {errorMessages} = this.state;

    if (errorMessage) {

      const errMsgs = [...errorMessages];
      errMsgs.push(errorMessage);

      this.setState({
        errorMessages: errMsgs
      });
    } else {

      this.setState({
        errorMessages: []
      });
    }
  };

  setQuery = (evt: ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
    this.setState({
      query: data.value
    });
  };

  onQueryKeyDown = (event: any) => {
    event.persist();
    const isKey: any = isKeyCheck(event);

    if (isKey.enter) {
      this.fetchTestResults();
    }
  };

  showTestResultImage = (testResultImageId: string | null) => {

    if (testResultImageId) {

      this.setState({
        resultImageLoading: true
      });

      getTestResultImage(testResultImageId, this.state.cancelTokenSource)
      .then(response => {
        this.setState({
          resultImage: response,
          resultImageLoading: false
        });
      })
      .catch((e) => {
        this.handleError(e.response.data)
      })
      .finally(() => {
        this.setState({
          resultImageLoading: false
        })
      });
    }
  }

  render() {
    return (
        <TestResultDiv className="test-registration">
          <CompanyDataHeader />

          {this.renderSearchEmployeeForm()}
        </TestResultDiv>
    );
  }

  renderSearchEmployeeForm = (): JSX.Element => {
    return (
        <FinalForm
            onSubmit={() => {
            }}
            initialValues={{
              query: "",
              includesInactive: false
            }}
            subscription={{pristine: true}}
            render={this.renderSearchFormContent}
        />
    );
  };

  renderSearchFormContent = ({form}: FormRenderProps): React.ReactNode => {
    const {t} = this.props;
    const {testResultsLoaded, resultImage, errorMessages, resultImageLoading} = this.state;

    //const state : any = this.props.location.state;

    return <>
      <div className="title-h1">{t("testResults.title")}</div>

      {errorMessages.length > 0 &&
      <div className="error">
        <StyledErrorMessage onDismiss={() => this.setErrorMessage()}>
          {errorMessages.map(err => <div key={err}>{err}</div>)}
        </StyledErrorMessage>
      </div>
      }

      <div className="search-form">
        <label htmlFor="query">{t("testResults.search")}</label>

        <Field
          id="query"
          name="query"
          component={Input}
          //onChange={this.setQuery}
          onKeyDown={(e: any) => this.onQueryKeyDown(e)}
          fluid
        />

        <Button
            type="button"
            className="action-button"
            primary
            onClick={this.fetchTestResults}
        >
          {t("testResults.searchButton")}
        </Button>

        <Field
            name="includesInactive"
            className="checkbox"
            component={CheckBox}
            label={t("testResults.includingInactive")}
            toggle={true}
        />
      </div>

      <div className="results-table">
        {!testResultsLoaded
            ? <LoaderComponent message={t("employeeDashboard.table.loading")} />
            : this.renderTestResultsTable()
        }
      </div>

      <div className="page-actions">
        <div className="actions-right">
          <Button
              type="button"
              className="action-button"
              color={"grey"}
              onClick={(evt) => this.props.history.push("/")}
          >
            {t("action.back")}
          </Button>
        </div>
      </div>

      {resultImageLoading && <LoaderComponent message={t("loading")}/>}

      {resultImage && <Lightbox
        mainSrc={`data:${resultImage.testResultFileType};base64, ${resultImage.testResultImage}`}
        onCloseRequest={() => this.setState({resultImage: undefined})}
      />}

      <FormSpy subscription={{values: true}} onChange={this.handleChange} />
    </>;
  }

  renderTestResultsTable = (): JSX.Element => {

    const {t} = this.props;
    const {testResults} = this.state;

    return (
        <VirtualizedTable
            rowCount={testResults.length}
            rowGetter={this.testResultsRowGetter}
            rowRenderer={this.testResultsRowRenderer}
            columns={[
              {
                width: 200,
                label: t("testResults.tableHeader.employee"),
                dataKey: "index",
                flexGrow: 2,
                cellRenderer: this.employeeNameCellRenderer
              },
              {
                width: 100,
                label: (t("testResults.tableHeader.birthDate")),
                dataKey: "patientBirthDate",
                cellRenderer: this.birthDateCellRenderer
              },
              {
                width: 120,
                label: (t("testResults.tableHeader.division")),
                dataKey: "divisionName",
                flexGrow: 1,
                cellRenderer: this.divisionCellRenderer
              },
              {
                width: 100,
                label: (t("testResults.tableHeader.testType")),
                dataKey: "testType",
                flexGrow: 1,
                cellRenderer: this.testTypeCellRenderer
              },
              {
                width: 120,
                label: (t("testResults.tableHeader.testFrequency")),
                dataKey: "testFrequency",
                flexGrow: 1,
                cellRenderer: this.testFrequencyCellRenderer
              },
              {
                width: 65,
                label: (t("testResults.tableHeader.numTests")),
                dataKey: "numberOfTests",
                cellRenderer: this.numberOfTestsCellRenderer
              },
              {
                width: 150,
                label: (t("testResults.tableHeader.results")),
                dataKey: "covidTestResult",
                flexGrow: 1,
                cellRenderer: this.testResultsCellRenderer
              },
              {
                width: 100,
                label: (t("testResults.tableHeader.status")),
                dataKey: "active",
                cellRenderer: this.statusCellRenderer
              }
            ]}
        />

    );
  };

  testResultsRowGetter = ({index}: any) => {
    const {testResults} = this.state;
    Object.assign(testResults[index], {index: index + 1});

    return testResults[index];
  };

  testResultsRowRenderer = ({className, columns, index, key, style}: any) => {
    const a11yProps = {'aria-rowindex': index + 1};

    // const {testResults} = this.state;
    // const registration = testResults[index];

    let rowStyle = {...style};

    return (
        <div
            {...a11yProps}
            className={className}
            key={key}
            role="row"
            style={rowStyle}
        >
          {columns}
        </div>
    );
  };

  employeeNameCellRenderer = (data: any) => {
    const {rowData} = data;
    return <div>
      {rowData.patientFirstName} {rowData.patientLastName}
    </div>;
  };

  birthDateCellRenderer = ({cellData}: any) => {
    if (cellData) {
      return <div>{moment(cellData).format("DD.MM.YYYY")}</div>;
    }
    return "";
  };

  divisionCellRenderer = ({cellData}: any) => {
    if (cellData && cellData.length > 0) {
      return cellData;
    }
    return "";
  };

  testTypeCellRenderer = ({cellData}: any) => {
    const {language} = this.props;
    const {interventions} = this.state;

    if (cellData && cellData.length > 0) {
      const intervention = interventions.find(i => i.externalCode === cellData)

      return interventionDescription(intervention, language);
    }
  };

  testFrequencyCellRenderer = ({cellData}: any) => {
    const {t} = this.props;
    if (cellData && cellData.length > 0) {
      return t(`testFrequency.${cellData}`);
    }
    return "";
  };

  numberOfTestsCellRenderer = ({rowData}: any) => {
    return rowData.numberOfTests;
  };

  testResultsCellRenderer = ({rowData, cellData}: any) => {
    const {t} = this.props;

    const renderSingleResult = (testResult: TestResultDisplay) => {
      const result = testResult.result;

      const timestampValue = testResult.timestamp;
      const timestampDisplay = timestampValue ? ` - ${moment(timestampValue).format("DD.MM.YYYY HH:mm")}` : "";
      if (result) {
        return <span onClick={() => this.showTestResultImage(testResult.testResultImageId)}>
          <TestResultIcon result={result} title={`${t(`testResult.${result}`)} ${timestampDisplay}`}/>
        </span>
      }
      return <></>;
    };

    if (rowData.results) {
      return <>
        {
          rowData.results.map((result: TestResultDisplay) => renderSingleResult(result))
        }
      </>;
    }

    if (cellData && cellData.length > 0) {
      return cellData;
    }

    return "";
  };

  statusCellRenderer = ({cellData}: any) => {
    const {t} = this.props;
    if (cellData) {
      return t("employee.active");
    }
    return t("employee.inactive");
  };

}

export default withRouterWorkaround(
    withAuthContext(
        withTranslation(["mipoco"])(
            TestResult)));
