import {AuthConsumerRenderProps, withAuthContext} from "auth/AuthContext";
import React, {Component} from "react";
import {getCompanyData} from "service/companyServices";
import axios from "service/http";
import {getContainerForCompany} from "service/selfServiceContainerServices";
import {CovidTestSampleMethod, RegistrationStateType} from "ts-types/api.enums";
import {CompanyDataDto, SelfServiceContainerDto, UpsertCompanyDto} from "ts-types/api.types";

const cancelTokenSource = axios.CancelToken.source();

interface Props extends JSX.ElementChildrenAttribute, AuthConsumerRenderProps {}

interface State {
  companyData: CompanyDataDto,
  containerData?: Partial<SelfServiceContainerDto>,
  setCompanyData: (upsertCompanydto: Partial<UpsertCompanyDto>) => void
  setCompanyDataRegistrationState: (registrationState: string) => void
}

export const CompanyDataContext = React.createContext<State>({
  companyData: {
    id: -1,
    name: "",
    street: "",
    zip: "",
    city: "",
    administrator: "",
    registrationState: RegistrationStateType.INITIAL,
    testSampleMethod: CovidTestSampleMethod.SALIVA,
    companyLogoUrl: "",
    numEmployees: 0,
    automaticCertificateNotification: false,
    acceptCD: false,
    acceptCDRemark: ""
  },
  containerData: {},
  setCompanyData: () => {},
  setCompanyDataRegistrationState: () => {}
});

class CompanyDataProvider extends Component<Props, State> {

  constructor(props: Props) {
    super(props);

    this.state = {
      companyData: {
        id: -1,
        name: "",
        street: "",
        zip: "",
        city: "",
        administrator: "",
        registrationState: RegistrationStateType.INITIAL,
        testSampleMethod: CovidTestSampleMethod.SALIVA,
        companyLogoUrl: "",
        numEmployees: 0,
        automaticCertificateNotification: false,
        acceptCD: false,
        acceptCDRemark: ""
      },
      setCompanyData: this.setCompanyData,
      setCompanyDataRegistrationState: this.setCompanyDataRegistrationState
    };

    if (this.props.isAuthenticated) {
      this.fetchCompanyData();
    }
  }

  UNSAFE_componentWillReceiveProps = (
      nextProps: Readonly<Props>, nextContext: any): void => {

    if (!this.props.isAuthenticated && nextProps.isAuthenticated) {
      this.fetchCompanyData();
    }
  }

  fetchCompanyData = async () => {
    const companyData = await getCompanyData(cancelTokenSource);

    const container = companyData.container;
    let containerData : SelfServiceContainerDto | undefined = undefined;
    if (container) {
      containerData = await getContainerForCompany(cancelTokenSource);
    }

    this.setState({
      companyData,
      containerData
    });
  }

  setCompanyDataRegistrationState = (registrationState: string) => {
    const {companyData} = this.state;
    this.setState({
      companyData: {...companyData, registrationState: registrationState}
    })
  }

  setCompanyData = (upsertCompanyDto: Partial<UpsertCompanyDto>) => {
    const {companyData} = this.state;
    this.setState({
      companyData: {
        ...companyData,
        name: upsertCompanyDto.description ? upsertCompanyDto.description : companyData.name,
        street: upsertCompanyDto.street ? upsertCompanyDto.street : companyData.street,
        zip: upsertCompanyDto.zip ? upsertCompanyDto.zip : companyData.zip,
        city: upsertCompanyDto.city ? upsertCompanyDto.city : companyData.city
      }
    });
  }

  render() {
    return (
        <CompanyDataContext.Provider value={this.state} >
            {this.props.children}
        </CompanyDataContext.Provider>
    )
  }
}

export default withAuthContext(CompanyDataProvider);

export interface CompanyDataConsumerRenderProps extends State {}

export const withCompanyDataContext = (Component: typeof React.Component | React.FunctionComponent<any> ) => {
  return (props: any) => (
      <CompanyDataContext.Consumer>
        {({...sidebarProps}: CompanyDataConsumerRenderProps) => {
          return <Component {...props} {...sidebarProps} />
        }}
      </CompanyDataContext.Consumer>
  );
};
